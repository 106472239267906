import React,{ FC, useEffect, useState } from "react";
import "./fooditem.scss";
import { URLS } from "../../../../constants/url";
import { onImgError } from "../../../../utils/helper";

const Icon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const Dropdown: FC<any> = (
  { 
    placeHolder, 
    options, 
    onChange, 
    parentIndex,
    labelImage,
    labelText,
    dropdownText
  }) => {
  
  const [showMenu, setShowMenu] = useState(false);

  const [selectedValue, setSelecetedValue] = useState<any>(null);

  const handleInputClick = (e: any) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    const handler = () => setShowMenu(false);
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    }     
  });
  const getDisplay = () => {
    if (selectedValue) {
      return selectedValue.description;
    }
    return placeHolder;
  };

  const onItemClick = (option: any, parentIndex: number) => {
    setSelecetedValue(option);
    setShowMenu(false);
    onChange(option, parentIndex);
  }

  const isSelected = (option: any) => {
    if (!selectedValue) {
      return false;
    }
    return selectedValue.description === option.description;
  }


  return (
    <ul className=" mr-lg-auto dropdown_menu mb-3" role="listitem">
      <li className="selected_item dropdown food_selection_link">
        <span onClick={handleInputClick}>
          {
            labelImage ?           
            <img src={URLS.CLOCK_IMAGE} alt="clock" style={{width: "20px", marginRight: "10px"}} />
            : null
          }
          {labelText?labelText:null}
          {getDisplay()}
          <span className="caret"></span>
        </span>
        {showMenu &&
          <ul id="" className="dropdown-menu" role="menu" malihu-scrollbar>
          {
            options.map((choice: any, j: any) => (
            <li key={choice.value} 
              onClick={() => onItemClick(choice, parentIndex)}
              className={`dropdown-item ${isSelected(choice) && "selected"}`}>
              <label className="form-label">
                <div className="dropdown-item">
                  {
                    choice.image && 
                    <div className="brand_icon">
                      <img src={choice.image} alt="Food Image"  onError={onImgError} />
                    </div>
                  }                  
                  <p>{dropdownText?dropdownText:null}{choice.description}</p>
                </div>
              </label>
            </li>
            ))
          }
          </ul>
        }
      </li>
    </ul>
  );
};

export default Dropdown;