import React,{ FC, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../../../../components/UI/DropDown/FoodItem";
import { TICKET_FLOW } from "../../../../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../../../models/tickets";
import { RootState } from "../../../../../../../store";
import { addSelectedTickets, clearFoodItemsAction, getBookedTickets } from "../../../../../../../store/foodAndBeverage/action";
import { setModal } from "../../../../../../../store/ticketBooking/action";
// import './updateDeliveryDetails.scss';
import moment from "moment";

interface IOrderHistoryProps {
  historyData: any
}

const OrderHistory: FC<IOrderHistoryProps> = ({
  historyData
}) => {
  const {     
    modalType, 
    countryId,
    prePurchasedTicket,
    hasGL,
    prevModal
  } = useSelector((state: RootState)=>({
      modalType: state.ticketBookingReducer.modal.type,
      countryId: state.applicationReducer.countryId,
      prePurchasedTicket: state.foodBeverageReducer.prePurchasedTicket,
      hasGL: state.ticketBookingReducer.hasGL,
      prevModal: state.ticketBookingReducer.modal.prevModal,
  }))

  const dispatch = useDispatch();
  const [purchasedData, setConcession] = useState<any>([]);
  const [expand, setExpand] = useState<{
    parentId: number;
  } | null>(null);

  useEffect(() => {
    if (historyData) {
      let formatData = historyData.map((v: any)=>{
        return {
          createdOn: moment.parseZone(v.Created_date).format('hh:mm A, ddd D MMMM YYYY'),
          concessionTotal: v.Extra?v.Extra.map((v: any)=>JSON.parse(v)).reduce((acc: any, v: any)=>{
            return acc +  Number(v.Price);
          },0):0,
          concessions: v.Extra?v.Extra.map((v: any)=>JSON.parse(v)).map((v: any)=>{
            const smartModifierItem = 
            (v.smartModifierGroups && v.smartModifierGroups.length > 0)? (v.smartModifierGroups[0].selected):[];
            v.modifierItem  = [...v.modifier,...smartModifierItem].join(',');
            return v;
          }):[],
          id:v.Id
        }
      })
      setConcession(formatData);
    }
  }, [historyData]);

  // useEffect(() => {
  //   if (concessions) {
  //     setConcession(concessions);
  //   }
  // }, [concessions]);

  const setDeliverSeat = {

  }

  const setDeliverTime = {

  }

  const onBack = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const prev: MODAL_TYPE = prevModal;
    const modal = TICKET_FLOW[ticketType][prev];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  }

  const handleExpand = (parentIndex: number) => {
    if (
      expand &&
      expand.parentId === parentIndex
    ) {
      setExpand(null);
    } else {
      setExpand({
        parentId: parentIndex
      });
    }
  };

  return (
    <>
    <div className="col-md-12 historySection">
      <p className="header">
        <img src="timeImg" alt="clock" className="clockIcon" />Order History
        </p>            
    </div>
    {
      purchasedData.length > 0 ? 
      purchasedData.map((history: any, pIndex: any) => (
        <div className="col-md-12 historySection">
          <div className="mb-2">
            <div className="accordion acct-content" onClick={() => {
                handleExpand(pIndex);
              }}>
              <h2 className="accordion_text">{history.createdOn}</h2>
              <span
                className={`${
                  expand &&
                  expand.parentId === pIndex
                    ? "chevron_up"
                    : "chevron_down"
                }`}
              >
                &nbsp;
              </span>
            </div>
            {
            expand &&
            expand.parentId === pIndex ? (
            <div className="panel">
              <div className="order_item">
                <div className="col-md-12">
                  <div className="row my-2">
                    <div className="col-6 label">Food & Beverage</div>
                    <div className="col-6 field">
                      {
                        history.concessions.map((foodItems: any) => (
                          <div>
                            {foodItems.Description}
                            {foodItems.modifierItem}<br/>
                            <span>Quantity: {foodItems.Quantity} -
                              {foodItems.Delivery} {foodItems.Delivery === 'Seat Delivery' &&
                              foodItems.deliveryInfo.Comment?' - '+foodItems.deliveryInfo.Comment:''}</span>
                          </div>
                        ))
                      }                      
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-6 label">Total Paid</div>
                    <div className="col-6 field">${history.concessionTotal}</div>
                  </div>
                </div>
              </div>
            </div>
            ) : null}
          </div>
        </div>   
      )) : null
    }    
    </>
  );
};

export default OrderHistory;
