import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { checkVoucherBalanceApi } from "../../../../services/payment";
import { useFormik } from "formik";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import CreditCard, { ICardRef } from "../CreditCard";
import { concessionPriceCalculation } from "../../../../utils/tickets";
import TextInput from "../../../UI/TextInput";

export interface NswVoucherProps {
  back?: () => void;
}

export interface IVoucherRef {
  submit: () => any;
}

const NswVoucher = forwardRef(
  (props: NswVoucherProps, ref: React.Ref<IVoucherRef>) => {
    const { ticketDetails, orderCartData, currentCinema, settings } =
      useSelector((state: RootState) => ({
        ticketDetails: state.ticketBookingReducer.ticketDetails,
        ticketBooking: state.ticketBookingReducer,
        currentCinema: state.applicationReducer.currentCinema,
        orderCartData: state.foodBeverageReducer.orderCartData,
        settings: state.applicationReducer.settings,
      }));

    const cardRef = useRef<ICardRef>(null);

    const concessionTotal = useMemo(() => {
      return concessionPriceCalculation(orderCartData);
    }, [orderCartData]);

    const total = useMemo(() => {
      return (
        (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
      ).toFixed(2);
    }, [ticketDetails, concessionTotal]);

    const bookingFees = useMemo(() => {
      return ticketDetails?.bookingFees
        ? Number(ticketDetails?.bookingFees)
        : 0;
    }, [ticketDetails]);

    const [voucherError, setVoucherError] = useState("");
    const [voucherAmount, setVoucherAmount] = useState(total);
    const [totalAmount, setTotalAmount] = useState(total);
    const [isValidVoucher, setIsValidVoucher] = useState(false);

    const { handleChange, values, errors, touched, setFieldValue } = useFormik({
      enableReinitialize: true,
      initialValues: {
        nswVoucher: "",
        enableCreditCard: false,
      },
      onSubmit: (values) => {},
    });

    useImperativeHandle(ref, () => ({
      submit: () => {
        if (isValidVoucher && values.nswVoucher) {
          if (values.enableCreditCard) {
            const cardDetails: any = cardRef?.current?.submit();
            if (!cardDetails) {
              return null;
            }
            const { card, action, saveCard } = cardDetails;
            return {
              cardDetails: card,
              action,
              saveCard,
              voucher: values.nswVoucher,
              enableCreditCard: values.enableCreditCard,
              cardChargedAmount: voucherAmount,
              balance: totalAmount,
              total: total,
            };
          } else {
            return {
              voucher: values.nswVoucher,
              enableCreditCard: values.enableCreditCard,
              cardChargedAmount: total,
              total: total,
            };
          }
        } else {
          setVoucherError("Voucher code is required");
          return null;
        }
      },
    }));

    const checkBalance = async () => {
      setVoucherError("");
      const { nswVoucher } = values;
      if (!nswVoucher) {
        setVoucherError("Voucher code is required");
        return;
      }
      if (nswVoucher) {
        const response: any = await checkVoucherBalanceApi({
          cardNumber: nswVoucher,
          theatreId: currentCinema.id,
        });
        if (response.status) {
          const balance = response.balance;
          setIsValidVoucher(true);
          setVoucherAmount(balance);
          if (balance < total) {
            setTotalAmount(total - balance);
            setFieldValue("enableCreditCard", true);
          } else {
            setTotalAmount(0);
            setFieldValue("enableCreditCard", false);
          }
        } else {
          setVoucherError(response.error);
          setIsValidVoucher(false);
        }
      }
    };

    return (
      <div className="login-form">
        <div className="row mt-4">
          <div className="col-12">
            <label className="form-label">VOUCHER NUMBER </label>
            <div className="form-group">
              <TextInput
                type="text"
                field={"nswVoucher"}
                value={values.nswVoucher}
                onBlur={checkBalance}
                handleChange={handleChange}
                touched={touched.nswVoucher!}
                error={errors.nswVoucher}
              />

              {voucherError ? (
                <div className="error_message">
                  <span>{voucherError}</span>
                </div>
              ) : null}

              {isValidVoucher ? (
                <div className="info_message">
                  <span style={{color:'grey'}}>
                    {voucherAmount > totalAmount &&
                    (!settings.voucherFB || settings.voucherFB == "0")
                      ? "Redeemable amount:"
                      : "Balance in Card:"}
                    ${voucherAmount.toFixed(2)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          {isValidVoucher && totalAmount > 0 ? (
            <div className="col-12">
              <div className="info_message">
                <span>
                  {total - bookingFees < voucherAmount
                    ? `Balance Payment Amount: $${totalAmount.toFixed(2)}`
                    : `Balance Payment Amount: $${(
                        totalAmount - bookingFees
                      ).toFixed(2)} + Booking Fee: $${bookingFees.toFixed(2)}`}
                </span>
              </div>
            </div>
          ) : null}

          {values.enableCreditCard ? (
            <>
              {" "}
              <div className="col-12">
                <div className="custom_checkbox save_card_list_checkbox">
                  <div className="state p-primary">
                    <label className="form-label">
                      Pay remaining balance with credit card
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <CreditCard ref={cardRef} />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
);

export default NswVoucher;
