import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IMilitartConfirmationHeaderProps {}

const MilitartConfirmationHeader: FC<IMilitartConfirmationHeaderProps> = () => {
    const { data } = useSelector((state: RootState) => ({
        data: state.ticketBookingReducer.modal.data,
      }));
  return (
    <div className="military-confirmation-wrapper">
      <div className="col-12 px-2">
        <div className="military-header">
         {data.header} 
        </div>
      </div>
    </div>
  );
};

export default MilitartConfirmationHeader;
