import React,{ FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import moment from "moment";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { Tooltip } from 'react-tooltip';
import "./commonHeader.scss";
import { COUNTRY } from "../../../../constants/app";
import { formatFlags } from "../../../../utils/formatter";

interface ICommonHeaderProps {}

const CommonHeader: FC<ICommonHeaderProps> = () => {
  const { films, ticketDetails, ticketLessFb, countryId } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      films: state.ticketBookingReducer.films,
      ticketDetails: state.ticketBookingReducer.ticketDetails,
      ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    })
  );
  const dispatch = useDispatch();

  return (
    <div className="common-header ">
      <div className="mobile_show">
        <div className="mobile_header_top">
          <div className="position-top">
            <div className="date">
              {films
                ? moment.parseZone(films?.showDateTime).format("dddd DD MMMM")
                : ""}
            </div>
            {formatFlags(films).map((flagObj: any, i: number)=>(<><span
                  className="flag_text"
                  data-tooltip-id={`flag-description_${i}`} data-tooltip-html={flagObj.Description}
                  >{flagObj.short}</span>
                  <Tooltip id={`flag-description_${i}`} className="tool-tip" /></>))}
          </div>
        </div>
        <div className="media">
          <div className="mobile_header wid-xs">
            <div className="micro_poster">
              <img
                src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${films?.image}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`}
                alt={films?.movieName}
                title={films?.movieName}
              />
            </div>
            <div className="media-body">
              <div className="movie_detail">
                <div
                  className={`cat_hide_mobile
                  ${
                    films?.sessionAttributes.toLowerCase() === "premium"
                      ? "premium_icon"
                      : films?.sessionAttributes.toLowerCase() === "gold"
                      ? "gold_icon"
                      : films?.sessionAttributes.toLowerCase() === "soho"
                      ? "soho_icon"
                      : films?.sessionAttributes.toLowerCase() === "titanxc"
                      ? "titanxc_icon"
                      : films?.sessionAttributes.toLowerCase() ===
                        "titanluxe"
                      ? "titan_luxe_icon"
                      : ""
                  }`}
                ></div>

                <div className="title">{films?.movieName}</div>
                <div className="sub-title"> {films
                ? moment.parseZone(films?.showDateTime).format("hh:mm A")
                : ""} {films?.cinemaName}
                 {countryId === COUNTRY.STA || countryId === COUNTRY.ANG?<span><br/>{ films.screenName }</span>:null}</div>
                <div className="col-12 pd-0">
                  <div className="rating_title">
                    <div className="rating_wrap come here">
                      <div className="icon">
                        <img
                          src={
                            films?.ratingIcon
                              ? `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${films?.ratingIcon}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT}`
                              : URLS.ERROR_IMAGE
                          }
                          alt={films?.ratingName}
                        />
                      </div>
                      <div className="">{films?.ratingName}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile_hide">
        <div className="d-flex align-items-center">
          <div className="date">
            {films
              ? moment.parseZone(films?.showDateTime).format("dddd DD MMMM")
              : ""}
                  {formatFlags(films).map((flagObj: any, i: number)=>(<>{flagObj.short != 'SoHo'?<span
                  className="flag_text"
                  data-tooltip-id={`flag-description_${i}`} data-tooltip-html={flagObj.Description}
                  >{flagObj.short}</span>:null}
                  <Tooltip id={`flag-description_${i}`} className="tool-tip" /></>))}
          </div>
          <div
                  className={`${
                    films?.sessionAttributes.toLowerCase() === "premium"
                      ? "premium_icon"
                      : films?.sessionAttributes.toLowerCase() === "gold"
                      ? "gold_icon"
                      : films?.sessionAttributes.toLowerCase() === "soho"
                      ? "soho_icon"
                      : films?.sessionAttributes.toLowerCase() === "titanxc"
                      ? "titanxc_icon"
                      : films?.sessionAttributes.toLowerCase() ===
                        "titanluxe"
                      ? "titan_luxe_icon"
                      : ""
                  } mx-1`}
                ></div>
        </div>

        <div className="clear"></div>

        <div className="col-8 pd-0 pull-left">
          <div className="title">{films?.movieName}</div>
        </div>
        <div className="clear"></div>
        <span className="time">
          {films ? moment.parseZone(films?.showDateTime).format("hh:mm A") : ""}
        </span>
        <span className="sub-title">
          {films?.cinemaName}
          {countryId === COUNTRY.STA || countryId === COUNTRY.ANG?<span><br/>{ films.screenName }</span>:null}
        </span>
      </div>
    </div>
  );
};

export default CommonHeader;
