import { AnyAction } from "@reduxjs/toolkit";
import { ACTIONS, Giftcards } from './type';


const initialState: Giftcards = {
    currentTab: "",
    currentTabId: "",
    cart: [],
    cardCategory: [],
    banner: {
        mobileImageUrl: ""
    },
    cards: [],
    cardDesigns: [],
    loader: false,
    orderResponse: null,
    bookingId: "",
    error: '',
    currentAction: '',
    giftFormVisible: false,
    giftModalVisible: false,
    giftPromo: [],
    promoPopupData: null
};

export default function giftCardReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case ACTIONS.GET_CARD_WATCHER:
            return {
                ...state,
                loader: true,
            };
        case ACTIONS.GET_CARD_SUCCESS:
            return {
                ...state,
                cards: action.payload,
                loader: false,
            };
        case ACTIONS.GET_CARD_ERROR:
            return {
                ...state,
                cards: [],
                error: action.payload,
                loader: false,
            };
        case ACTIONS.SET_GIFT_SHOP_TAB:
            return {
                ...state,
                currentTab: action.payload,
            }
        case ACTIONS.SET_GIFT_SHOP_TAB_ID:
            return {
                ...state,
                currentTabId: action.payload,
            }            
        case ACTIONS.SET_GIFT_SHOP_ACTION:
            return {
                ...state,
                currentAction: action.payload,
            }
        case ACTIONS.GET_CARD_DESIGN_WATCHER:
            return {
                ...state,
                loader: true,
            };
        case ACTIONS.GET_CARD_DESIGN_SUCCESS:
            return {
                ...state,
                cardDesigns: action.payload,
                loader: false,
            };

        case ACTIONS.GET_CARD_CATEGORY_SUCCESS:
            return {
                ...state,
                cardCategory: (action.payload).sort((a: any, b: any) => a.sortorder - b.sortorder),
                loader: false,
            };
        case ACTIONS.GET_CARD_CATEGORY_ERROR:
            return {
                ...state,
                cardCategory: [],
                loader: false,
            };
        case ACTIONS.GET_CARD_DESIGN_ERROR:
            return {
                ...state,
                cardDesigns: [],
                error: action.payload,
                loader: false,
            };
        case ACTIONS.ADD_GIFT_CART:
            return {
                ...state,
                cart: action.payload,
            };
        case ACTIONS.SET_GIFT_FORM_VISIBLE:
            return {
                ...state,
                giftFormVisible: action.payload,
            };
        case ACTIONS.SET_GIFT_MODAL_VISIBLE:
            return {
                ...state,
                giftModalVisible: action.payload,
            };
        case ACTIONS.GIFT_CARD_ORDER_SUCCESS:
            return {
                ...state,
                orderResponse: action.payload,
                loader: false,
            };
        case ACTIONS.UPDATE_GIFT_CARD_BOOKING_ID:
            return {
                ...state,
                bookingId: action.payload,
                loader: false,
            };
        case ACTIONS.CLEAR_GIFT_CARD_ORDERS:
            return {
                ...state,
                bookingId: "",
                orderResponse: null,
                cart: [],
                error: ''
            };
        case ACTIONS.SET_TRANSACTION_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case ACTIONS.GIFT_CARD_PROMO_SUCCESS:
            return {
                ...state,
                giftPromo: action.payload,
            };
        case ACTIONS.GIFT_CARD_PROMO_FAILURE:
            return {
                ...state,
                giftPromo: [],
            };
        case ACTIONS.SET_PROMO_DATA:
            return {
                ...state,
                promoPopupData: action.payload
            }    

        default:
            return state
    }

}