import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COUNTRY } from "../../../../constants/app";
import { URLS } from "../../../../constants/url";
import { IPrintSessions } from "../../../../models/cinema";
import { printSessionApi } from "../../../../services/films";
import { RootState } from "../../../../store";
import Storage from "../../../../utils/storage";
import "./printSession.scss";

interface IPrintSessionProps {}

const PrintSession: React.FC<IPrintSessionProps> = () => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  const [sessions, setSessions] = useState<IPrintSessions[]>([]);
  const [theaterName, setTheaterName] = useState<string>("");
  const [logo, setLogo] = useState({
    img: "",
    title: "",
    altText: "",
    label: "",
  });

  useEffect(() => {
    return () => {
      document.body.classList.remove("print_session_active");
    };
  });

  useEffect(() => {
    if (countryId) {
     
      switch (countryId) {
        case COUNTRY.AUS:
        case COUNTRY.NZ:
            setLogo({
            img: URLS.READING_LOGO_BLACK,
            title: "Reading Cinemas",
            altText: "Reading Cinemas",
            label: ""
          });
          break;
      }

      const getSession = async () => {
        const currentCinema = Storage.getItem("LOCAL", "currentCinema");
        if (currentCinema && Object.keys(currentCinema).length > 0) {
          const {
            status,
            data: { data, statusCode },
          } = await printSessionApi({
            cinemaId: currentCinema.slug,
            status: "nowShowing",
            countryId: countryId,
            printsession: "print",
            flag: "",
          });
          if (status === 200 && statusCode === 200 && data.length > 0) {
            setSessions(data);
            setTheaterName(data[0].theater);
            setTimeout(() => {
                document.body.classList.add("print_session_active");
              window.print();
            }, 10);
          }
        }
      };
      getSession();
    }
  }, [countryId]);

  const showtimeKeys = (obj: any) => {
    return Object.keys(obj);
  };

  return (
    <section className="movie_session_list_wrap">
      <div className="print_header">
        {logo.img?<img src={logo.img} alt={logo.altText} />: null}
        <h5 className="location_name">{theaterName}</h5>
      </div>
      <div className="movie_session_list_session">
        {sessions.map((movie: IPrintSessions, index: number) => (
          <div className="each_movie" key={index}>
            <div className="print_movie_info">
              <h6 className="print_movie_name">{movie?.name}</h6>
              <p className="print_rating_text">{movie?.ratingName}</p>
              <div className="print_each_row">
                {showtimeKeys(movie.showtime).map(
                  (showdate: any, dataIndex: number) => (
                    <table key={dataIndex}>
                      <thead>
                        <tr>
                          <th id="head_row"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{showdate}</td>
                          <td>
                            {movie.showtime[showdate].map((showtime) => (
                              <span>{showtime.time + " "}</span>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default PrintSession;
