import { all } from 'redux-saga/effects';
import { applicationWatcher } from './application/saga';
import { filmsWatcher } from './films/saga';
import { ticketBookingWatcher } from './ticketBooking/saga';
import { foodBeverageActionWatcher } from './foodAndBeverage/saga';
import { authWatcher } from './auth/saga';
import { giftshopWatcher } from './giftshop/saga';

export default function* RootSaga(){
   yield all([
      applicationWatcher(),
      filmsWatcher(),
      ticketBookingWatcher(),
      foodBeverageActionWatcher(),
      authWatcher(),
      giftshopWatcher(),
   ])
}