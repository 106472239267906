import { FC, useEffect } from "react";
import "./signupBanner.scss";
import {
  showLogin,
  updateMembbershipPurchase,
} from "../../../../store/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { COUNTRY } from "../../../../constants/app";
import HelmetTitle from "../../../Helmet";
import { getSettingsWatcher } from "../../../../store/application/action";
import { newMembership } from "../../../../services/rewards";
import ANGELIKA_MEMBERSHIP_TABLE from "../../../Membership/CompareAllTable/Angelika";
import STATE_MEMBERSHIP_TABLE from "../../../Membership/CompareAllTable/State";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { setModal, toggleTicketModal } from "../../../../store/ticketBooking/action";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import { TICKET_FLOW } from "../../../../constants/ticket";
import AUSTRALIA from "../../../Membership/CompareAllTable/Australia";


interface ISignupBannerProps {
  onCancel: () => void;
}

const SignupBanner: FC<ISignupBannerProps> = ({ onCancel }) => {
  const { countryId, currentCinema, settings, ticketDetails } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId as COUNTRY,
    currentCinema: state.applicationReducer.currentCinema,
    settings: state.applicationReducer.settings,
    ticketDetails: state.ticketBookingReducer.ticketDetails
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (countryId) {
      dispatch(getSettingsWatcher({ countryId }));
    }
  }, [])


  const onSignuupFree = async () => {

    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
        MODAL_TYPE.MEMBERSHIP_AUTH
        ],
        type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
        data: 'account'
      })
    );
    dispatch(toggleTicketModal(true));
    dispatch(showLogin(false));
    dispatch(updateMembbershipPurchase(null));
  }
  const onSignupPaid = async () => {
    await newMembership(countryId, currentCinema, dispatch)
    dispatch(showLogin(false));
  };

  const gotToRewardPage = () => {
    navigate(`/${ROUTES.MEMBERSHIPS}`);
    onCancel();
  }

  return (
    <div>
      <HelmetTitle title="Sign-up" description="Sign-up" />
      <div className="signup-membership-table">
        {countryId === COUNTRY.ANG ?
          <ANGELIKA_MEMBERSHIP_TABLE paidPurchase={onSignupPaid} freePurchase={onSignuupFree} /> :
          countryId === COUNTRY.STA ? <STATE_MEMBERSHIP_TABLE paidPurchase={onSignupPaid} freePurchase={onSignuupFree} />
            :  countryId === COUNTRY.AUS || countryId === COUNTRY.NZ ?<AUSTRALIA paidPurchase={onSignupPaid} freePurchase={onSignuupFree} />:null}
        <div className="terms-and-condition">
          <p>Conditions apply to benefits and rewards.</p>
        </div>
        <div className="membership-page">
          <button onClick={gotToRewardPage}>Go To Membership Rewards Page</button>
        </div>
      </div>
    </div>
  );
};

export default SignupBanner;
