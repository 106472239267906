import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import { logOutApi, userConfirmationApi } from "../../../../services/auth";
import { RootState } from "../../../../store";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { BRANDS, COUNTRY, MEMBERSHIP_TYPE } from "../../../../constants/app";
import { ROUTES } from "../../../../constants/path";
import { formatMembershipType } from "../../../../utils/rewards";
import { logoutAction } from "../../../../store/auth/action";
import { setContentModal, toggleContentModal } from "../../../../store/application/action";

interface IUpdateUserProps {}

const UpdateUser: FC<IUpdateUserProps> = () => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));
  const { code } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userConfirmation = async () => {
    const {
      data: { data },
    } = await userConfirmationApi({ code: code });
    await logOutApi();
    dispatch(logoutAction({}));
    if (data && data.status == true) {
        dispatch(setContentModal({ type: ROUTES.UPDATE_ACCOUNT_VERIFY_INFO }));
        dispatch(toggleContentModal(true));
    } else {
      toast.error("Given link expired.");
    }
    setTimeout(()=>{
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    },1000)
   
  };

  useEffect(() => {
    if (code && countryId) {
      userConfirmation();
    }
  }, [code, countryId]);

  return null;
};

export default UpdateUser;
