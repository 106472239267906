import React, { FC, useEffect, useMemo, useState } from "react";
import GooglePayButton from "@google-pay/button-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { COUNTRY, COUNTRY_CODE, CURRENCY } from "../../../../constants/app";
import { concessionPriceCalculation } from "../../../../utils/tickets";
import { URLS } from "../../../../constants/url";
import {
  fetchPaymentIntentClientSecret,
  verifyFatzebraPaymentTokenApi,
} from "../../../../services/payment";
import { PAYMENT_TYPE } from "../../../../models/payment";
declare function Stripe(key: any, object: any): any;

interface IStripePaymentProps {
  processPayment: any;
  isValid: any;
  onError: any;
  checkValid: () => void;
}

const StripePayment: FC<IStripePaymentProps> = ({
  isValid,
  processPayment,
  onError,
  checkValid,
}) => {
  const { countryId, orderCartData, ticketDetails, userDetails } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId as COUNTRY,
      orderCartData: state.foodBeverageReducer.orderCartData,
      ticketDetails: state.ticketBookingReducer.ticketDetails,
      userDetails: state.authReducer.userDetails,
    })
  );

  const [env, setEnv] = useState<any>(process.env.REACT_APP_PAYMENT_ENV!);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [provider, setProvider] = useState<any>(null);
  const [showButton, setShowButton] = useState(false);

  const concessionTotal = useMemo(() => {
    return concessionPriceCalculation(orderCartData);
  }, [orderCartData]);

  const total = useMemo(() => {
    return (
      (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
    ).toFixed(2);
  }, [ticketDetails, concessionTotal]);

  const submitWalletPay = (result: any, ev: any) => {
    let res = ev.paymentMethod;

    processPayment(
      {
        cardDetails: {
          expirymonth: res.card.exp_month + "",
          expiryyear: res.card.exp_year + "",
          cardNumber: res.card.last4,
          expiryDate:
            res.card.exp_month +
            "/" +
            res.card.exp_year.substring(2, res.card.exp_year.length),
          cardHolderName: "",
          cvv: "",
        },
        paymentIntentId: result.paymentIntent.id,
      },
      PAYMENT_TYPE.GOOGLE_PAY
    );
  };

  useEffect(() => {
    if (countryId && total) {
      let stripe = Stripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLEKEY!}`, {
        apiVersion: "2020-08-27",
      });

      var paymentRequest = stripe.paymentRequest({
        country: COUNTRY_CODE[countryId],
        currency: CURRENCY[countryId].toLowerCase(),
        total: {
          label: "Total Price",
          amount: +total * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      var elements = stripe.elements();
      let that = this;
      var prButton = elements.create("paymentRequestButton", {
        paymentRequest: paymentRequest,
        style: {
          paymentRequestButton: {
            type: "default",
            // One of 'default', 'book', 'buy', or 'donate'
            // Defaults to 'default'

            theme: "dark",
            // One of 'dark', 'light', or 'light-outline'
            // Defaults to 'dark'

            height: "50px",
            // Defaults to '40px'. The width is always '100%'.
          },
        },
      });

      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then(function (result: any) {
        if (result) {
          if (result.applePay) {
          } else if (result.googlePay) {
          }
          prButton.mount("#payment-request-button");
        } else {
        }
      });

      paymentRequest.on("paymentmethod", function (ev: any) {
        const intentPayload = {
          countryId: countryId,
          amount: total,
          description: ticketDetails?.userSessionId!,
        };

        fetchPaymentIntentClientSecret(intentPayload)
          .then((data: any) => {
            let clientSecret = data.data.client_secret;

            stripe
              .confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
              )
              .then(function (confirmResult: any) {
                if (confirmResult.error) {
                  ev.complete("fail");
                  onError();
                } else {
                  ev.complete("success");
                  if (
                    confirmResult.paymentIntent.status === "requires_action"
                  ) {
                    stripe
                      .confirmCardPayment(clientSecret)
                      .then(function (result: any) {
                        if (result.error) {
                        } else {
                          submitWalletPay(confirmResult, ev);
                        }
                      });
                  } else {
                    submitWalletPay(confirmResult, ev);
                  }
                }
              });
          })
          .catch((error) => {
            ev.complete("fail");
            onError();
          });
      });
    }
  }, [countryId, total]);

  useEffect(() => {
    setShowButton(isValid);
  }, [isValid]);

  return !showButton ? (
    <button
      type="button"
      className="btn black_btn"
      style={{ borderRadius: 5 }}
      onClick={checkValid}
    >
      <img style={{ width: 67, marginTop: 2 }} src={URLS.GPAY_ICON} alt="" />
    </button>
  ) : (
    <div>
      <div
        id="payment-request-button"
        style={{ minWidth: "132px", marginTop: "15px", marginLeft: "20px" }}
      ></div>
    </div>
  );
};

export default StripePayment;
