import React,{ FC } from "react";
import Slick from "react-slick";
import "./dateSlider.scss";

interface IDatSliderProps {
  data?: Array<any>;
  onChange?: (dateObj: any) => void;
  selected?: string;
}

const DateSlider: FC<IDatSliderProps> = ({
  data,
  onChange,
  selected,
  ...props
}) => {
  const settings = {
    className: "",
    // centerMode: true,
    centerPadding: "0px",
    dots: false,
    lazyload: true,
    infinite: false,
    speed: 250,
    slidesToShow: 6,
    autoplay: false,
    cssEase: "linear",
    slidesToScroll: 6,
    // initialSlide: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 320,
        settings: { slidesToShow: 3,    slidesToScroll: 3 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 3,    slidesToScroll: 3 },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        },
      },
      {
        breakpoint: 2080,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        },
      },
    ],
  };

  return (
    <div className="dates-tab">
      <div className="carousel">
        <Slick {...settings} {...props}>
          {data?.map((dateObj, index) => (
            <div key={`date${index}`} className="slide nav-item text-center">
              <a
                href="javascript:void(0)"
                className={`nav-link ${
                  selected === dateObj.value ? "active" : ""
                }`}
                onClick={() => onChange && onChange(dateObj)}
              >
                {dateObj.disp_date}
              </a>
            </div>
          ))}
        </Slick>
      </div>
    </div>
  );
};

export default DateSlider;
