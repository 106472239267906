import React, { FC, useEffect, useState } from "react";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import './giftcard.scss';
import { GiftCardDetail, ISavedGiftCards } from "../../../../models/giftcard";
import { getSavedGiftCardsWatcher } from "../../../../store/auth/action";
import Modal from "../../../../components/UI/Modal";
import Barcode from "react-barcode";
import AddCard from "./Add";
import { COUNTRY } from "../../../../constants/app";
import { removedGiftCardApi } from "../../../../services/auth";
import { toast } from "react-toastify";
import moment from "moment";

interface IGiftCardProps { }

enum TAB_TYPE {
    UPCOMING = 'upcoming',
    HISTORY = 'history',
}

const GiftCard: FC<IGiftCardProps> = () => {
    const { countryId, userDetails, memberDetail, savedGiftCards } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            userDetails: state.authReducer.userDetails,
            memberDetail: state.authReducer.memberDetail,
            savedGiftCards: state.authReducer.savedGiftCards
        })
    );

    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [giftCardModal, setGiftCardModal] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [cardDetails, setCardDetails] = useState<GiftCardDetail[]>([]);
    const [selectedCard, setSelectedCard] = useState<GiftCardDetail | null>(null);

    const getGiftCards = () => {
        dispatch(
            getSavedGiftCardsWatcher({
                memberRefId: userDetails?.result?.memberRefId!,
                countryId: countryId,
                emailId: userDetails?.email!,
            })
        );
    }

    useEffect(() => {
        getGiftCards();
    }, []);

    useEffect(() => {
        if (savedGiftCards) {
            setCardDetails(savedGiftCards);
        }
    }, [savedGiftCards]);

    const openModal = (data: ISavedGiftCards) => {
        setModal(true);
        setSelectedCard(data)
    }

    const onCloseModal = () => {
        setModal(false);
    }

    const onCloseGiftCardModal = () => {
        setGiftCardModal(false)
    }

    const openRemoveModal = () => {
        setRemoveModal(true)
    }

    const onClodeRemoveModal = () => {
        setRemoveModal(false)
    }

    const removeCard = () => {
        setIsRemove(true)
    }

    const cancel = () => {
        setIsRemove(false)
    }

    const removeGiftCard = (data: ISavedGiftCards) => {
        setSelectedCard(data);
        setRemoveModal(true);
    }

    const deleteCard = async () => {
        await removedGiftCardApi({
            memberRefId: userDetails?.result?.memberRefId!,
            cardNumber: selectedCard?.GiftcardNumber!,
            countryId: `${countryId}`,
            emailId: userDetails?.email!,
        }).then((res: any) => {
            if (res?.data?.data && res?.data?.data.status && res?.data?.data.status === "true") {
                toast.success("Gift Card removed successfully");
                setRemoveModal(false)
                getGiftCards();
            }
        });
    };


    const onReset = () => {
        setGiftCardModal(false)
    }

    const addCard = () => {
        setGiftCardModal(true)
    }


    return <>
        <div className="giftcard-tab-wrapper">
            {savedGiftCards.map((v: GiftCardDetail) => <div className={`gift-card ${moment.parseZone(v.expiry) < moment.parseZone()?'disable':''}`}>
                <div className="logo">
                    {countryId === COUNTRY.AUS || countryId === COUNTRY.NZ ?
                        <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'reading_giftcard.png'}`} /> :
                        countryId === COUNTRY.ANG ?
                            <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'angelika_giftcard.png'}`} /> :
                            countryId === COUNTRY.STA ?
                                <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'state_cinema_giftcard.png'}`} /> :
                                null}
                </div>
                <div className="content-wrapper">
                    <div className="content1">
                        <div className="member-title">Gift Card #</div>
                        <div className="memberId">{v.GiftcardNumber}</div>
                    </div>
                    <div className="content2">
                        <div className="expired-on">
                        {moment.parseZone(v.expiry) < moment.parseZone()?<><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'warning-red.svg'} />
                            <span>Expired On</span></>:
                            <span>Expires On</span>}
                        </div>
                        <div className="expire-date">{moment
                    .parseZone(v.expiry)
                    .format("DD MMM YYYY")}</div>
                    </div>
                </div>

                {isRemove ? <div className="scan-barcode">
                    <button onClick={() => removeGiftCard(v)}>
                        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'trash.svg'} />
                        REMOVE THIS GIFT CARD
                    </button>
                </div> : <div className="scan-barcode">
                    <button onClick={() => openModal(v)}>
                        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'scan_barcode.svg'} />
                        View/scan barcode
                    </button>
                </div>}
                <div className="balance-wrapper">
                    <div className="balance">
                        <div className="label">Balance</div>
                        <div className="value">${v.balance}</div>
                    </div>
                </div>
            </div>)}
            <div className="gift-card-btn">
                {!isRemove ? <><button className="add-btn" onClick={addCard}><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'plus_16x16_white.svg'} />ADD GIFT CARD</button>
                    {savedGiftCards.length > 0 ? <button className="remove-btn" onClick={removeCard}>REMOVE GIFT CARD</button> : null}</> :
                    <button className="cancel" onClick={cancel}><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'cross_white.svg'} />Cancel Changes & Close</button>}
            </div>
        </div>
        <Modal
            className={`barcode-viewer content-modal`}
            visible={modal}
            showCloseBtn={false}
        >
            <div className="modal fade show" style={{ display: "block" }}>
                <div
                    className={"modal-dialog modal-dialog-centered modal-md"}
                >
                    <div className={`modal-content`}>
                        <div className="modal-header-image">
                            <button
                                type="button"
                                className="close-modal"
                                onClick={onCloseModal}
                            >
                                <img
                                    className="close_icon"
                                    src={URLS.CLOSE_ICON}
                                    alt="close_icon"
                                    title="close_icon"
                                />
                            </button>
                        </div>
                        <div className="modal-header">
                            <h4>Scan Gift Card barcode.</h4>
                        </div>
                        <div className="modal-body">
                            <div className="contact-us-form form_page">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <Barcode
                                            width={3}
                                            displayValue={false}
                                            value={selectedCard?.GiftcardNumber!}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="button_wrap text-center div">
                                            <button
                                                type="button"
                                                className="btn black_btn mx-1"
                                                onClick={() => onCloseModal()}
                                            >
                                                CLOSE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal visible={giftCardModal} className="manage-cards-popup" showCloseBtn={false}>
            <div className="modal fade show" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered modal-lg ">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close-modal"
                            onClick={onCloseGiftCardModal}
                        >
                            <img
                                className="close_icon"
                                src={URLS.CLOSE_ICON}
                                alt="close_icon"
                                title="close_icon"
                            />
                        </button>
                        <div className="modal-header">
                            <div className="title title_append">Add Gift Card</div>
                        </div>
                        <div className="modal-body">
                            <p className="enter-gift-card">Enter gift card details below.</p>
                            <div className="manage_card_icons">
                                <AddCard onReset={onReset} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal visible={removeModal} className="manage-cards-popup" showCloseBtn={false}>
            <div className="modal fade show" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <div className="conformation_wrap">
                                <h4>Are you sure want to remove</h4>
                                <div className="conformation_action">
                                    <button
                                        className="del_btn"
                                        type="button"
                                        onClick={deleteCard}
                                    >
                                        Yes remove it
                                    </button>
                                    <button
                                        className=""
                                        type="button"
                                        onClick={onClodeRemoveModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal></>

};

export default GiftCard;
