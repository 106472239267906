import React, { FC, useMemo } from "react";
import "./pagenotfound.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ROUTES } from "../../../constants/path";

interface IPageNotFoundProps {}

const PageNotFound: FC<IPageNotFoundProps> = () => {
  const { settings, currentCinema } = useSelector((state: RootState) => ({
    settings: state.applicationReducer.settings,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const showMaintenance = useMemo(() => {
    return settings?.enableUndermaintenance === 1 ? true : false;
  }, [settings]);

  return (
    <section className="errorpage">
      <div className="container d-flex h-100">
        <div className="row m-auto">
          {showMaintenance ? (
            <div className="col-md-12">
              <article className="maintenance_wrap">
                <h1>We&rsquo;ll be back soon!</h1>
                <div className="undermaintenancemsg">
                  <p>
                    Sorry for the inconvenience but we&rsquo;re performing some
                    maintenance at the moment. If you need to you can always{" "}
                    <a href={"mailto:"+settings?.underMaintenanceEmail}>contact us</a>, otherwise we&rsquo;ll be
                    back online shortly!
                  </p>
                  <p>&mdash; The Team</p>
                </div>
              </article>
            </div>
          ) : (
            <div className="col">
              <div className="error_title">
                <div className="num_text">
                  <span className="theme_red">4</span>
                  <span className="theme_black">0</span>
                  <span className="theme_red">4</span>
                </div>
                <span className="error_text">PAGE NOT FOUND</span>
                <a
                  href={`/${currentCinema?.alias}/${ROUTES.MOVIES}`}
                  className="error_link"
                >
                  Click here to go back home page
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;