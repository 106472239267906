import { takeLatest, call, put } from 'redux-saga/effects';
import { ACTIONS } from './type';
import { getBookedTicketsError, getBookedTicketsSuccess, getFoodItemsError, getFoodItemsSuccess, getLicenseError, getLicenseSuccess, setConcessionDiscount } from './action';
import { getBookedTicketsApi, getItemsApi, getLicenseApi } from '../../services/concessions';
import { AnyAction } from '@reduxjs/toolkit';

function* onGetFoodItemsData(action: AnyAction) {
    let { payload } = action;
    const { status, response, error } = yield call(getItemsApi, payload);
    if (status) {
      yield put(getFoodItemsSuccess(response?.data?.concessions));
      yield put(setConcessionDiscount(response?.data?.memberItems));
    } else {
      yield put(getFoodItemsError(error));
    }
}

function* getLicense(action: AnyAction){
  const { payload } = action;
  const { status, response,error } = yield call(getLicenseApi, payload);
  if(status){
    yield put(getLicenseSuccess(response.data.data));
  }else{
    yield put(getLicenseError(error));
  }

}

function* getBookedTickets(action: AnyAction){
  const { payload } = action;
  const { status, response,error } = yield call(getBookedTicketsApi, payload);
  if(status){
    yield put(getBookedTicketsSuccess(response.data.data));
  }else{
    yield put(getBookedTicketsError(error));
  }
}
  
// function* onPostFoodOrderData(action: IAction) {
//     let { payload, resolve, reject } = action;
  
//     function postFoodOrderApi() {
//       return API_CALLER.post(concessionsOrder, payload)
//         .then((response) => ({ response }))
//         .catch((error) => ({ error }));
//     }
  
//     const { response, error } = yield call(postFoodOrderApi);
//     if (response) {
//       resolve(response?.data)
//       yield put(postFoodOrderSuccess(response?.data));    
//     } else {
//       reject(error)
//       yield put(postFoodOrderError(error));
//     }
// }
  
export function* foodBeverageActionWatcher() {
    yield takeLatest(ACTIONS.GET_FOOD_BEVERAGE_WATCHER, onGetFoodItemsData);
    yield takeLatest(ACTIONS.GET_LICIENSE, getLicense);
    yield takeLatest(ACTIONS.GET_BOOKED_TICKETS, getBookedTickets);
    //yield takeLatest(FOOD_ORDER_POST_WATCHER, onPostFoodOrderData);
}
