import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import CreditCard, {
  ICardRef,
} from "../../../../../components/TicketBooking/Payment/CreditCard";
import "./payment.scss";
import { IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import { concessionPriceCalculation } from "../../../../../utils/tickets";
import {
  EncryptionKeyResponse,
  PAYMENT_TYPE,
} from "../../../../../models/payment";
import ReCAPTCHA from "react-google-recaptcha";
import {
  CAPTCHA_SITE_KEY,
  COUNTRY,
  PAYMENT_PROVIDERS,
  VACCINATION_LOCATIONS,
} from "../../../../../constants/app";
import { memberValidationApi } from "../../../../../services/auth";
import { memberValidationSuccess } from "../../../../../store/auth/action";
import {
  completeOrderApi,
  generateMailApi,
  getCreditCardEncryptionKey,
  getPaymentMethod,
  makeGiftCardPaymentAPI,
  makePaymentApi,
  makeVoucherPaymentAPI,
  orderContinueApi,
  refundGiftCardApi,
  refundPayment,
  refundVoucherApi,
} from "../../../../../services/payment";
import { encryptData } from "../../../../../utils/helper";
import {
  setModal,
  updateBookingId,
} from "../../../../../store/ticketBooking/action";
import { IFormatedTicketType, MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../models/tickets";
import { TICKET_FLOW } from "../../../../../constants/ticket";
import GPay from "../../../../../components/TicketBooking/Payment/GPay";
import { toast } from "react-toastify";
import GiftCard from "../../../../../components/TicketBooking/Payment/GiftCard";
import NswVoucher from "../../../../../components/TicketBooking/Payment/NswVoucher";
import ApplePay from "../../../../../components/TicketBooking/Payment/ApplePay";
import StripePayment from "../../../../../components/TicketBooking/Payment/Stripe";
import {
  MAKE_PAYMENT_REQUEST_TYPE,
  TOKEN_PAYMENT_REQUEST_TYPE,
} from "../../../../../constants/payment";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../../store/application/action";
import { ROUTES } from "../../../../../constants/path";
import { useLocation } from "react-router";
import Click2pay from "../../../../../components/TicketBooking/Payment/Click2pay";
import { pushDataLayer } from "../../../../../utils/dataLayer";

let click2payCard:any = null;
interface IPaymentProps {
  onCancel: () => void;
  setStopTimer?: any
}

const Payment: FC<IPaymentProps> = ({ onCancel, setStopTimer }) => {
  const {
    countryId,
    modalType,
    prevModal,
    nextModal,
    ticketDetails,
    orderCartData,
    hasGL,
    ticketLessFb,
    isFutureFb,
    preOrderFb,
    currentCinema,
    films,
    isLoggedIn,
    userDetails,
    memberDetail,
    savedCards,
    voucherFB,
    voucherTicketing,
    sharedUserSession,
    seatSwapDetails,
    settings
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId as COUNTRY,
    modalType: state.ticketBookingReducer.modal.type,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    orderCartData: state.foodBeverageReducer.orderCartData,
    currentCinema: state.applicationReducer.currentCinema,
    hasGL: state.ticketBookingReducer.hasGL,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    isFutureFb: state.ticketBookingReducer.isFutureFb,
    preOrderFb: state.ticketBookingReducer.preOrderFb,
    films: state.ticketBookingReducer.films,
    isLoggedIn: state.authReducer.isLoggedIn,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
    savedCards: state.authReducer.savedCards,
    voucherFB: state.applicationReducer.settings.voucherFB,
    voucherTicketing: state.applicationReducer.settings.voucherTicketing,
    sharedUserSession: state.ticketBookingReducer.sharedUserSession,
    seatSwapDetails: state.ticketBookingReducer.seatSwapDetails,
    settings: state.applicationReducer.settings
  }));

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const cardRef = useRef<ICardRef>(null);
  const giftCardRef = useRef<ICardRef>(null);
  const voucherRef = useRef<ICardRef>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CARD);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [informationStatus, setInformationStatus] = useState(false);
  const [showWallet, setShowWallet] = useState("");

  useEffect(()=>{
    if(settings){
      setPaymentType(settings.enableClick2pay?PAYMENT_TYPE.CLICK_TO_PAY:PAYMENT_TYPE.CARD);
    }
  },[settings])

  useEffect(() => {
    if (
      countryId !== COUNTRY.NZ &&
      VACCINATION_LOCATIONS.includes(currentCinema.slug)
    ) {
      setInformationStatus(true);
    } else {
      setInformationStatus(false);
    }

    if (
      countryId !== COUNTRY.NZ &&
      !/^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      setShowWallet(PAYMENT_TYPE.GOOGLE_PAY);
    } else if (
      countryId !== COUNTRY.NZ &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      const applepaySession = (window as any).ApplePaySession;
      if (applepaySession) {
        if (applepaySession.canMakePayments()) {
          setShowWallet(PAYMENT_TYPE.APPLE_PAY);
        }
      }
    }
  }, [countryId]);

  const toggleTab = (type: PAYMENT_TYPE) => {
    setErrorMessage("");
    setPaymentType(type);
  };

  const concessionTotal = useMemo(() => {
    return concessionPriceCalculation(orderCartData);
  }, [orderCartData]);

  const isSeatSwap = useMemo(() => {
    return pathname.includes("/seat-swap/") && seatSwapDetails ? true : false;
  }, [pathname, seatSwapDetails]);

  const total = useMemo(() => {
    return (
      (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
    ).toFixed(2);
  }, [ticketDetails, concessionTotal]);

  const orderContinue: any = async () => {
    return await orderContinueApi({
      countryId: countryId,
      hasGL: hasGL,
      userSessionId: ticketDetails?.userSessionId,
      saleId: ticketDetails?.userSessionId,
    });
  };

  const completeOrder = async ({
    cardType,
    isVoucher = false,
    isGivex = 0,
    giftCardPaidAmount = 0,
  }: any) => {
    if (isLoggedIn && !ticketLessFb && isLoggedIn) {
      const memberResponse: any = await memberValidationApi({
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: ticketDetails?.userSessionId,
        cardNumber: userDetails?.cardNumber,
        countryId: countryId,
      });

      if (memberResponse.status) {
        dispatch(memberValidationSuccess(memberResponse.response));
      }
    }

    let completeOrderPayload: any = {
      countryId: countryId,
      email: userDetails?.email,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      hasGL: hasGL,
      loyaltyCardNumber:
        memberDetail &&
        memberDetail?.LoyaltyMember &&
        memberDetail?.LoyaltyMember?.CardNumber
          ? memberDetail.LoyaltyMember.CardNumber
          : "",
      paidamount:
        ((ticketDetails?.total ? ticketDetails.total : 0) + concessionTotal).toFixed(2),
      paymentAmountInCents:
        parseInt(((
          (ticketDetails?.total ? ticketDetails.total : 0) + concessionTotal
        ) * 100).toFixed()),
      paymentCardType: cardType,
      phoneNumber: userDetails?.phoneNumber
        ? userDetails?.phoneNumber
        : memberDetail &&
          memberDetail?.LoyaltyMember &&
          memberDetail?.LoyaltyMember?.MobilePhone
        ? (memberDetail?.LoyaltyMember?.MobilePhone).replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", "")
        : "",
      requestType: "completeOrder",
      saleId: ticketDetails?.userSessionId,
      userSessionId: ticketDetails?.userSessionId,
      ticketlessFB: ticketLessFb,
      isVoucher: isVoucher,
      isGivex: isGivex,
      giftCardPaidAmount: giftCardPaidAmount,
    };
    if (concessionTotal && orderCartData.length > 0) {
      completeOrderPayload.concessions = orderCartData;
      if (isFutureFb && ticketLessFb && preOrderFb) {
        completeOrderPayload["concessions"] = completeOrderPayload[
          "concessions"
        ].map((v: any) => {
          const order = { ...v };
          order.parentId = preOrderFb.id;
          return order;
        });
        completeOrderPayload["parentId"] = preOrderFb.id;
        completeOrderPayload["bookedTicket"] = true;
        completeOrderPayload["parentSaleId"] = preOrderFb.saleId;
        completeOrderPayload["parentShowDate"] = preOrderFb.parentShow;
      }
    }
    if (isFutureFb && ticketLessFb && preOrderFb) {
      completeOrderPayload["orderedConcessionDetails"] = preOrderFb?.concessions
        ? preOrderFb.concessions
        : [];
    }
    if (sharedUserSession && sharedUserSession.length > 0) {
      completeOrderPayload["sharedUserSessionId"] =
        sharedUserSession[0].userSessionId;
      if (sharedUserSession.length === 1) {
        completeOrderPayload["sharedSession"] = sharedUserSession[0];
      }
    }

    if (isSeatSwap) {
      completeOrderPayload["swapRefund"] = true;
      completeOrderPayload["swappedUserSessionId"] = seatSwapDetails.id;
    }

    if(paymentType === PAYMENT_TYPE.CLICK_TO_PAY){
      completeOrderPayload['click2payCardDetails'] = click2payCard;
    }
    return await completeOrderApi(completeOrderPayload);
  };

  const makeCardPayment = async (
    processPayload: any,
    ptype: PAYMENT_TYPE,
    key: string,
    amount: number
  ) => {
    //Make payment
    const makePaymentPayload: any = {
      amount: getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA?parseInt((amount).toFixed()):amount,
      email: userDetails?.email!,
      msgId: ticketDetails?.userSessionId!,
      saleId: ticketDetails?.userSessionId!,
      transactionReference: `invoice${ticketDetails?.userSessionId}`,
      ticketlessFB: ticketLessFb,
      requestType: MAKE_PAYMENT_REQUEST_TYPE[countryId],
      countryId: countryId,
      memberRefId: userDetails?.result?.memberRefId || "",
    };

    //Checking payment type - new card, saved card, apple pay or google pay
    if (
      (processPayload.cardDetails && processPayload.action === "newCard") ||
      ptype === PAYMENT_TYPE.APPLE_PAY ||
      ptype === PAYMENT_TYPE.GOOGLE_PAY
    ) {
      const encryptKey = key + (ticketLessFb ? "dfb" : films?.movieId);
      const encryptedData = encryptData(
        JSON.stringify(processPayload.cardDetails),
        encryptKey
      );
      makePaymentPayload.data = encryptedData;
      makePaymentPayload.saveCreditCard = processPayload?.saveCard;
    } else if (
      processPayload.cardDetails &&
      processPayload.action === "savedCard"
    ) {
      makePaymentPayload.tokenValue = processPayload.cardDetails.token;
      makePaymentPayload.requestType = TOKEN_PAYMENT_REQUEST_TYPE[countryId];
      if(ptype === PAYMENT_TYPE.CLICK_TO_PAY){
        makePaymentPayload.paymentMethodType = processPayload.cardDetails.paymentMethodType
      }
    }

    if (ptype === PAYMENT_TYPE.APPLE_PAY || ptype === PAYMENT_TYPE.GOOGLE_PAY) {
      makePaymentPayload.googleApplePay = true;
      makePaymentPayload.id = processPayload.paymentIntentId;
    }
    if (countryId === COUNTRY.ANG || countryId === COUNTRY.AUS || countryId === COUNTRY.STA) {
      makePaymentPayload.deviceId = (document.getElementById('pmnts_id') as any)?.value;
    }
    return await makePaymentApi(makePaymentPayload);
  };

  const refundCard = async (
    txnID: string,
    transactionReference: string,
    amount: number
  ) => {
    const refundDetails: any = {
      amount: getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA?parseInt((amount).toFixed()):amount,
      countryId: countryId,
      txnType: 4,
      requestType: "refundPayment",
      paymentMethod: getPaymentMethod(countryId),
      txnID: txnID,
      transactionReference: transactionReference,
      saleId: ticketDetails?.userSessionId,
    };
    if (ticketLessFb) {
      refundDetails["ticketlessFB"] = true;
    }
    return await refundPayment(refundDetails);
  };

  const processPayment = async (processPayload: any, ptype: PAYMENT_TYPE) => {
    const decryptPayload: any = {
      countryId: countryId,
      saleId: ticketDetails?.userSessionId!,
    };
    if (ticketLessFb) {
      decryptPayload.requestType = "ticketlessFB";
    }
    getCreditCardEncryptionKey(
      decryptPayload,
      async (data: EncryptionKeyResponse | null) => {
        if (data) {
          //order api
          //const ticketAmount = getPaymentMethod() === "fatzebra"?
          //(Math.round((ticketDetails?.total ? ticketDetails.total : 0) + concessionTotal) *
          //100) //Testing only
           const ticketAmount =
            getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA
              ?  (total * 100)
              : ((ticketDetails?.total ? ticketDetails.total : 0) +
              concessionTotal);
          const orderResponse = await orderContinue();
          if (orderResponse.status) {
            const makePaymentResponse: any = await makeCardPayment(
              processPayload,
              ptype,
              data.data.key,
              ticketAmount
            );
            if (makePaymentResponse.status) {
              const completeOrderResponse: any = await completeOrder({
                cardType: "ccard",
              });
              if (completeOrderResponse.status) {
                setDataLayer();
                dispatch(updateBookingId(completeOrderResponse.VistaBookingId));
                onNext();
              } else {
                refundCard(
                  makePaymentResponse.data.txnID,
                  makePaymentResponse.data.transactionReference,
                  getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA
                    ? Number(
                        ticketDetails?.total !== undefined
                          ? ticketDetails?.total + concessionTotal
                          : concessionTotal
                      ) * 100
                    : Number(
                        ticketDetails?.total !== undefined
                          ? ticketDetails?.total + concessionTotal
                          : concessionTotal
                      )
                );
                onError(completeOrderResponse?.error);
              }
            } else {
              onError(makePaymentResponse?.error);
            }
          } else {
            onError(orderResponse?.error);
          }
        }
      }
    );
  };

  const onNext = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  };

  const onError = (errorMsg?: string) => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][MODAL_TYPE.TICKET_ERROR];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
        errorMsg,
      })
    );
  };

  const onCardPayment = async () => {
    const cardDetails: any = cardRef?.current?.submit();
    if (!cardDetails) {
      return;
    }
    const { card, action, saveCard } = cardDetails;
    if (card && !isTermsChecked && !isLoggedIn) {
      setErrorMessage("Please agree Terms and conditions");
      return;
    }

    if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return;
    }

    if (action === "newCard") {
      processPayment(
        { cardDetails: card, action, saveCard },
        PAYMENT_TYPE.CARD
      );
    } else if (action === "savedCard") {
      processPayment({ cardDetails: card, action }, PAYMENT_TYPE.CARD);
    }
  };

  const onGiftCard = async () => {
    const giftCardDetails = giftCardRef?.current?.submit();
    if (!giftCardDetails) {
      return;
    }
    if (giftCardDetails && !isTermsChecked && !isLoggedIn) {
      setErrorMessage("Please agree Terms and conditions");
      return;
    }

    if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return;
    }

    const makeRefund = async (payload: any) => {
      return await refundGiftCardApi(payload);
    };

    const orderResponse = await orderContinue();
    if (orderResponse.status) {
      let paymentSuccess = true;
      let payableGiftCardAmt = 0;
      let params: any = [];
      for (let card of giftCardDetails.giftCards) {
        let payload: any = {
          countryId: countryId,
          requestType: "giftcardRedemption",
          paymentMethod: "vista",
          saleId: ticketDetails?.userSessionId,
          cardNumber: card.card,
          transactionReference: ticketDetails?.userSessionId,
          amount: card.balance,
          totalAmount: giftCardDetails.total,
          ticketlessFB: ticketLessFb,
        };
        const response: any = await makeGiftCardPaymentAPI(payload);
        if (response.status) {
          payableGiftCardAmt = payableGiftCardAmt + card.balance;
          payload["paymentStatus"] = "success";
          payload["ticketSalesPaidStatus"] = 1;
          payload["txnReference"] = response.transactionCode;
        } else {
          payload["paymentStatus"] = "failure";
          payload["ticketSalesPaidStatus"] = 0;
          paymentSuccess = false;
        }
        params.push(payload);
      }

      if (paymentSuccess) {
        if (giftCardDetails.enableCreditCard) {
          const decryptPayload: any = {
            countryId: countryId,
            saleId: ticketDetails?.userSessionId!,
          };
          if (ticketLessFb) {
            decryptPayload.requestType = "ticketlessFB";
          }
          getCreditCardEncryptionKey(
            decryptPayload,
            async (data: EncryptionKeyResponse | null) => {
              if (data) {
                const makePaymentResponse: any = await makeCardPayment(
                  {
                    cardDetails: giftCardDetails.cardDetails,
                    action: giftCardDetails.action,
                    saveCard: giftCardDetails.saveCard,
                  },
                  PAYMENT_TYPE.CARD,
                  data.data.key,
                  getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA
                    ? giftCardDetails.balance * 100
                    : giftCardDetails.balance
                );
                if (makePaymentResponse.status) {
                  const completeOrderResponse: any = await completeOrder({
                    cardType: "ccard",
                    isGivex: 1,
                    giftCardPaidAmount: payableGiftCardAmt,
                  });
                  if (completeOrderResponse.status) {
                    setDataLayer();
                    await dispatch(
                      updateBookingId(completeOrderResponse.VistaBookingId)
                    );
                    onNext();
                  } else {
                    for (let param of params) {
                      await makeRefund(param);
                    }
                    await refundCard(
                      makePaymentResponse.data.txnID,
                      makePaymentResponse.data.transactionReference,
                      getPaymentMethod(countryId) ===
                        PAYMENT_PROVIDERS.FATZEBRA
                        ? giftCardDetails.balance * 100
                        : giftCardDetails.balance
                    );
                    onError(completeOrderResponse?.error);
                  }
                } else {
                  onError(makePaymentResponse.error);
                }
              }
            }
          );
        } else {
          const completeOrderResponse: any = await completeOrder({
            cardType: "givex",
            isGivex: 1,
            giftCardPaidAmount: payableGiftCardAmt,
          });
          if (completeOrderResponse.status) {
            setDataLayer();
            await dispatch(
              updateBookingId(completeOrderResponse.VistaBookingId)
            );
            onNext();
          } else {
            for (let param of params) {
              await makeRefund(param);
            }
            onError(completeOrderResponse?.error);
          }
        }
      } else {
        for (let param of params) {
          await makeRefund(param);
        }
        onError();
      }
    } else {
      onError(orderResponse?.error);
    }
  };

  const onNswVoucher = async () => {
    const voucherDetails = voucherRef?.current?.submit();
    if (!voucherDetails) {
      return;
    }
    if (voucherDetails && !isTermsChecked && !isLoggedIn) {
      setErrorMessage("Please agree Terms and conditions");
      return;
    }

    if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return;
    }

    const makeRefund = async (txnReference: string) => {
      const payload = {
        amount: voucherDetails.cardChargedAmount,
        countryId: countryId,
        requestType: "voucherRefund",
        paymentMethod: "voucher",
        code: txnReference,
        voucherCode: voucherDetails.voucher,
        saleId: ticketDetails?.userSessionId,
        location: currentCinema.slug.toLowerCase(),
      };
      await refundVoucherApi(payload);
      onError();
    };

    const payload = {
      countryId: countryId,
      requestType: "voucherPayment",
      paymentMethod: "voucher",
      saleId: ticketDetails?.userSessionId,
      code: voucherDetails.voucher,
      amount: voucherDetails.cardChargedAmount,
      totalAmount: voucherDetails.total,
      location: currentCinema.slug.toLowerCase(),
      ticketlessFB: ticketLessFb,
    };

    const orderResponse = await orderContinue();
    if (orderResponse.status) {
      const response: any = await makeVoucherPaymentAPI(payload);
      if (response.status) {
        if (voucherDetails.enableCreditCard) {
         const decryptPayload: any = {
            countryId: countryId,
            saleId: ticketDetails?.userSessionId!,
          };
          if (ticketLessFb) {
            decryptPayload.requestType = "ticketlessFB";
          }
          getCreditCardEncryptionKey(
            decryptPayload,
            async (data: EncryptionKeyResponse | null) => {
              if (data) {
                const makePaymentResponse: any = await makeCardPayment(
                  {
                    cardDetails: voucherDetails.cardDetails,
                    action: voucherDetails.action,
                    saveCard: voucherDetails.saveCard,
                  },
                  PAYMENT_TYPE.CARD,
                  data.data.key,
                  voucherDetails.balance * 100
                );
                if (makePaymentResponse.status) {
                  const completeOrderResponse: any = await completeOrder({
                    cardType: "ccard",
                  });
                  if (completeOrderResponse.status) {
                    setDataLayer();
                    dispatch(
                      updateBookingId(completeOrderResponse.VistaBookingId)
                    );
                    onNext();
                  } else {
                    await makeRefund(response.transactionCode);
                    await refundCard(
                      makePaymentResponse.data.txnID,
                      makePaymentResponse.data.transactionReference,
                      voucherDetails.balance * 100
                    );
                    onError(completeOrderResponse?.error);
                  }
                } else {
                  onError(makePaymentResponse.error);
                }
              }
            }
          );
        } else {
          const completeOrderResponse: any = await completeOrder({
            cardType: "voucher",
            isVoucher: true,
          });
          if (completeOrderResponse.status) {
            setDataLayer();
            dispatch(updateBookingId(completeOrderResponse.VistaBookingId));
            onNext();
          } else {
            await makeRefund(response.transactionCode);
            onError(completeOrderResponse?.error);
          }
        }
      } else {
        onError(response?.error);
      }
    } else {
      onError(orderResponse?.error);
    }
  };

  const onPurchase = () => {
    if (setStopTimer) {
      setStopTimer(true);
    }
    switch (paymentType) {
      case PAYMENT_TYPE.CARD:
        onCardPayment();
        break;
      case PAYMENT_TYPE.GIFT_CARD:
        onGiftCard();
        break;
      case PAYMENT_TYPE.NSW_VOUCHER:
        onNswVoucher();
        break;
    }
  };

  const onChangeCaptcha = (event: any) => {
    if (event) {
      setIsCaptchaChecked(true);
      setErrorMessage("");
    } else {
      setIsCaptchaChecked(false);
      setErrorMessage("Verification Code is required");
    }
  };

  const onCheckTerms = (event: any) => {
    const {
      target: { checked },
    } = event;
    setIsTermsChecked(checked);
  };

  const isValid = useMemo(() => {
    if (paymentType === PAYMENT_TYPE.GOOGLE_PAY) {
      if (!isTermsChecked) {
        return false;
      } else if (!isCaptchaChecked) {
        return false;
      }
    }
    setErrorMessage("");
    setErrorMessage("");
    return true;
  }, [isTermsChecked, isCaptchaChecked, paymentType]);

  const checkValid = () => {
    if (!isTermsChecked) {
      setErrorMessage("Please agree Terms and conditions");
      return false;
    } else if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return false;
    }
  };

  const openTerms = () => {
    dispatch(
      setContentModal({
        type: "Etix Terms",
      })
    );
    dispatch(toggleContentModal(true));
  };

  const isNswSupported = useMemo(() => {
    return process.env.REACT_APP_NSW_VOUCHER_STATE &&
      process.env.REACT_APP_NSW_VOUCHER_STATE.includes(currentCinema.stateCode) &&
      ((voucherTicketing === "1" && !ticketLessFb) ||
        (voucherFB === "1" && ticketLessFb))
      ? true
      : false;
  }, [currentCinema]);

  const Banner = () => {
    return (
      <div className="col-12 col-md-12 pl-lg-0 ">
        <div className="swap-banner">
          <p>
            Your refund total will be returned to the original form of payment.
          </p>
          <p className="italic">
            Please allow up to 72 business hours for the funds to reflect in
            your account.
          </p>
        </div>
      </div>
    );
  };

  const showContent = useMemo(()=>{
    return paymentType === PAYMENT_TYPE.CLICK_TO_PAY?false:true
  },[paymentType])

  const click2payfunc = (data: any)=>{
    const { cardExpiry, cardHolder, cardNumber, cardSubcategory, cardType, token } = data
    click2payCard = {cardExpiry, cardHolder, cardNumber, cardSubcategory, cardType,
        paymentMethodType: 'click2pay'
    };
    processPayment({ cardDetails:  data, action: "savedCard" }, PAYMENT_TYPE.CLICK_TO_PAY);
  }

  const onBack = () => {
    window.location.reload();
  }
  
  const totalTicketQty = useMemo(() => {
    if (ticketDetails?.ticketTypes) {
      return ticketDetails?.ticketTypes.reduce((acc: number, v: IFormatedTicketType) => {
        acc = acc + v.qty
        return acc;
      }, 0)
    } else {
      return 0
    }
  }, [ticketDetails])

  const c2pBanner = useMemo(()=>{
    switch(countryId){
      case COUNTRY.AUS:
        return 'c2p_reading_banner.png';
      case COUNTRY.ANG:
        return 'c2p_angelika_banner.png';  
      default:
        return ''  
    }
  },[countryId])

  const setDataLayer = () => {
    if (ticketLessFb) {
      return;
    }
    return pushDataLayer({
      isLoggedIn,
      ticketDetails,
      films,
      countryId,
      total
    }, 'purchase')
  };

  return (
    <>
      {c2pBanner?<div className="c2p_banner_wrapper">
        <img className="c2p_banner" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + c2pBanner} />
      </div>:null}
      <div className="row ticket-payment-wrapper">
        {!ticketLessFb ? <MovieDescription /> : null}
        <div
          className={`col-12 auth-wrapper ${
            ticketLessFb ? "col-md-12" : "col-md-8"
          }`}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="row no-gutters pay-method">
                <div className="col-12 col-md-12">
                  <div className="form_page pay_heading">
                    <h2>Payment Details</h2>
                    <div className="pay_method_icons">
                      <ul className="nav nav-tabs">
                      {settings?.enableClick2pay?<li
                          className={`nav-item icons ${
                            paymentType === PAYMENT_TYPE.CLICK_TO_PAY ? "active" : ""
                          }`}
                          onClick={() => toggleTab(PAYMENT_TYPE.CLICK_TO_PAY)}
                        >
                          <a
                            className="nav-link active"
                          
                          >
                            <span className="credit_card ">Click to Pay</span>
                          </a>
                        </li>: null}
                        <li
                          className={`nav-item icons ${
                            paymentType === PAYMENT_TYPE.CARD ? "active" : ""
                          }`}
                          onClick={() => toggleTab(PAYMENT_TYPE.CARD)}
                        >
                          <a
                            className="nav-link active"
                          
                          >
                            <span className="credit_card ">Credit Card</span>
                          </a>
                        </li>
                        {showWallet === PAYMENT_TYPE.GOOGLE_PAY ? (
                          <li
                            className={`nav-item icons ${
                              paymentType === PAYMENT_TYPE.GOOGLE_PAY
                                ? "active"
                                : ""
                            }`}
                            onClick={() => toggleTab(PAYMENT_TYPE.GOOGLE_PAY)}
                          >
                            <a className="nav-link">
                              <span className="wallet_pay gpay_cls">
                                <img
                                  alt="GPayIcon"
                                  className="gpayBtn"
                                  src={URLS.GOOGLE_PAY_MARK}
                                />
                              </span>
                            </a>
                          </li>
                        ) : null}
                        {showWallet === PAYMENT_TYPE.APPLE_PAY ? (
                          <li
                            className={`nav-item icons ${
                              paymentType === PAYMENT_TYPE.APPLE_PAY
                                ? "active"
                                : ""
                            }`}
                            onClick={() => toggleTab(PAYMENT_TYPE.APPLE_PAY)}
                          >
                            <a className="nav-link">
                              <span className="applePay_cls">
                                <img
                                  src={URLS.APPLE_PAY_ICON}
                                  alt="ApplePayIcon"
                                />{" "}
                                Apple Pay
                              </span>
                            </a>
                          </li>
                        ) : null}

                        <li
                          className={`nav-item icons gift_Card_tab ${
                            paymentType === PAYMENT_TYPE.GIFT_CARD ? "active" : ""
                          }`}
                          onClick={() => toggleTab(PAYMENT_TYPE.GIFT_CARD)}
                        >
                          <a className="nav-link">
                            <span className="gift_Card">Gift Card</span>
                          </a>
                        </li>

                        {isNswSupported ? (
                          <li
                            className={`nav-item icons gift_Card_tab ${
                              paymentType === PAYMENT_TYPE.NSW_VOUCHER
                                ? "active"
                                : ""
                            }`}
                            onClick={() => toggleTab(PAYMENT_TYPE.NSW_VOUCHER)}
                          >
                            <a
                              className="nav-link"
                              style={{
                                backgroundColor: "#de0707",
                                borderRadius: "0.25rem",
                              }}
                            >
                              <span
                                className="gift_voucher nsw_gift_voucher"
                                style={{ backgroundColor: "#de0707" }}
                              >
                                NSW Parents Voucher
                              </span>
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                    {paymentType === PAYMENT_TYPE.CLICK_TO_PAY ? (
                      <Click2pay userSessionId={ticketDetails?.userSessionId} amount={total} click2pay={click2payfunc} />
                    ) : null}
                    {paymentType === PAYMENT_TYPE.CARD ? (
                      <CreditCard ref={cardRef} />
                    ) : null}
                    {paymentType === PAYMENT_TYPE.GIFT_CARD ? (
                      <GiftCard ref={giftCardRef} />
                    ) : null}
                    {paymentType === PAYMENT_TYPE.NSW_VOUCHER ? (
                      <NswVoucher ref={voucherRef} />
                    ) : null}
                  </div>
                </div>
                {showContent?<><div className="col-12 mt-4">
                  <div className="custom_checkbox option_link">
                    <input
                      type="checkbox"
                      value="1"
                      checked={isTermsChecked}
                      onClick={onCheckTerms}
                    />
                    <div className="state p-primary">
                      <label className="form-label">
                        <div>
                          I have read and understand the{" "}
                          {countryId === COUNTRY.STA
                            ? "State Cinema"
                            : countryId === COUNTRY.ANG
                            ? "Angelika"
                            : "Reading"}{" "}
                          Internet Ticket Sales{" "}
                          <span className="non_atag_link_u" onClick={openTerms}>
                            Terms and conditions
                          </span>{" "}
                          {/* {informationStatus ? (
                            <span>
                              including vaccination requirements as outlined above
                            </span>
                          ) : null} */}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12  col-md-12">
                    <label className="form-label">Verification Code* </label>
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey={`${CAPTCHA_SITE_KEY}`}
                        onChange={onChangeCaptcha}
                      />
                    </div>
                  </div>
                </div></>:null}
                {errorMessage ? (
                  <div className="error_message text-center">
                    <span>{errorMessage}</span>
                  </div>
                ) : null}

                <div>
                  {isLoggedIn ? (
                    <>
                      <div className="welcome">Welcome back</div>
                      <div className="my-account text-capti font-size-16">
                        {userDetails?.firstName}
                      </div>
                    </>
                  ) : null}

                  {isSeatSwap ? (
                    <div className="user-details">
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <div className="total">REFUND TOTAL</div>
                          <div className="total-amount">
                            ${(seatSwapDetails.totalAmount / 100).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="user-details">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="total">
                          {isSeatSwap ? "NEW TOTAL" : "TOTAL ORDER"}
                        </div>
                        <div className="total-amount">${total}</div>
                        {ticketDetails?.ticketTypes?.map((v: any) =>
                          v.qty > 0 ? (
                            <div>
                              <div className="total-det">
                                {v.qty} x {v.Description} SEATS: $
                                {(v.qty * v.price).toFixed(2)}
                              </div>
                            </div>
                          ) : null
                        )}
                        {!ticketLessFb ? (
                          <div className="total-det">
                            <span>
                              BOOKING FEE : $
                              {ticketDetails?.bookingFees.toFixed(2)}
                            </span>
                          </div>
                        ) : null}
                        {orderCartData?.map((foodItems: any) => (
                          <div>
                            <div className="total-det">
                              {foodItems.quantity} x {foodItems.description} :{" "}
                              {foodItems.totalPrice}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {isSeatSwap ? <Banner /> : null}

                {showContent?<div className="col-md-12 movie_footer_btn_el">
                  <div className="movie_button_wrap">
                    <button
                      type="button"
                      className="btn gray_btn"
                      onClick={onBack}
                    >
                      BACK
                    </button>
                    {paymentType === PAYMENT_TYPE.APPLE_PAY &&
                    countryId !== COUNTRY.NZ ? (
                      <ApplePay
                        processPayment={processPayment}
                        isValid={isValid}
                        onError={onError}
                        checkValid={checkValid}
                        page={"ticketing"}
                      />
                    ) : paymentType === PAYMENT_TYPE.GOOGLE_PAY &&
                      countryId !== COUNTRY.NZ ? (
                      <GPay
                        processPayment={processPayment}
                        isValid={isValid}
                        onError={onError}
                        checkValid={checkValid}
                        page={"ticketing"}
                      />
                    ) : (paymentType === PAYMENT_TYPE.APPLE_PAY ||
                        paymentType === PAYMENT_TYPE.GOOGLE_PAY) &&
                      countryId === COUNTRY.NZ ? (
                      <StripePayment
                        processPayment={processPayment}
                        isValid={isValid}
                        onError={onError}
                        checkValid={checkValid}
                      />
                    ) : (
                      <button
                        type="button"
                        className="btn black_btn"
                        onClick={onPurchase}
                      >
                        PURCHASE
                      </button>
                    )}
                  </div>
                </div>: null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
