import { FC } from "react";
import { decode } from "html-entities";

interface IHtmlRender {
  content: any;
  sliceLength?: number;
}

const HtmlRender: FC<IHtmlRender> = ({ content, sliceLength, ...props }) => {
  return (
    <p
      {...props}
      dangerouslySetInnerHTML={{
        __html:
          sliceLength && content.length > sliceLength
            ? content.substr(0, sliceLength) + ".."
            : decode(content),
      }}
    ></p>
  );
};

export default HtmlRender;
