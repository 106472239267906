import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSliderAction, setContentModal, toggleContentModal } from '../../../../../store/application/action';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../../constants/path';
import { IMAGE_URL_CONFIG } from '../../../../../constants/url';
import './accountVerifyInfo.scss'
import { showLogin } from '../../../../../store/auth/action';
import { RootState } from '../../../../../store';
import { COUNTRY } from '../../../../../constants/app';

interface IAccountVerifyInfoProps {
    onCloseModal: () => void;
 }

const AccountVerifyInfo: FC<IAccountVerifyInfoProps> = ({onCloseModal}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        countryId,
      } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
      }));


    const rewardSummary = () => {
        onCloseModal()
        dispatch(showLogin(true));
        dispatch(openSliderAction({ open: false, type: "membershipSummary" }));
    }

    const automaticRenewal = () => {
        onCloseModal()
        dispatch(showLogin(true));
        dispatch(openSliderAction({ open: false, type: "membershipRenewalEdit" }))
    }

    return <div className="activate-verfiy-info-wrapper">
        <div className="col-12">
            <div className="content">
                <div className="header">
                    <h2>Your account has been activated.</h2>
                </div>

                <div className="body">
                    <div className='divider'></div>
                    <div className="activation_link_banner">
                        <div className="party_logo">
                            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} />
                            <p>Success!</p>
                        </div>
                        <div className="activation_content">
                            <p>You can now start using your exclusive {countryId === COUNTRY.AUS || countryId === COUNTRY.NZ?'Reading':'Angelika'} Reward benefits!</p>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className={`movie_footer_btn_el col-md-12 ml-auto mt-3`}>
                        <div className="movie_button_wrap justify-content-end">
                            <button  type="button"  onClick={automaticRenewal}
                                className="btn btn_grey" >SET UP AUTOMATIC RENEWAL</button>
                            <button
                                type="button"
                                className="btn black_btn"
                                onClick={rewardSummary}>
                                RETURN TO REWARDS SUMMARY</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default AccountVerifyInfo;