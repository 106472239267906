import React, { FC, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

interface MainProps {
  children: React.ReactNode;
}

const AULayout: FC<MainProps> = ({ children }) => {
  useEffect(()=>{
    document.body.classList.add('layout-au');
    document.body.classList.remove('layout-us');
  },[])

  return (
    <div>
      <Header />
      <Sidebar />
      <Navbar/>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default AULayout;
