import { IMovieData, IShows } from "../../models/cinema";

export interface IFilms {
    nowShowing: IShows[],
    comingSoon: IShows[],
    signatureProgram: IShows[],
    titanMovies: IMovieData[],
    quickFilters: {
        location: string | null,
        movie: string | null,
        format: string | null,
        session: string | null,
        date: string | null
    },
    searchFilter: string
}


export const ACTIONS = {
    GET_NOW_SHOWING_WATCHER: 'GET_NOW_SHOWING_WATCHER',
    GET_NOW_SHOWING_SUCCESS: 'GET_NOW_SHOWING_SUCCESS',
    GET_NOW_SHOWING_FAILURE: 'GET_NOW_SHOWING_FAILURE',

    GET_COMING_SOON_WATCHER: 'GET_COMING_SOON_WATCHER',
    GET_COMING_SOON_SUCCESS: 'GET_COMING_SOON_SUCCESS',
    GET_COMING_SOON_FAILURE: 'GET_COMING_SOON_FAILURE',

    SET_QUICK_FILTER: 'SET_QUICK_FILTER',

    GET_SIGNATURE_PROGRAM_WATCHER: 'GET_SIGNATURE_PROGRAM_WATCHER',
    GET_SIGNATURE_PROGRAM_SUCCESS: 'GET_SIGNATURE_PROGRAM_SUCCESS',
    GET_SIGNATURE_PROGRAM_FAILURE: 'GET_SIGNATURE_PROGRAM_FAILURE',

    GET_TITAN_MOVIES_WATCHER: 'GET_TITAN_MOVIES_WATCHER',
    GET_TITAN_MOVIES_SUCCESS: 'GET_TITAN_MOVIES_SUCCESS',
    GET_TITAN_MOVIES_FAILURE: 'GET_TITAN_MOVIES_FAILURE',

    SEARCH_MOVIE: 'SEARCH_MOVIE'
}