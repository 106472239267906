import React, { FC, useEffect, useState } from "react";
import SessionTimes from "../../../SessionTimes/AU";
import DateSlider from "../../../UI/DateSlider";
import HtmlRender from "../../../UI/HtmlRender";
import { sortBy } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router";

interface IComingSoonProps {
  data: any;
  isBookable: number;
}

const AdvanceTicketSection: FC<IComingSoonProps> = ({ data, isBookable }) => {

  const { countryId,currentCinema } =
  useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [dateArray, setDateArray] = useState<
    { disp_date: string; value: string }[]
  >([]);
  const dispatch = useDispatch();
  const [shows, setShows] = useState<any>([]);

  useEffect(() => {
    if (data.dateArr && data.dateArr.length > 0) {
      const currentDate = data.dateArr[0];

      const dates = data.dateArr.map((v: string) => {
        return {
          disp_date:
            v == moment().format("YYYY-MM-DD")
              ? "TODAY"
              : moment(v).format("DD/MM ddd"),
          value: v,
        };
      });
      setSelectedDate(currentDate);
      setShows(sortBy(data.shows[currentDate], (row) => row["showDateTime"]));
      setDateArray(dates);
    }
  }, [data]);

  const onClickMovieDetails = (data: any) => {
      const { slug } = data;
      navigate(`/movies/details/${currentCinema?.slug}/${slug}`);
  };

  const onChangeDate = (dateObject: { disp_date: string; value: string }) => {
    setSelectedDate(dateObject.value);
    setShows(
      sortBy(data.shows[dateObject.value], (row) => row["showDateTime"])
    );
  };


  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <div className="media">
            <div
              className="list_poster"
              onClick={() => onClickMovieDetails(data)}
            >
              <img
                className="align-self-start"
                src={data.movieData.film_image_large_size}
                alt={data.movieDataname}
                title={data.movieData.name}
              />
            </div>
            <div className="media-body">
              <h5
                className="mt-0 title"
                onClick={() => onClickMovieDetails(data)}
              >
                {data.movieData.name}
              </h5>
              {data.movieData.synopsis ? (
                <div className="description">
                  <HtmlRender
                    content={data.movieData.synopsis}
                    sliceLength={300}
                  />
                </div>
              ) : (
                <p className="description">No Description</p>
              )}
              <div className="rating_wrap">
                <div className="icon">
                  <span className="certification_icon">
                    <img
                      className="align-self-start"
                      src={data.movieData.ratingIcon}
                      alt={data.movieData.ratingName}
                      data-tooltip-id="rating-explanation" data-tooltip-html={data.movieData.ratingExplanation}
                    />
                      <Tooltip id="rating-explanation" className="tool-tip" />
                  </span>
                </div>
                <HtmlRender
                  {...{ className: "rating_title" }}
                  content={data.movieData.ratingName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mobile_scroll">
          <DateSlider 
            data={dateArray}
            onChange={onChangeDate}
            selected={selectedDate}
          />
          <SessionTimes data={shows} isBookable={isBookable}/>
        </div>
      </div>
    </div>
  );
};

export default AdvanceTicketSection;
