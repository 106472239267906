import { AnyAction } from "@reduxjs/toolkit";
import { ROUTES } from "../../constants/path";
import { IApplication, ACTIONS } from './type';
import Storage from "../../utils/storage";

const initialState: IApplication = {
    countryId: "",
    brandId: "",
    settings: {
        VistaUrl: "",
        countryId: "",
        defaultCinema: "",
        enableSeatGap: 0,
        enableTicketBooking: 1,
        enableUndermaintenance: 0,
        paymentGateway: "",
        serviceChargeFee: 0,
        glServiceChargeFee: 0,
        sohoServiceChargeFee: 0,
        underMaintenanceEmail: "",
        uniqueKey: "",
        voucher_access_token: "",
        voucherTicketing: "0",
        voucherFB: "0",
        membership: null,
        rewardsEmail: "",
        giftcard_promo_id: 0,
        giftcard_bonus_id: 0
    },
    token: "",
    cinemas: {},
    currentCinema: null,
    slides: [],
    promoAd: [],
    promoAdSuccess: false,
    openSlider: { open: false, type: '' },
    footerType: "",
    page: "",
    movieDetailBackUrl: `${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`,
    loader: false,
    reinitializeScroll: true,
    reinitializeFilter: false,
    infoPopup: {
        open: false,
        type: '',
        data: {}
    },
    error: "",
    openContentModal: false,
    contentModal: {
        type: '',
        data: null
    },
    menu: {
        header: [],
        footer: []
    },
    dateObject: null
}

export default function applicationReducer(state = initialState, action: AnyAction) {

    switch (action.type) {
        case ACTIONS.SET_LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case ACTIONS.GET_SETTINGS_SUCCESS:
            Storage.setItem('SESSION', 'token', action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                settings: action.payload,
                countryId: action.payload.countryId
            };
        case ACTIONS.OPEN_SLIDER:
            return {
                ...state,
                openSlider: action.payload
            }
        case ACTIONS.GET_CINEMAS_SUCCESS:
            return {
                ...state,
                cinemas: action.payload,
            }
        case ACTIONS.GET_CINEMAS_ERROR:
            return {
                ...state,
                cinemas: {}
            }
        case ACTIONS.SET_SELECTED_CINEMA:
            Storage.setItem("LOCAL", "currentCinema", action.payload);
            return {
                ...state,
                currentCinema: action.payload
            }
        case ACTIONS.GET_SLIDES_WATCHER:
            return {
                ...state,
                slides: [],
            }     
        case ACTIONS.GET_SLIDES_SUCCESS:
            return {
                ...state,
                slides: action.payload,
            };
        case ACTIONS.GET_SLIDES_FAILURE:
            return {
                ...state,
                slides: [],
            };

        case ACTIONS.GET_PROMO_AD_SUCCESS:
            return {
                ...state,
                promoAdSuccess: true,
                promoAd: Array.isArray(action.payload)?action.payload:[],
            };
        case ACTIONS.CLEAR_PROMO_AD:
            return {
                ...state,
                promoAdSuccess: false,
            };
        case ACTIONS.GET_PROMO_AD_FAILURE:
            return {
                ...state,
                promoAdSuccess: false,
            };
        case ACTIONS.SET_FOOTER_TYPE:
            return {
                ...state,
                footerType: action.payload
            };
        case ACTIONS.SET_MOVIE_DETAIL_BACK_URL:
            return {
                ...state,
                movieDetailBackUrl: action.payload ? action.payload : `/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`
            }
        case ACTIONS.SET_REINITIALIZE_SCROLL:
            return {
                ...state,
                reinitializeScroll: action.payload
            }
        case ACTIONS.SET_POPUP_INFO:
            return {
                ...state,
                infoPopup: action.payload
            }
        case ACTIONS.SET_BRAND_ID:
            return {
                ...state,
                brandId: action.payload
            }
        case ACTIONS.SET_REINITIALIZE_FILTER:
            return {
                ...state,
                reinitializeFilter: action.payload
            }
        case ACTIONS.TOGGLE_CONTENT_MODAL:
            return {
                ...state,
                openContentModal: action.payload,
            };

        case ACTIONS.SET_CONTENT_MODAL:
            return {
                ...state,
                contentModal: action.payload
            }
        case ACTIONS.MENU_SUCCESS:
            return {
                ...state,
                menu: action.payload
            }
        case ACTIONS.MENU_ERROR:
            return {
                ...state,
                menu: []
            }
        case ACTIONS.SET_DATE_OBJECT:
            return {
                ...state,
                dateObject: action.payload
            }    
        default:
            return state
    }

}