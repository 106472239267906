import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history'
import applicationReducer from './application/reducer';
import filmsReducer from './films/reducer';
import ticketBookingReducer from './ticketBooking/reducer';
import authReducer from './auth/reducer';
import foodBeverageReducer from './foodAndBeverage/reducer'
import giftCardReducer from './giftshop/reducer';

const RootReducer = (history: History) => combineReducers({
    applicationReducer,
    filmsReducer,
    ticketBookingReducer,
    authReducer,
    foodBeverageReducer,
    giftCardReducer,
})


export default RootReducer;