import React, { FC, useState } from "react";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import './booking.scss';
import TicketList from "../TicketList";
import { API_REQUEST_TYPE } from "../../../../constants/url";

interface IBookingProps { }

enum TAB_TYPE {
    UPCOMING = 'upcoming',
    HISTORY = 'history',
}

const Booking: FC<IBookingProps> = () => {
    const { countryId, userDetails, memberDetail } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            userDetails: state.authReducer.userDetails,
            memberDetail: state.authReducer.memberDetail,
        })
    );

    const [tab, setTab] = useState<TAB_TYPE>(TAB_TYPE.UPCOMING)

    const changeTab = (type: TAB_TYPE) => {
        setTab(type);
    }
    return (
        <div className="booking-tab-wrapper">
            <div className="tab-header">
                <div className={`tab ${tab === TAB_TYPE.UPCOMING ? 'active' : ''}`} onClick={() => changeTab(TAB_TYPE.UPCOMING)}>
                    <span>UPCOMING</span>
                </div>
                <div className={`tab ${tab === TAB_TYPE.HISTORY ? 'active' : ''}`} onClick={() => changeTab(TAB_TYPE.HISTORY)}>
                    <span>HISTORY</span>
                </div>
            </div>
            <div className="tab-body">
                <TicketList type={tab === TAB_TYPE.UPCOMING ? API_REQUEST_TYPE.UPCOMING_TICKETS : API_REQUEST_TYPE.HISTORY_TICKETS} />
            </div>
        </div>
    );
};

export default Booking;
