import { takeLatest, call, put } from 'redux-saga/effects';
import { ACTIONS, ICardDesign } from './type';
import { IAction } from '../../models/common';
import { getCardCategoriesApi, getCardDesignApi, getGiftCardApi, giftPromoApi } from '../../services/giftshop';
import { getCardCategoryError, getCardCategorySuccess, getCardDesignError, getCardDesignSuccess, getGiftCardError, getGiftCardPromoError, getGiftCardPromoSuccess, getGiftCardSuccess } from './action';
import moment from 'moment';
import { GIFT_CARD_TYPE } from '../../models/giftcard';
import { formatEGiftCard } from '../../utils/giftshop';

function* getCardDetails(action: IAction) {
    let { payload } = action
    const { data, error } = yield call(getGiftCardApi, payload)
    if (data && (data.giftcards || data.Items) ) {
        const { giftcards, Items, statusCode } = data;

        let cardDetails = [];
        if (statusCode === 200 && giftcards.length > 0) {
            cardDetails = giftcards;
        }
        else if (Items && Items.length > 0) {
            let phyRes = Items.sort((a: any, b: any) => {
                return a.sortorder - b.sortorder;
            });
            cardDetails = payload.giftType === GIFT_CARD_TYPE.E_GIFT_CARD  ?formatEGiftCard(phyRes):phyRes;
        }
        yield put(getGiftCardSuccess(cardDetails));
    } else {
        yield put(getGiftCardError(error));
    }
}
function* getCardDesigns(action: IAction) {
    const { payload } = action;
    const { data, error } = yield call(getCardDesignApi, payload);
    if (data) {
        let { Items } = data;
        if(Items.length > 0){
            Items = Items.filter((v: ICardDesign)=>!(v.archive === 1 && moment(v.expiry_date) < moment())).sort((a:ICardDesign,b:ICardDesign)=>a.sortorder - b.sortorder)
        }
        yield put(getCardDesignSuccess(Items))
    } else {
        yield put(getCardDesignError(error))
    }
}


function* getCardCategory(action: IAction) {
    let { payload } = action
    const { data, error } = yield call(getCardCategoriesApi, payload)
    if (data) {
        const { Items } = data;
        yield put(getCardCategorySuccess(Items));
    } else {
        yield put(getCardCategoryError(error));
    }
}

function* getGiftPromo(action: IAction) {
    let { payload } = action

    const { data } = yield call(giftPromoApi, payload)
    if (data) {
        yield put(getGiftCardPromoSuccess(data));
    } else {
        yield put(getGiftCardPromoError({}));
    }
}

export function* giftshopWatcher() {
    yield takeLatest(ACTIONS.GET_CARD_WATCHER, getCardDetails);
    yield takeLatest(ACTIONS.GET_CARD_DESIGN_WATCHER, getCardDesigns);
    yield takeLatest(ACTIONS.GET_CARD_CATEGORY_WATCHER, getCardCategory);
    yield takeLatest(ACTIONS.GIFT_CARD_PROMO_WATCHER, getGiftPromo);
}