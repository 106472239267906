import React, { FC, useEffect, useMemo, useState } from 'react';
import { IMAGE_URL_CONFIG } from '../../../../../../constants/url';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { COUNTRY, GENDER_LIST, GENDER_LIST_STA, GENRE_LIST, MEMBERSHIP_TYPE, MONTH_LIST } from '../../../../../../constants/app';
import "./profile.scss";
import { formatMembershipType } from '../../../../../../utils/rewards';
import { useFormik } from 'formik';
import { createValidationSchema } from '../../../../../../utils/validatorSchema';
import moment from 'moment';
import { changePasswordApi, memberValidationApi, updateMemberAttributeApi } from '../../../../../../services/auth';
import TextInput from '../../../../../../components/UI/TextInput';
import SelectDropdown from '../../../../../../components/UI/DropDown/SelectDropDown';
import { dateArray, yearArray } from '../../../../../../utils/helper';
import Calendar from "../../../../../../components/UI/Calendar";
import { sortBy } from 'lodash';
import { IDropDown } from '../../../../../../models/common';
import { openSliderAction, setContentModal, toggleContentModal } from '../../../../../../store/application/action';
import { ROUTES } from '../../../../../../constants/path';
import { logoutAction, memberValidationSuccess, setUserDetails } from '../../../../../../store/auth/action';
import { toast } from 'react-toastify';

interface ProfileProps {
    onCloseModal: any;
}

const Profile: FC<ProfileProps> = ({ onCloseModal }) => {

    const { countryId, memberDetail, userDetails, cinemas, contentModal } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
        memberDetail: state.authReducer.memberDetail,
        userDetails: state.authReducer.userDetails,
        cinemas: state.applicationReducer.cinemas,
        contentModal: state.applicationReducer.contentModal,
    }))

    const [errorMessage, setErrorMessage] = useState("");
    const [genderList, setGenderList] = useState<{ label: string; value: any }[]>(
        []
    );
    const [cinemaList, setCinemaList] = useState<IDropDown[]>([]);

    useEffect(() => {
        if ([COUNTRY.AUS, COUNTRY.NZ].includes(countryId)) {
            setGenderList(GENDER_LIST);
        } else if ([COUNTRY.STA, COUNTRY.ANG].includes(countryId)) {
            setGenderList(GENDER_LIST_STA);
        }
    }, [countryId]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (cinemas) {
            let tempCinemas: any = [];
            Object.keys(cinemas).forEach(function (key) {
                const arrList = cinemas[key];
                arrList.forEach((item: any) => {
                    tempCinemas.push({
                        label: `${item.name}, ${item.stateCode}`,
                        value: `${item.slug}`,
                    });
                });
            });
            setCinemaList(sortBy(tempCinemas, (row) => row.label));
        }
    }, [cinemas]);

    const membershipType = useMemo(() => {
        return formatMembershipType(memberDetail!)
    }, [memberDetail])

    const logo = useMemo(() => {
        if (countryId === COUNTRY.AUS) {
            return membershipType === MEMBERSHIP_TYPE.GOLD ? 'reading_rewards_boost.png' : 'reading_rewards_base.png';
        } else if (countryId === COUNTRY.NZ) {
            return membershipType === MEMBERSHIP_TYPE.GOLD ? 'reading_rewards_boost.png' : 'reading_rewards_base.png';
        } else if (countryId === COUNTRY.ANG) {
            return membershipType === MEMBERSHIP_TYPE.GOLD ? 'angelika_rewards_gold.svg' : 'angelika_rewards_club.svg';
        } else if (countryId === COUNTRY.STA) {
            return membershipType === MEMBERSHIP_TYPE.GOLD ? 'state_rewards_boost.svg' : 'state_rewards_base.svg';
        }
    }, [countryId, membershipType])

    const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
        useFormik({
            validationSchema: createValidationSchema("updateProfile"),
            enableReinitialize: true,
            initialValues: {
                firstName: userDetails?.firstName || "",
                lastName: userDetails?.lastName || "",
                gender: userDetails?.gender || "",
                dob: userDetails?.dateOfBirth?moment(userDetails?.dateOfBirth).format('DD MMMM YYYY'):"",
                email: userDetails?.email || "",
                updatedEmail: userDetails?.email || "",
                phoneNumber: userDetails?.phoneNumber
                    ? userDetails?.phoneNumber?.replace(/[^+\d]+/g, "").replace("+", "")
                    : "",
                genres:
                    typeof memberDetail?.LoyaltyMember?.PreferredGenres === "string" ||
                        memberDetail?.LoyaltyMember?.PreferredGenres === null ||
                        memberDetail?.LoyaltyMember?.PreferredGenres === undefined
                        ? []
                        : memberDetail?.LoyaltyMember?.PreferredGenres,
                preferredLocation: userDetails?.theatreId || userDetails?.theatre_id || "",
                resetPassword: false,
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
                passwordHint: "",
                weeklyNews: memberDetail?.LoyaltyMember?.SendNewsletter ? 1 : 0,
                countryId: countryId,
            },
            onSubmit: async (values) => {
                const updateProfilePayload = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    gender: values.gender,
                    dateOfBirth: moment(values.dob).format('YYYY-MM-DD'),
                    email: values.email,
                    updatedEmail: values.updatedEmail,
                    phoneNumber: values.phoneNumber,
                    preferredGenres: values.genres.join(","),
                    resetPassword: values.resetPassword,
                    oldPassword: values.currentPassword,
                    newPassword: values.newPassword,
                    countryId: countryId,
                    id: userDetails?.id,
                    weeklyNews: values.weeklyNews,
                    theatreId: values.preferredLocation,
                    memberRefId: userDetails?.vista_memberid,
                    cardNumber: userDetails?.cardNumber,
                    request: 'updateProfileNew',
                    isUpdateProfile: contentModal?.data?.isUpdateProfile
                };

                const {
                    response: {
                        data: {
                            memberDetails: memberDetailResult,
                            userDetails: userResult,
                            memberId
                        },
                    },
                } = (await updateMemberAttributeApi(updateProfilePayload)) as any;
                if (memberDetailResult.statusCode && memberDetailResult.statusCode === 200 && memberDetailResult.data) {
                    dispatch(memberValidationSuccess(memberDetailResult.data));
                }
                if (userResult.statusCode && userResult.statusCode === 200 && userResult.data && userResult.data.result && userResult.data.result.Member) {
                    const { data: { result: { Member } } } = userResult
                    dispatch(setUserDetails({
                        ...userDetails,
                        countryId: countryId,
                        email: Member.Email,
                        firstName: Member.FirstName,
                        id: memberId,
                        lastName: Member.LastName,
                        phoneNumber: values.phoneNumber,
                        cardNumber: Member.CardNumber,
                        weekly_news: values.weeklyNews,
                        dateOfBirth: values.dob,
                        gender: Member.Gender,
                        preferredGenres: Member.PreferredGenres,
                        theatreId: values.preferredLocation,
                        theatre_id: values.preferredLocation,
                    }))
                }
                if (contentModal?.data && contentModal?.data?.from === 'login') {
                    dispatch(logoutAction({}));
                    dispatch(setContentModal({ type: ROUTES.ACCOUNT_UPDATE_INFO, data: { email: values.updatedEmail } }));
                    dispatch(toggleContentModal(true));
                } else {
                    toast.success("Profile updated successfully");
                    dispatch(toggleContentModal(false));
                    dispatch(
                        openSliderAction({ open: true, type: "account" })
                    );
                }
            },
        });

    const onChangeWeeklyLetter = (event: any) => {
        const {
            target: { checked },
        } = event;
        setFieldValue("weeklyNews", checked ? 1 : 0);
    };

    const onResetPassword = (event: any) => {
        const { target: { checked } } = event;
        setFieldValue('resetPassword', checked);
    }

    return <div className='account-update-wrapper'>
        <div className="content">
            <div className="header">
                <h2>Welcome back! Update your account details with Reading Rewards.</h2>
            </div>
            <div className="body">
                <div className='membership-card'>
                    <div className={`card brand_${countryId} ${membershipType}`}>
                        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + logo} />
                    </div>
                    <p>{countryId === COUNTRY.AUS || countryId === COUNTRY.NZ ? 'Reading' : 'Angelika'} Rewards</p>
                </div>
                <div>
                    <div className="account_details_form form_page">
                        <div className="row">
                            <div className="col-12 col-md-4 ">
                                <label className="form-label">First Name*</label>
                                <div className="form-group">
                                    <TextInput
                                        value={values.firstName}
                                        field={"firstName"}
                                        handleChange={handleChange}
                                        touched={touched.firstName!}
                                        error={errors.firstName}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Last Name*</label>
                                <div className="form-group">
                                    <TextInput
                                        value={values.lastName}
                                        field={"lastName"}
                                        handleChange={handleChange}
                                        touched={touched.lastName!}
                                        error={errors.lastName}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Gender</label>
                                <div className="form-group">
                                    <SelectDropdown
                                        field={"gender"}
                                        options={genderList}
                                        setFieldValue={setFieldValue}
                                        touched={touched.gender!}
                                        error={errors.gender}
                                        value={values.gender}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Date of Birth*</label>
                                <div className="form-group">
                                    <Calendar
                                        maxDate={new Date()}
                                        field={"dob"}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        touched={touched.dob!}
                                        error={errors.dob}
                                        format={'DD MMMM YYYY'}
                                        value={values.dob}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Contact Email*</label>
                                <div className="form-group">
                                    <TextInput
                                        value={values.updatedEmail}
                                        field={"updatedEmail"}
                                        handleChange={handleChange}
                                        touched={touched.updatedEmail!}
                                        error={errors.updatedEmail}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Contact Number*</label>
                                <div className="form-group position-relative">
                                    <TextInput
                                        value={values.phoneNumber}
                                        field={"phoneNumber"}
                                        handleChange={handleChange}
                                        touched={touched.phoneNumber!}
                                        error={errors.phoneNumber}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Preferred Genres</label>
                                <div className="form-group">
                                    <SelectDropdown
                                        field={"genres"}
                                        options={GENRE_LIST}
                                        setFieldValue={setFieldValue}
                                        touched={touched.genres!}
                                        error={errors.genres}
                                        value={values.genres}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Preferred Cinema Location*</label>
                                <div className="form-group">
                                    <SelectDropdown
                                        field={"preferredLocation"}
                                        options={cinemaList}
                                        setFieldValue={setFieldValue}
                                        touched={touched.preferredLocation!}
                                        error={errors.preferredLocation}
                                        value={values.preferredLocation}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=' mb-3'>
                            <div className="custom_checkbox p-default form-group">
                                <input type='checkbox' onClick={onResetPassword}
                                    checked={values.resetPassword ? true : false} />
                                <div className="state p-primary">
                                    <label className='form-label'>
                                        <span className='f-16'>I want to reset my password.</span></label>
                                </div>
                            </div>
                        </div>
                        {values.resetPassword ? <div className="row">
                            <div className="col-12 col-md-4">
                                <label className="form-label">Current Password</label>
                                <div className="form-group">
                                    <TextInput
                                        type="password"
                                        value={values.currentPassword}
                                        field={"currentPassword"}
                                        handleChange={handleChange}
                                        touched={touched.currentPassword!}
                                        error={errors.currentPassword}
                                    />
                                </div>
                            </div>
                        </div> : null}
                        {values.resetPassword ? <div className='row'>
                            <div className="col-12 col-md-4">
                                <label className="form-label">New Password*</label>
                                <div className="form-group">
                                    <TextInput
                                        type="password"
                                        value={values.newPassword}
                                        field={"newPassword"}
                                        handleChange={handleChange}
                                        touched={touched.newPassword!}
                                        error={errors.newPassword}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">Confirm Password*</label>
                                <div className="form-group">
                                    <TextInput
                                        type="password"
                                        value={values.confirmPassword}
                                        field={"confirmPassword"}
                                        handleChange={handleChange}
                                        touched={touched.confirmPassword!}
                                        error={errors.confirmPassword}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <label className="form-label">New Password Hint*</label>
                                <div className="form-group">
                                    <TextInput
                                        type="text"
                                        value={values.passwordHint}
                                        field={"passwordHint"}
                                        handleChange={handleChange}
                                        touched={touched.passwordHint!}
                                        error={errors.passwordHint}
                                    />
                                </div>
                            </div>
                        </div> : null}
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="custom_checkbox p-default form-group">
                                    <input
                                        type="checkbox"
                                        value="1"
                                        onClick={onChangeWeeklyLetter}
                                        checked={values.weeklyNews === 1 ? true : false}
                                    />
                                    <div className="state p-primary">
                                        <label className="form-label">
                                            <span className='f-16'> {countryId === COUNTRY.ANG
                                                ? "Keep me updated with Angelika offers, special events and weekly news"
                                                : countryId === COUNTRY.STA
                                                    ? `Keep me updated with Loyalty weekly news`
                                                    : `Keep me updated with Reading Cinemas weekly news.`}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="err-message">{errorMessage}</div>
                    <div className="col-md-12 movie_footer_btn_el accounts_btn">
                        <div className="movie_button_wrap">
                            <button
                                className="btn gray_btn"
                                type="button"
                                onClick={onCloseModal}
                            >
                                CANCEL
                            </button>
                            <button
                                className="btn black_btn"
                                type="button"
                                onClick={() => handleSubmit()}
                            >
                                SAVE MY DETAILS
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default Profile;