import React, { FC } from "react";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import CommonHeader from "../CommonHeader";

interface IOrderSummaryHeaderProps {}

const OrderSummaryHeader: FC<IOrderSummaryHeaderProps> = () => {

  const { orderCartData } =
  useSelector((state: RootState) => ({
    orderCartData: state.foodBeverageReducer.orderCartData,
  }));


  return  orderCartData && orderCartData.length > 0?<div className="food_header_wrap">
      <div className="col-10 col-md-8 pd-0">
        <div>
          <div className="title">Order Summary</div>
        </div>
      </div>
    </div>:<CommonHeader/>
};

export default OrderSummaryHeader;
