import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  foodCollectApi,
  foodQrCodeApi,
} from "../../../../services/concessions";
import Modal from "../../../../components/UI/Modal";
import "./foodCollect.scss";
import { URLS } from "../../../../constants/url";
import { ROUTES } from "../../../../constants/path";

interface IFoodCollectProps {}

const FoodCollect: FC<IFoodCollectProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const { code } = useParams();
  const [foodConfirmed, setFoodConfirmed] = useState<boolean>(false);
  const [foodCollectSummary, seFoodCollectSummary] = useState<any>(null);
  const [foodList, setFoodList] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const getFoodCollect = async () => {
    const {
      data: { data, message, statusCode },
    } = await foodCollectApi({
      saleId: code,
      countryId,
    });
    if (statusCode === 200) {
      seFoodCollectSummary(data);
      setFoodList(data.concessionDetails);
      setOpenModal(true);
    } else if (statusCode === 208) {
      seFoodCollectSummary(data);
      setFoodConfirmed(true);
      setOpenModal(true);
      toast.info(message, { autoClose: 8000 });
    } else if (statusCode === 404 || statusCode === 500) {
      toast.error(message, { autoClose: 9000 });
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    } else {
      toast.error("Something went wrong", { autoClose: 8000 });
    }
  };

  useEffect(() => {
    if (code && countryId) {
      getFoodCollect();
    }
  }, [code, countryId]);

  const onClickAmHere = async () => {
    const {
      data: { statusCode, message },
    } = await foodQrCodeApi({
      userSessionId: code,
      countryId,
    });

    if (statusCode === 200) {
      setFoodConfirmed(true);
      toast.success(message, { autoClose: 8000 });
    } else if (statusCode === 400) {
      toast.error(message, { autoClose: 8000 });
    } else if (statusCode === 417) {
      toast.error(message, { autoClose: 8000 });
    } else if (statusCode === 500) {
      toast.error(message, { autoClose: 8000 });
    } else {
      toast.error("Something went wrong", { autoClose: 8000 });
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
  };

  return (
    <Modal className="iam-here-modal" visible={openModal} showCloseBtn={false}>
        <div className="modal fade show" style={{ display: "block" }}>
        <div className={`modal-dialog modal-dialog-centered modal-lg`}>
          <div className="modal-content" id="ticket-booking-modal">
            <div className="modal-body">  <div className="modal-header-image">
        <button type="button" className="close-modal" onClick={onCloseModal}>
          <img
            className="close_icon"
            src={URLS.CLOSE_ICON}
            alt="close_icon"
            title="close_icon"
          />
        </button>
      </div>
      {!foodConfirmed ? (
        <div className="row food_collect_wrap mobile_modal_pad mx-3">
          <div className="col-12 mb-5">
            <h2 className="title">Food & Beverages</h2>
            <p>
              Please select the ‘I’M AT THE CINEMA’ button below when you have
              arrived at the cinema so we can prepare your order.
            </p>
            <p>
              If you have nominated ‘Seat Service’ for any food items you don’t
              need to do anything! We will deliver your meal to your seats at
              your preferred time as nominated at checkout.
            </p>
          </div>

          {foodCollectSummary ? (
            <div className="col-12 col-md-12 col-lg-8">
              <ul>
                {foodList.map((item: any) => (
                  <li>
                    <h2 className="title">{item.Description}</h2>
                    <p className="item_list">
                      Quantity: {item.Quantity} - {item.Delivery}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {foodCollectSummary ? (
            <div className="col-12 col-md-6 col-lg-4 text-right">
              <div className="ticket_details_wrap">
                <p className="item_list">Booking Number</p>
                <h2 className="title">
                  {foodCollectSummary.ticketConfirmationNumber}
                </h2>
              </div>
            </div>
          ) : null}
          {foodCollectSummary ? (
            <div className="notes_wrap mt-5 mb-5">
              <p>
                If you wish to make changes to your order please visit the
                counter and <strong style={{ color: "red" }}>do not </strong>
                press the button below.
              </p>
            </div>
          ) : null}
          <div className="col-12">
            <button
              type="button"
              className="btn red_btn"
              onClick={onClickAmHere}
            >
              I’M AT THE CINEMA
            </button>
          </div>
        </div>
      ) : (
        <div className="row food_collect_wrap mobile_modal_pad mx-3">
          <div className="col-12 mb-5">
            <h2 className="title">Your Food Is Being Prepared</h2>
            <p>
              Please visit the counter upon arrival and quote your booking
              number to collect your order.
            </p>
          </div>

          {foodCollectSummary ? (
            <div className="col-12 col-md-6 col-lg-6">
              <div className="ticket_details_wrap">
                <p className="item_list">Booking Number</p>
                <h2 className="title">
                  {foodCollectSummary.ticketConfirmationNumber}
                </h2>
                <div className="qr_code_wrap">
                  <img
                    style={{ width: 120, height: 120 }}
                    src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${
                      foodCollectSummary.ticketConfirmationNumber
                        ? foodCollectSummary.ticketConfirmationNumber
                        : ""
                    }`}
                    alt=""
                    width="100"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}</div>
          </div>
        </div>
      </div>
    
    </Modal>
  );
};

export default FoodCollect;
