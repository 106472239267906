import React, { FC } from 'react';
import './confirmation.scss'
import { IMAGE_URL_CONFIG } from '../../../../../../constants/url';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../../../constants/path';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

interface ConfirmationProps {
    onCloseModal: any;
}

const AccountUpdateConfirmation: FC<ConfirmationProps> = ({onCloseModal}) => {

    const {
        contentModal
      } = useSelector((state: RootState) => ({
        contentModal: state.applicationReducer.contentModal,
      }));
    const navigate = useNavigate();
    const home = () => {
        onCloseModal()
        navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }

    return <div className="confirmmation-account-update-wrapper">
        <div className="col-12">
            <div className="content">
                <div className="header">
                    <h2>Thank you for updating your account details.</h2>
                </div>

                <div className="body">
                    <div className="activation_link_banner">
                        <div className="party_logo">
                            <p> <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} />A confirmation link has been sent to your email address.</p>
                        </div>
                        <div className='email'>
                            <div>Emailed To:</div>
                            <div className='value'>{contentModal?.data?.email}</div>
                        </div>
                        <div className="activation_content">
                            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'party_black.svg'}/><p>Please click on the confirmation link in your email to start using your exclusive Reading Rewards benefits.</p>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className={`movie_footer_btn_el col-md-12 ml-auto mt-3`}>
                        <div className="movie_button_wrap justify-content-end">
                            <button
                                type="button"
                                className="btn black_btn"
                                onClick={home}>
                                RETURN TO MOVIES HOMEPAGE</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default AccountUpdateConfirmation;