import React, { FC, useEffect, useState } from "react";
import { AUTH_MENU } from "../../../constants/app";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { logOutApi, memberValidationApi } from "../../../services/auth";
import { logoutAction, memberValidationSuccess, showManageCardModal, showUpdateProfileModal } from "../../../store/auth/action";
import { openSliderAction, setContentModal, toggleContentModal } from "../../../store/application/action";
import { useNavigate } from "react-router";
import { IMAGE_URL_CONFIG } from "../../../constants/url";
import Booking from "./Booking";
import Account from "./Account";
import GiftCard from "./GiftCard";
import "./accountMenu.scss";
import UpdateProfile from "../AuthMenu/UpdateProfile";
import ManageCards from "../AuthMenu/ManageCards";
import { ROUTES } from "../../../constants/path";

interface IAccountMenuProps { }

enum TAB_TYPE {
    ACCOUNT = 'account',
    BOOKING = 'booking',
    GIFTCARD = 'giftcard'
}

const AccountMenu: FC<IAccountMenuProps> = () => {
    const { countryId, userDetails, savedCards } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            userDetails: state.authReducer.userDetails,
            savedCards: state.authReducer.savedCards
        })
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tab, setTab] = useState<TAB_TYPE>(TAB_TYPE.BOOKING)

    const getMemberValidate = async () => {
        const memberResponse: any = await memberValidationApi({
            MemberRefId: userDetails?.vista_memberid,
            UserSessionId: userDetails?.cardNumber,
            cardNumber: userDetails?.cardNumber,
            countryId: countryId,
        });

        if (memberResponse.status && memberResponse.response.data && memberResponse.response.data.data) {
            dispatch(memberValidationSuccess(memberResponse.response.data.data));
        }
    }
    useEffect(() => {
        getMemberValidate();
    }, [userDetails])


    const logOut = async () => {
        const { status } = await logOutApi();
        if (status === 200) {
            dispatch(logoutAction({}));
            dispatch(openSliderAction({ open: false, type: "" }));
        }
    };

    const changeTab = (type: TAB_TYPE) => {
        setTab(type);
    }

    const onClickManageCards = () => {
        dispatch(showManageCardModal(true));
    };

    const onClickEdit = () => {
        dispatch(setContentModal({ type: ROUTES.ACCOUNT_UPDATE}));
        dispatch(toggleContentModal(true));
    };

    const closeSlider = () => {
        dispatch(openSliderAction({ open: false, type: "" }));
    };
    return (<>
        <section className="account-side-nav">

            {AUTH_MENU.userDetails.enable.includes(countryId) ? (

                <div className="user-details">
                    <div className="user-name">
                        <span>
                            Hello,{" "}
                            {userDetails?.firstName ? userDetails.firstName : ""}
                        </span>{" "}
                        <span>
                            {userDetails?.lastName ? userDetails.lastName : ""}.
                        </span>
                        <div className="close-btn"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'close-icon.svg'} onClick={closeSlider} /></div>
                    </div>
                    <div className="description">This is where you can manage your ticket bookings, food and beverage orders, membership account and gift cards.</div>
                </div>

            ) : null}

            <div className="tab-wrapper">
                <div className="tab-header">
                    <div className={`tab ${tab === TAB_TYPE.BOOKING ? 'active' : ''}`} onClick={() => changeTab(TAB_TYPE.BOOKING)}>
                        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'tickets_white.svg'} />
                        <span>MY BOOKINGS</span>
                    </div>
                    <div className={`tab ${tab === TAB_TYPE.ACCOUNT ? 'active' : ''}`} onClick={() => changeTab(TAB_TYPE.ACCOUNT)}>
                        <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}user_icon.svg`} />
                        <span>MY ACCOUNT</span>
                    </div>
                    <div className={`tab ${tab === TAB_TYPE.GIFTCARD ? 'active' : ''}`} onClick={() => changeTab(TAB_TYPE.GIFTCARD)}>
                        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'gift.svg'} />
                        <span>MY GIFT CARDS</span>
                    </div>
                </div>
                <div className="tab-body">
                    {tab === TAB_TYPE.BOOKING ?
                        <Booking /> :
                        tab === TAB_TYPE.ACCOUNT ?
                            <Account /> :
                            tab === TAB_TYPE.GIFTCARD ?
                                <GiftCard /> :
                                null}
                </div>
            </div>
            <div className="my-account">
                <h4 className="title">My Account</h4>
                <div className="profile">
                    <img className="user-icon" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'user_icon.svg'} />
                    <div className="name">
                        <div className="label">{userDetails?.firstName ? userDetails.firstName : ""}{" "}{userDetails?.lastName ? userDetails.lastName : ""}</div>
                        <div className="label">{userDetails?.phoneNumber}</div>
                    </div>
                    <div>
                        <img className="edit-icon" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'edit_icon.svg'} onClick={onClickEdit} />
                    </div>
                </div>
                <div className="divider"></div>
                <div className="save-cards">
                    <h4>Saved credit cards ({savedCards.length})</h4>
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'edit_icon.svg'} onClick={onClickManageCards} />
                </div>
                <div className="divider"></div>
                <div className="logout-wrapper">
                    <div className="logout-btn" onClick={logOut}>
                        <span>Logout</span>
                        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'logout.svg'} />
                    </div>
                </div>
            </div>
        </section>
        <UpdateProfile />
        <ManageCards />
    </>
    );
};

export default AccountMenu;
