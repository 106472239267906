import React, { FC, useEffect, useState } from "react";
import "./calendar.scss";
import Calendar from "react-calendar";
import moment from "moment";

const CalendarPicker: FC<any> = ({
  field,
  touched,
  error,
  onValueChange,
  setFieldValue,
  value,
  handleChange,
  format,
  ...props
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState<any>("");
  const onCalendarChange = (event: any) => {
    if (handleChange) {
      handleChange(field);
    }
    setShowCalendar(false);
    const d = moment(event).format(format ? format : "DD-MM-YYYY");
    setDate(d);
    if (setFieldValue) {
      setFieldValue(field, d);
    }
  };

  useEffect(()=>{
    if(value){
      setDate( moment(value).format(format ? format : "DD-MM-YYYY"));
    }
  },[value])

  return (
    <>
      <div className="position-relative custom-calendar" style={{ zIndex: 3}}>
        <input
          type="text"
          readOnly={true}
          className={`form-group form-group-date-field mb-1 ${
            touched && error ? "is-invalid" : ""
          }`}
          onKeyUp={({ target: { value } }: any) =>
            onValueChange && onValueChange(value)
          }
          value={date}
          onFocus={() => setShowCalendar(true)}
        />
        {touched && error ? (
          <div className="error_message">
            <span>{error}</span>
          </div>
        ) : null}

        <Calendar
          calendarType="Hebrew"
          className={showCalendar ? "" : "d-none"}
          onChange={onCalendarChange}
          value={value}
          {...props}
        />
      </div>
      <div
        className={`${showCalendar ? "calendar-overlay" : ""}`}
        onClick={() => setShowCalendar(false)}
      ></div>
    </>
  );
};

export default CalendarPicker;
