const Storage = {
   getItem: (storage: string, key: string) => {
      const item = (storage === 'SESSION' ? sessionStorage : localStorage).getItem(key);
      return item && item != "undefined" ? JSON.parse(item) : {};
   },
   setItem: (storage: string, key: string, data: any) => {
      return (storage === 'SESSION' ? sessionStorage : localStorage).setItem(key, JSON.stringify(data));
   },
   removeItem: (storage: string, key: string) => {
      return (storage === 'SESSION' ? sessionStorage : localStorage).removeItem(key)
   },
   clear: (storage: string,) => {
      return (storage === 'SESSION' ? sessionStorage : localStorage).clear();
   }
}

export default Storage