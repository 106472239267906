import { ACTIONS, FoodBeverageItems } from './type';

export function getFoodItemsWatcher(payload: any) {
    return { type: ACTIONS.GET_FOOD_BEVERAGE_WATCHER, payload: payload }
}

export function getFoodItemsSuccess(payload: any) {
    return { type: ACTIONS.GET_FOOD_BEVERAGE_SUCCESS, payload: payload }
}

export function setConcessionDiscount(payload: any) {
    return { type: ACTIONS.SET_CONCESSION_DISCOUNT, payload: payload }
}

export function getFoodItemsError(error: any) {
    return { type: ACTIONS.GET_FOOD_BEVERAGE_FAILURE, payload: error }
}

export function postFoodOrderWatcher(payload: any, resolve: any, reject: any) {
    return { type: ACTIONS.FOOD_ORDER_POST_WATCHER, payload: payload, resolve, reject }
}

export function postFoodOrderSuccess(payload: any) {
    return { type: ACTIONS.FOOD_ORDER_POST_SUCCESS, payload: payload }
}

export function postFoodOrderError(error: any) {
    return { type: ACTIONS.FOOD_ORDER_POST_FAILURE, payload: error }
}

export function setUpModalHeaderText(text: string) {
    return { type: ACTIONS.SETUP_MODAL_HEADER_TEXT, payload: text }
}

export function clearFoodItemsAction() {
    return { type: ACTIONS.CLEAR_FOOD_ITEMS }
}

export function setTicketLessFb(payload: boolean) {
    return { type: ACTIONS.SET_TICKETLESS_FB, payload: payload }
}

export function addFoodItemsCart(payload: FoodBeverageItems) {
    return { type: ACTIONS.ADD_ORDER_FOOD_ITEMS, payload: payload }
}

export function updateFoodItemsCart(payload?: any) {
    return { type: ACTIONS.UPDATE_ORDER_FOOD_ITEMS, payload: payload }
}

export function setAlcoholDob(payload: string) {
    return { type: ACTIONS.SET_ALCOHOL_DOB, payload: payload }
}

export function getLicense(payload: { countryId: string, cinemaId: string }) {
    return { type: ACTIONS.GET_LICIENSE, payload: payload }
}

export function getLicenseSuccess(payload: any){
    return {type: ACTIONS.GET_LICENSE_SUCCESS, payload: payload}
}

export function getLicenseError(payload: any){
    return {type: ACTIONS.GET_LICENSE_ERROR, payload: payload}
}

export function getBookedTickets(payload: { 
    countryId: string, 
    phoneNumber: string,
    requestType: string
}) {
    return { type: ACTIONS.GET_BOOKED_TICKETS, payload: payload }
}

export function getBookedTicketsSuccess(payload: any){
    return {type: ACTIONS.GET_BOOKED_TICKETS_SUCCESS, payload: payload}
}

export function getBookedTicketsError(payload: any){
    return {type: ACTIONS.GET_BOOKED_TICKETS_ERROR, payload: payload}
}

export function addSelectedTickets(payload: any) {
    return { type: ACTIONS.PRE_PURCHASED_TICKET, payload: payload }
}
