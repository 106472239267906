import moment from "moment"
import { COUNTRY, CURRENCY } from "../constants/app"

const formatPayload = (data: any, type: string) => {
    switch (type) {
        case 'view_item':
            return {
                event: "autoEvent",
                event_category: "Film Interactions",
                event_action: "Film Page View",
                event_label: "view_item",
                logged_user: data.isLoggedIn,
                film_name: data?.films?.movieName,
                cms_id: data.films?.movieGroupId,
                genre: `[]`,
                venue_name: data.films?.cinemaName,
                ecommerce: {
                    items: [{
                        item_name: data?.films?.movieName,
                        item_id: data?.films?.movieId,
                        item_category: data?.films?.cinemaName,
                    }]
                }
            }
        case 'select_item':
            return {
                event: "autoEvent",
                event_category: "Showtime Interactions",
                event_action: "Showtime Click",
                event_label: "select_item",
                logged_user: data.isLoggedIn,
                film_name: data?.films?.movieName,
                cms_id: data.films?.movieGroupId,
                genre: `[]`,
                venue_name: data.films?.cinemaName,
                show_date: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                show_time: moment.parseZone(data?.films?.showDateTime).format("HH:mm"),
                ecommerce: {
                    items: [{
                        item_name: data?.films?.movieName,
                        item_id: data?.films?.movieId,
                        item_category: data?.films?.cinemaName,
                        item_category2: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                        item_variant: "Ticket",
                    }]
                }
            }
        case 'add_to_cart':
            return {
                event: "autoEvent",
                event_category: "Ticket Checkout",
                event_action: "Add to Cart",
                event_label: "add_to_cart",
                logged_user: data.isLoggedIn,
                film_name: data?.films?.movieName,
                cms_id: data.films?.movieGroupId,
                genre: `[]`,
                venue_name: data.films?.cinemaName,
                show_date: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                show_time: moment.parseZone(data?.films?.showDateTime).format("HH:mm"),
                ecommerce: {
                    items: [{
                        item_name: data?.films?.movieName,
                        item_id: data?.films?.movieId,
                        item_category: data?.films?.cinemaName,
                        item_category2: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                        item_variant: "Ticket",
                    }]
                }
            }
        case 'begin_checkout':
            return {
                event: "autoEvent",
                event_category: "Ticket Checkout",
                event_action: "Start Checkout",
                event_label: "begin_checkout",
                logged_user: data.isLoggedIn,
                film_name: data?.films?.movieName,
                cms_id: data.films?.movieGroupId,
                genre: `[]`,
                venue_name: data.films?.cinemaName,
                show_date: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                show_time: moment.parseZone(data?.films?.showDateTime).format("HH:mm"),
                ecommerce: {
                    items: [{
                        item_name: data?.films?.movieName,
                        item_id: data?.films?.movieId,
                        item_category: data?.films?.cinemaName,
                        item_category2: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                        item_variant: "Ticket",
                    }]
                }
            }
        case 'purchase':
            return {
                event: "autoEvent",
                event_category: "Ticket Checkout",
                event_action: "Ticket Purchase",
                event_label: "purchase",
                logged_user: data.isLoggedIn,
                film_name: data?.films?.movieName,
                cms_id: data.films?.movieGroupId,
                genre: `[]`,
                venue_name: data.films?.cinemaName,
                show_date: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                show_time: moment.parseZone(data?.films?.showDateTime).format("HH:mm"),
                ecommerce: {
                    transaction_id: data?.ticketDetails?.userSessionId,
                    value: data?.total,
                    currency: CURRENCY[data.countryId as COUNTRY],
                    items: data?.ticketDetails?.ticketTypes?.map((v: any) =>
                    ({
                        item_name: data?.films?.movieName,
                        item_id: data?.films?.movieId,
                        item_category: data?.films?.cinemaName,
                        item_category2: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                        item_variant: "Ticket",
                        show_date: moment.parseZone(data?.films?.showDateTime).format("DD-MM-YYYY"),
                        price: (v.total).toFixed(2),
                        quantity: v.qty,
                    }))
                }
            }
        default:
            return null;
    }
}

export const pushDataLayer = (data: any, type: string) => {
    try {
        const payload = formatPayload(data, type);
        if (window && payload) {
            const dataLayer = (window as any).dataLayer
            if (dataLayer) {
                dataLayer.push(payload);
                console.log('datalayer', payload)
            }
        }
        return;
    } catch (err) {
        return;
    }
}