import React,{ FC } from "react";

type PublicRouteProps = {
  layout: FC<any>;
  component: FC<any>;
  exact?: boolean;
  children?: React.ReactNode;
};

const PublicRoute: FC<PublicRouteProps> = ({
  component: Component,
  layout: Layout,
  children,
  ...rest
}) => {
  return (
    <Layout>
      <Component>{children}</Component>
    </Layout>
  );
};

export default PublicRoute;
