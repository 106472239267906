import React,{ FC, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import PrePurchaseList from "../PrePurchaseList";
import './prePurchase.scss';
import { setModal } from "../../../../../../../store/ticketBooking/action";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../../../models/tickets";
import { TICKET_FLOW } from "../../../../../../../constants/ticket";
import { setUpModalHeaderText } from "../../../../../../../store/foodAndBeverage/action";
import { BRANDS } from "../../../../../../../constants/app";

interface IPrePurchaseProps {}

const PrePurchase: FC<IPrePurchaseProps> = () => {
  const {
    modalType, 
    countryId, 
    modalData, 
    bookedTickets, 
    prevModal, 
    nextModal,
    prePurchasedTicket,
    brandId
  } = useSelector((state: RootState)=>({
      modalType: state.ticketBookingReducer.modal.type,
      countryId: state.applicationReducer.countryId,
      modalData: state.ticketBookingReducer.modal.data,
      bookedTickets: state.foodBeverageReducer.bookedTickets,
      prevModal: state.ticketBookingReducer.modal.prevModal,
      nextModal: state.ticketBookingReducer.modal.nextModal,
      prePurchasedTicket: state.foodBeverageReducer.prePurchasedTicket,
      brandId: state.applicationReducer.brandId
  }))

  const dispatch = useDispatch();
  const [concession, setConcession] = useState<any>({});

  useEffect(() => {
    if (prePurchasedTicket) {
      setConcession(prePurchasedTicket.concessions);
    }
  }, [prePurchasedTicket]);

  const addConcession = () => {

    dispatch(
      setUpModalHeaderText(
        brandId === BRANDS.US ? "Eat & Drink" : "Food & Drink"
      )
    );
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
          MODAL_TYPE.FOOD_AND_BEVERAGE
        ],
        type: TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE,
        data: null,
      })
    );
  }

  const back = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const prev: MODAL_TYPE = prevModal;
    const modal = TICKET_FLOW[ticketType][prev];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  }

  return (
    <div className="mx-3 mx-lg-2 pre-purchase-order">
      <div className="col-12 col-md-12 p-0">
          <a className="chevron_left_black" onClick={back}>Back</a>
      </div>
      {
        concession && concession.length > 0 ?
        <>
          <div className="title">Pre-purchased Orders</div>
          <div className="my-3">          
            <PrePurchaseList concessions={concession}/>
          </div>
        </>
        : null
      }            
      {
        concession && concession.length === 0 ?
        <div className="text-center my-5">Food not yet added</div>
        : null
      }      
      <div className="d-flex justify-content-center mt-5">
        <button className="btn black_btn d-flex align-items-center" onClick={addConcession}>
          <span className="btn_plus mr-1"></span><span>ADD FOOD & BEVERAGE</span>
        </button>
      </div>
  </div>
  );
};

export default PrePurchase;
