import React,{ FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideMenu from "../../../components/Menu/SideMenu";
import { RootState } from "../../../store";
import { openSliderAction } from "../../../store/application/action";
import "./sidebar.scss";
import AuthMenu from "../../../components/Menu/AuthMenu";
import AccountMenu from "../../../components/Menu/AccountMenu";

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
  const { openSlider } = useSelector((state: RootState) => ({
    openSlider: state.applicationReducer.openSlider,
  }));

  const dispatch = useDispatch();

  const closeSlider = () => {
    dispatch(openSliderAction({ open: false, type: "" }));
  };

  return (
    <>
      <div
        id="mySidenav"
        className={`sidenav ${openSlider.open ? "opened" : "closed"}`}
      >
        <div className={`sidenavshow ${openSlider.open ? "accountshow" : ""}`}>
          <a className="closebtn close_icon"></a>
          {openSlider.type === 'account'?<AccountMenu/>:<SideMenu/>}
        </div>
      </div>
      <div
        id="backDrop"
        onClick={closeSlider}
        className={`backdrop ${openSlider.open ? "opened" : "closed"}`}
      ></div>
    </>
  );
};

export default Sidebar;
