import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import CompetitionForm from "../../../../components/Menu/SideMenu/Competition/competitionForm";
import CompetionQuestion from "../../../../components/Menu/SideMenu/Competition/competitionQuestion";

interface ICompetitionModalProps {}

const CompetitionModal: FC<ICompetitionModalProps> = () => {
  const { openContentModal, contentModal, currentCinema } = useSelector(
    (state: RootState) => ({
      openContentModal: state.applicationReducer.openContentModal,
      contentModal: state.applicationReducer.contentModal,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );
  return (
    <div className="privacy-policy">
      <div className="modal-header">
        <div className="title title_append">Competition Submission</div>
      </div>
      <div className="modal-body">
        <div className="my-4"><CompetionQuestion data={contentModal.data} page="modalPage" /></div>
        <div className="mx-3"><CompetitionForm data={{...contentModal.data}} /></div>
      </div>
    </div>
  );
};

export default CompetitionModal;
