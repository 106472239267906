import React, { FC, useEffect, useState } from "react";
import Barcode from "react-barcode";
import "./refund.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useParams } from "react-router";
import {
  checkRefundStatusApi,
  refundPayment,
} from "../../../../services/payment";
import { toast } from "react-toastify";
import { ICheckRefund } from "../../../../models/payment";
import { getPaymentMethod } from "../../../../services/payment";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import { setModal } from "../../../../store/ticketBooking/action";

interface IRefundTicketProps {
  onCloseModal: () => void;
}

const Refund: FC<IRefundTicketProps> = ({ onCloseModal }) => {
  const { countryId, sharedUserSessionId } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      sharedUserSessionId: state.ticketBookingReducer.modal.data,
    })
  );

  const dispatch = useDispatch();
  const [refundData, setRefundData] = useState<ICheckRefund | null>(null);

  const checkRefund = async (sharedUserSessionId: string) => {
    const {
      data: { data },
    } = await checkRefundStatusApi({
      saleId: sharedUserSessionId,
      countryId: countryId,
    });
    if (data && data.status) {
      setRefundData(data);
    } else {
      toast.error(data.message);
      onCloseModal();
    }
  };

  useEffect(() => {
    if (countryId && sharedUserSessionId) {
      checkRefund(sharedUserSessionId);
    }
  }, [countryId, sharedUserSessionId]);

  const processRefund = async () => {
    const refundDetails: any = {
      amount: (Number(refundData?.totalAmount) - Number(refundData?.bookingFee)) * 100,
      countryId: countryId,
      txnType: 4,
      requestType: "refundPayment",
      paymentMethod: getPaymentMethod(countryId),
      txnID: refundData?.txnId,
      transactionReference: refundData?.transactionRefNo,
      saleId: sharedUserSessionId,
      refundType: "individual",
    };
    const {
      status,
      data
    } = (await refundPayment(refundDetails)) as any;
    if (status && data && data.data && data.data.validation_status === 'success' && data.data.transaction_status === 'approved') {
      const modal =
        TICKET_FLOW[TICKET_FLOW_TYPES.REFUND][MODAL_TYPE.REFUND_CONFIRMATION];
      dispatch(
        setModal({
          ...modal,
          type: MODAL_TYPE.REFUND,
          data: refundData?.email
        })
      );
    } else {
      toast.error(
        data && data.data && data.data.message ? data.data.message : "Error occured please try later"
      );
      onCloseModal();
    }
  };

  return (
    <div className="refund-wrapper">
      <div className="col-12 px-2">
        <div className="form_page">
          <div className="ticket-confirm custom_scroll_content vscroll_600">
            <div className="ticket_detail_wrap">
              <div className="ticket_detail">
                <div className="ticket_number">
                  <p className="label">ORDER NUMBER</p>
                  <p className="number">{refundData?.ticketConfirmationNumber}</p>
                </div>
              </div>

              <div className="bar_code">
                <Barcode
                  width={2}
                  height={80}
                  displayValue={false}
                  value={refundData?.ticketConfirmationNumber!}
                />
              </div>
            </div>

            <table className="movie_details">
              <tbody>
                <tr>
                  <th scope="row">Total Paid</th>
                  <td> ${Number(refundData?.totalAmount).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Service Fees (non-refundable)</th>
                  <td> ${Number(refundData?.bookingFee).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Total Refund Amount</th>
                  <td style={{ color: "#D0021B" }}>
                    $
                    {(
                      Number(refundData?.totalAmount) -
                      Number(refundData?.bookingFee)
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="notes">
              Your total will be refunded to the original form of payment.{" "}
              <br />
              Please allow up to 72 business hours for the funds to reflect in
              your account.{" "}
            </div>
          </div>
          <div className={`movie_footer_btn_el col-md-12 ml-auto`}>
            <div className="movie_button_wrap justify-content-end">
              <button
                type="button"
                className="btn gray_btn"
                onClick={onCloseModal}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn black_btn"
                onClick={processRefund}
              >
                PROCESS REFUND
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refund;
