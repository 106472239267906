import { divide } from "lodash";
import { FC, useEffect, useState } from "react";
import "./eatAndDrink.scss";
import {
  IEatAndDrinkDetails,
  IEatDeliveryOption,
} from "../../../models/foodbeverage";
import { eatAndDrinkApi } from "../../../services/films";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import { url } from "inspector";
import moment from "moment";

interface IEatAndDrinkDetailsProps {}

const EatAndDrinkDetails: FC<IEatAndDrinkDetailsProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const [eatDrink, setEatDrink] = useState<IEatAndDrinkDetails[]>([]);
  const [openingHours, setOpeningHours] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  const currentDate = new Date();
  const currentDay = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
  });

  const getData = async () => {
    const { data } = await eatAndDrinkApi({
      cinemaId: currentCinema.slug,
      countryId: countryId,
    });
    if (Array.isArray(data)) {
      setEatDrink(data[0]);
      setOpeningHours(data[0].OpeningHours);
      setDeliveryOptions(data[0].DeliveryOptions);
      setPhoneNumber(data[0].PhoneOrderNumber);
    }
  };

  useEffect(() => {
    getData();
  }, [countryId, currentCinema]);

  return (
    <div className="eatAndDrink">
      <hr />
      <div className="middle-section d-flex">
        <div className="opening_hours">
          <h6>Opening Hours</h6>
          {openingHours.map((ele: any) => (
            <div className="d-flex hours ">
              <p
                className={
                  currentDay.toLowerCase() === ele.Day.toLowerCase()
                    ? "border-left highlight day"
                    : "day"
                }
              >
                {ele.Day}
              </p>
              {ele.OpenStatus === "1" ? (
                <p
                  className={
                    currentDay.toLowerCase() === ele.Day.toLowerCase()
                      ? "highlight time"
                      : "time"
                  }
                >
                  {moment(ele.OpenHour, "hh:mm").format("h:mm a")} -{" "}
                  {moment(ele.CloseHour, "hh:mm").format("h:mm a")}
                </p>
              ) : <p  className={
                currentDay.toLowerCase() === ele.Day.toLowerCase()
                  ? "highlight time"
                  : "time"
              }>N/A</p>}
            </div>
          ))}
        </div>
        <div className="delivery-options">
          <div>
            <h6>Delivery Options</h6>
            <div className="button-wrapper">
              {deliveryOptions.map((v: IEatDeliveryOption) => (
                <div className="my-2">
                  <a
                    href={v.ButtonLink}
                    target="_blank"
                    className="eat-button"
                    style={{
                      backgroundImage: `url(${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.ButtonImage}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT})`,
                    }}
                  >
                    &nbsp;
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="phone-order">
            <h6>Phone Order</h6>
            <div className="phone">
              <img src="/images/phone.svg" />
              <h6>{phoneNumber}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EatAndDrinkDetails;
