import { FC } from "react";

import HtmlRender from "../../../../UI/HtmlRender";
import { URLS } from "../../../../../constants/url";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { showLogin } from "../../../../../store/auth/action";
import { openSliderAction, setContentModal, toggleContentModal } from "../../../../../store/application/action";
import "./competitionQuestion.scss";
import { ROUTES } from "../../../../../constants/path";

interface ICompentionProps {
  data: any;
  page?: string;
}

const CompetionQuestion: FC<ICompentionProps> = ({ data , page}) => {
  const { isLoggedIn } = useSelector((state: RootState) => ({
    isLoggedIn: state.authReducer.isLoggedIn,
  }));

  const dispatch = useDispatch();

  const errorHandler = (event: any) => {
    event.target.src = URLS.ERROR_IMAGE;
  };

  const onClickCompetitionDetails = (data: any) => {
    dispatch(setContentModal({ type: ROUTES.COMPETITIONS, data }));
    dispatch(toggleContentModal(true));
  };

  const onOpenModal = () => {
    if(!isLoggedIn){
      dispatch(showLogin(true));
    }else{
      dispatch(setContentModal({ type: ROUTES.COMPETITIONS, data }));
      dispatch(toggleContentModal(true));
    }
  };

  const Question = () => {
    return (
      <>
        <h3>QUESTION</h3>
        <div className="question_content_wrap">
          <div className="price_img">
            <img
              src={data.prizeImgId}
              alt={data.title}
              title={data.title}
              onError={errorHandler}
            />
          </div>
          <div className="question_content">
            <div>
              <HtmlRender content={data.questions} />
            </div>
            {data.termsConditionUrl ? (
              <p>
                Click here to view{" "}
                <a
                  className="text_link"
                  href={data.termsConditionUrl}
                  target="blank"
                >
                  <u>Terms and Conditions</u>
                </a>
              </p>
            ) : null}
            {(data.openToPublic || isLoggedIn == true )&& page !== 'modalPage'? (
              <button
                type="button"
                className="btn white_btn_sml popup_content_hide"
                onClick={() => onClickCompetitionDetails(data)}
              >
                ENTER
              </button>
            ) : null}
            {data.openToPublic == false && !isLoggedIn && page !== 'modalPage'? (
              <button
                type="button"
                className="btn white_btn_sml popup_content_hide"
                onClick={onOpenModal}
              >
                LOGIN
              </button>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={`container competition-child ${page}`}>
      <div className="row">
        <div className="col-12">
          <label
            className="title mobile_title"
            onClick={() => onClickCompetitionDetails(data)}
          >
            {data.title}
          </label>
        </div>
        <div className="col-md-12 col-12 mobile_view">
          <div className="media">
            <div
              className="list_poster"
              onClick={() => onClickCompetitionDetails(data)}
            >
              <img
                className="align-self-start"
                src={data.competition_image}
                alt={data.title}
                title="competitionData.title"
                onError={errorHandler}
              />
            </div>
            <div className="media-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="title_wrap">
                    <p
                      className="mt-0 title mobile_hide"
                      onClick={() => onClickCompetitionDetails(data)}
                    >
                      {data.title}
                    </p>
                  </div>
                  <div className="content_hide description_wrap">
                    <HtmlRender content={data.description} />
                  </div>
                </div>
                <div className="col-md-12 col-12 popup_content_hide description_wrap mobile_hide">
                  <HtmlRender content={data.description} />
                </div>
              </div>
            </div>
          </div>
          <div className="question_section_wrap">
            <div className="description_wrap description_box mobile_hide col-md-12">
              <Question />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="col-md-12 popup_content_hide description_wrap mobile_show">
            <HtmlRender content={data.description} />
          </div>
          <div className=" mobile_show {{modalSettings }}">
            <div className="description_wrap description_box">
              <Question />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetionQuestion;
