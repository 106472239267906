import { IFoodTabConfig } from "../models/foodbeverage";
import { COUNTRY } from "./app";

export enum FOOD_COMPONENT_TYPE {
  FOOD_ITEMS = "Renderfooditems",
  CUSTOMISE = "customise",
  ADDTOORDER = "Add To Order",
}

export const FOOD_TAB_CONFIG: IFoodTabConfig = {
  [COUNTRY.AUS]: [
    { key: 'nonAlcoholicFlag', item: 'alcohol' },
    { key: 'hideHotFoodFlag', item: 'hot_food' },
  ],
  [COUNTRY.NZ]: [
    { key: 'nonAlcoholicFlag', item: 'alcohol' },
    { key: 'hideHotFoodFlag', item: 'hot_food' },
  ],
  [COUNTRY.STA]: [
    { key: 'nonAlcoholicFlag', item: 'alcohol' },
    { key: 'hideHotFoodFlag', item: 'combos' }],
  [COUNTRY.ANG]: [
    { key: 'nonAlcoholicFlag', item: 'alcohol' },
    { key: 'hideHotFoodFlag', item: 'combos' }
  ],
  [COUNTRY.US]: [
    { key: 'nonAlcoholicFlag', item: 'alcohol' },
    { key: 'hideHotFoodFlag', item: 'hot_food' },
  ],
}