import React,{ FC, useRef } from "react";
import Select from "react-select";

import "./location.scss";
import { BRANDS } from "../../../../constants/app";

interface ILocationDropDownProps{
  value?:  any,
  options: Array<{ label: string; value: any }>;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: string) => void;
}


const LocationDropDown: FC<ILocationDropDownProps| any> = ({
  value,
  options,
  placeholder,
  disabled = false,
  onChange,
  ...props
}) => {

  const eleRef = useRef<any>();

  const onSelect = (data:any) => {
    onChange && onChange(data.value);
    eleRef.current?.setValue(data)
  };

  const CustomOption: FC<any> = ({ data }) => {
    return (
      <div
        onClick={() => onSelect(data)}
        className={`${data.isParent ? "parent-label" : (value && value.value === data.value?"dropdown-item favourite_add":"dropdown-item")}`}
      >
        {props.brandId === BRANDS.US? <img className="mx-1" src="/images/location.svg" />: null}<span>{data.label}</span>
      </div>
    );
  };

  return (
    <Select
      ref={eleRef}
      value={value}
      isSearchable={false}
      placeholder={placeholder}
      className="location-dropdown"
      classNamePrefix="location"
      options={options}
      // menuIsOpen={true}
      components={{
        Option: CustomOption,
      }}
      {...props}
    />
  );
};

export default LocationDropDown;
