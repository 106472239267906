import React, { FC, useEffect, useRef, useState } from "react";
import Slick from "react-slick";
import "./slider.scss";
import HtmlRender from "../HtmlRender";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";


interface ISliderProps {
  data?: Array<any>;
  arrows?: boolean;
  fade?: boolean;
  isDotsNeeded?: boolean;
  className?: string;
}

const Slider: FC<ISliderProps> = ({
  data,
  arrows = true,
  fade = true,
  isDotsNeeded = false,
  ...props
}) => {
  const slideRef = useRef<any>();
  let settings = {
    className: "",
    centerMode: true,
    centerPadding: "0px",
    dots: isDotsNeeded,
    lazyload: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplayspeed: 500,
    cssEase: "linear",
    fade: true,
    slidesToScroll: 1,
    initialSlide: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    // adaptiveHeight: true,
    arrows: arrows,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

  const { currentCinema } = useSelector((state:RootState)=>({
    currentCinema: state.applicationReducer.currentCinema
  }))

  const navigate = useNavigate();
  useEffect(() => {
    if (slideRef) {
      slideRef.current?.slickGoTo(0);
    }
  }, [data]);

  const openLink = (url: string)=>{
    if(!url){
      return;
    }
    if(url.includes('https://')){
      window.open(url, "_self")
    }else{
      navigate(url)
    }
  }

  return (
    <div className="banner_wrap">
      <Slick {...settings} {...props} ref={slideRef}>
        {data?.map((slide, index) => {
          return <div
            key={index}
            className={`banner-image ${slide.textPosition}`}
          >
            {slide.showBannerInfo === 0 ? (
              <a key={index} onClick={()=>openLink(slide.bannerlink)}
              >
                {slide.imageUrl ? (
                  <img
                    className="d-none d-md-block"
                    src={slide.imageUrl}
                    alt={slide.title}
                    title={slide.title}
                  />
                ) : null}
                {slide.mobileImageUrl ? (
                  <img
                    className="d-block d-md-none"
                    src={slide.mobileImageUrl}
                    alt={slide.title}
                    title={slide.title}
                  />
                ) : null}
              </a>
            ) : <>
            {slide.imageUrl ? (
              <img
                className="d-none d-md-block"
                src={slide.imageUrl}
                alt={slide.title}
                title={slide.title}
              />
            ) : null}
            {slide.mobileImageUrl ? (
              <img
                className="d-block d-md-none"
                src={slide.mobileImageUrl}
                alt={slide.title}
                title={slide.title}
              />
            ) : null}</>}

            {slide.showBannerInfo === 1 ? (
              <div className="content_wrap">
                <div className="col_left">
                  {slide.ratingIcon ? (
                    <div className="rating_icon">
                      <img
                        src={slide.ratingIcon}
                        alt={slide.ratingName}
                        title={slide.ratingName}
                      />
                    </div>
                  ) : null}
                  {slide.title ? (
                    <div
                      className={`title ${
                        slide.textColor === "black" ? "text_black" : ""
                      }`}
                    >
                      {slide.title}
                    </div>
                  ) : null}
                  {slide.description ? (
                    <div
                      className={`description ${
                        slide.textColor === "black" ? "text_black" : ""
                      }`}
                    >
                      <HtmlRender content={slide.description} />
                    </div>
                  ) : null}
                </div>
                <div className="col_right">
                  {slide.buttonText ? (
                    <div className="banner-btn">
                      <a href={slide.buttonLink} className="white_btn">
                        {slide.buttonText}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
})}
      </Slick>
    </div>
  );
};

export default Slider;
