import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { RootState } from "../../../../store";
import { BRANDS } from "../../../../constants/app";

interface IContactUsProps {}

const ContactUs: FC<IContactUsProps> = () => {
  const {brandId} = useSelector((state: RootState)=>({
    brandId: state.applicationReducer.brandId
  }))
  const { type } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(brandId === BRANDS.AU){
      dispatch(
        setContentModal({ type: ROUTES.CONTACT_US, category: '' })
      );
      dispatch(toggleContentModal(true));
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`)
    }
  }, []);

  useEffect(() => {
    dispatch(
      setContentModal({ type: ROUTES.CONTACT_US, category: type })
    );
    dispatch(toggleContentModal(true));
  }, [type]);

  return null;
};

export default ContactUs;
