import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { COUNTRY } from "../../../constants/app";
import "./membership.scss";
import {
  setModal,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import HelmetTitle from "../../../components/Helmet";
import { newMembership } from "../../../services/rewards";
import { formatMembershipType } from "../../../utils/rewards";
import { sleep } from "../../../utils/helper";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/path";
import Slider from "../../../components/UI/Slider";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import { getSlidesWatcher, setContentModal, setFooterType, toggleContentModal } from "../../../store/application/action";
import { getFaqApi } from "../../../services/sideMenus";
import { sortBy } from "lodash";
import { IFaqs } from "../../../models/sidemenu";
import HtmlRender from "../../../components/UI/HtmlRender";
import { Link } from "react-router-dom";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ALREADY_MEMBER_CONTENT, MEMBERSHIP_TAB, MEMBERSHIP_CONTENT, QUESTIONS } from "../../../constants/membership";
import STATE_MEMBERSHIP_TABLE from "../../../components/Membership/CompareAllTable/State";
import ANGELIKA_MEMBERSHIP_TABLE from "../../../components/Membership/CompareAllTable/Angelika";
import Faq from "../../../components/Membership/Faq";
import Footer from "../../../components/Membership/Footer";
import { updateMembbershipPurchase } from "../../../store/auth/action";
import AUSTRALIA from "../../../components/Membership/CompareAllTable/Australia";

interface IMembershipProps { }

const Membership: FC<IMembershipProps> = () => {
  const {
    isLoggedIn,
    countryId,
    currentCinema,
    slides
  } = useSelector((state: RootState) => ({
    isLoggedIn: state.authReducer.isLoggedIn,
    countryId: state.applicationReducer.countryId as COUNTRY,
    currentCinema: state.applicationReducer.currentCinema,
    slides: state.applicationReducer.slides,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState('compare');

  useEffect(() => {
    if (currentCinema && isLoggedIn) {
      sleep(3000).then(() => {
        navigate(`/${ROUTES.REWARDS}/${ROUTES.SUMMARY}`);
      })
    }
  }, [currentCinema, isLoggedIn])

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.REWARDS,
          location: '',
        })
      );
      dispatch(setFooterType(10));
    }
  }, [countryId]);


  const freePurchase = async () => {
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
        MODAL_TYPE.MEMBERSHIP_AUTH
        ],
        type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
        data: 'account'
      })
    );
    dispatch(toggleTicketModal(true));
    dispatch(updateMembbershipPurchase(null));
  };

  const paidPurchase = async () => {
    await newMembership(countryId, currentCinema, dispatch)
  }

  const joinNow = () => {
    if (['basic', 'club'].includes(tab.toLowerCase())) {
      freePurchase();
    } else if (['boost', 'gold'].includes(tab.toLowerCase())) {
      paidPurchase();
    }
  }

  return (
    <div className="state-membership-wrapper">
      <HelmetTitle title="Rewards" description="Rewards" />
      <div>
        <div className={"col"}>
          <Slider
            data={slides}
          />
        </div>
      </div>
      <div className="state-membership-container">
        <div className="first_content">
          <div className="header">How it works</div>
          <div className="description">
            <div className="content">
              <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'seat_white.svg'} />
              <div className="content-text"><span>Watch movies</span>, earn points, and unlock incredible benefits.</div>
            </div>
            <div className="content">
              <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'party_white.svg'} />
              <div className="content-text"><span>Earn points</span> every time you purchase a movie ticket, food, or drinks.</div>
            </div>
            <div className="content">
              <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + (countryId === COUNTRY.STA?'tickets_white.svg':'popcorn_white.svg')} />
              <div className="content-text"><span>Redeem</span> your points for free tickets, exclusive cinema treats, and more.</div>
            </div>
          </div>
        </div>

        <div className="second_content">
          <div className="header">Membership tiers</div>
          <p>We have something for everyone, whether you’re a casual movie-goer or a cinema enthusiast!</p>
          <div className="tab">
            <div className={`heading ${tab === 'compare' ? 'active' : ''}`} onClick={() => setTab('compare')}>COMPARE ALL</div>
            {MEMBERSHIP_TAB[countryId]?.map((v: string) => <div className={`heading ${tab === v ? 'active' : ''}`} onClick={() => setTab(v)}>{v}</div>)}
          </div>
        </div>
        {tab === "compare" ? <div className="third_content">
          {countryId === COUNTRY.STA?<STATE_MEMBERSHIP_TABLE paidPurchase={paidPurchase} freePurchase={freePurchase} />:null}
          {countryId === COUNTRY.ANG?<ANGELIKA_MEMBERSHIP_TABLE paidPurchase={paidPurchase} freePurchase={freePurchase} />:null}
          {countryId === COUNTRY.AUS || countryId === COUNTRY.NZ?<AUSTRALIA paidPurchase={paidPurchase} freePurchase={freePurchase}/> : null}
          <div className="terms_condition">Conditions apply to benefits and rewards.</div>
        </div> : <div className="paid_content">
          <div className="paid_content_container">
            <p className="header">{MEMBERSHIP_CONTENT[countryId]?.[tab]?.membershipHeader}</p>
            <div className="paid_content_wrapper">
              <div className="paid_logo">
                <div className={`logo ${tab}_${countryId}`}>
                  <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}${MEMBERSHIP_CONTENT[countryId]?.[tab]?.['membershipLogo']}`} />
                </div>
                <div className="paid_logo_desc">
                  {MEMBERSHIP_CONTENT[countryId]?.[tab]?.['membershipLogoDesc']}
                </div>
                <button className={`join-btn ${tab}_${countryId}`} onClick={joinNow}>JOIN NOW</button>
              </div>
              <div className="paid_desc">
                {MEMBERSHIP_CONTENT[countryId]?.[tab]?.['rewards'].map((v: any) => <div className="paid_desc_wrapper">
                  <h5>{v.header}</h5>
                  <p>{v.text}</p>
                </div>)}
              </div>
            </div>
          </div>
        </div>}
        {ALREADY_MEMBER_CONTENT[countryId as COUNTRY]?.header && countryId === COUNTRY.STA && !['BASIC'].includes(tab)? <div className="already_membership">
          <div className="container">
            <div className="header">{ALREADY_MEMBER_CONTENT[countryId as COUNTRY].header}</div>
            <p className="description">{ALREADY_MEMBER_CONTENT[countryId as COUNTRY].desc}</p>
          </div>
        </div> : null}
        <Faq/>
        <Footer/>
      </div>
    </div>
  );
};

export default Membership;
