import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleTicketModal } from "../../../../store/ticketBooking/action";
import "./kitchenClosed.scss";

interface IKitchenClosedProps {
  onCloseModal: () => void;
}

const KitchenClose: FC<IKitchenClosedProps> = ({ onCloseModal }) => {
  
    useEffect(()=>{
        document.getElementById('ticket-booking-modal')?.classList.add('bg-modal-black');
        return ()=>{
            document.getElementById('ticket-booking-modal')?.classList.remove('bg-modal-black');
        }
    },[])

  return (
    <div className="row food_common">
      <div className="col-12 mb-5">
        <h5>Our kitchen is closed for the day.</h5>
        <p>
          Please visit the counter upon arrival if you have any queries.
          <br />
          We apologise for any inconvenience.
        </p>
      </div>

      <div className="col-12">
        <button type="button" className="btn btn_ok" onClick={onCloseModal}>
          OK
        </button>
      </div>
    </div>
  );
};

export default KitchenClose;
