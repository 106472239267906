import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import './membershipHeader.scss';
import { COUNTRY } from "../../../../constants/app";
import { IMAGE_URL_CONFIG } from "../../../../constants/url";

interface IMembershipHeaderProps { }

const MembershipHeader: FC<IMembershipHeaderProps> = () => {
    const { countryId, membershipPurchase } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
        membershipPurchase: state.authReducer.membershipPurchase,
    }));

    const membershipTitle = useMemo(() => {
        if (countryId === COUNTRY.ANG) {
            return membershipPurchase ? 'Gold' : 'Club';
        } else if (countryId === COUNTRY.STA) {
            return membershipPurchase ? 'Boost' : '';
        } else {
            return membershipPurchase ? 'Boost' : '';
        }
    }, [countryId, membershipPurchase])

    return (
        <div className="membership-header-wrapper">
            <div className="col-12 px-2">
                <div className="membership-header">
                    Become an {countryId === COUNTRY.AUS || countryId === COUNTRY.NZ?'Reading':'Angelika'} Rewards {membershipTitle} Member!
                </div>
            </div>
        </div>
    );
};

export default MembershipHeader;
