import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { FC, useEffect, useState } from "react";
import moment from "moment";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropDown from "../../../../components/UI/DropDown/SelectDropDown";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import {
  gamingHiresApi,
} from "../../../../services/sideMenus";
import "./gamingHire.scss";
import CalendarPicker from "../../../../components/UI/Calendar";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY, TimeOfDayList } from "../../../../constants/app";
import { toast } from "react-toastify";
import { sortBy } from "lodash";
import { IDropDown } from "../../../../models/common";
import { gameHireTypeApi } from "../../../../services/application";
import { gamingHireBookingSubmissionApi, getGamingHireBookingApi, getMovieListApi } from "../../../../services/films";
import HtmlRender from "../../../../components/UI/HtmlRender";

interface IGamingHiresProps {
  onCloseModal: () => void;
}

const GamingHiresForm: FC<IGamingHiresProps> = ({ onCloseModal }) => {
  const { countryId, currentCinema, cinemas } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      cinemas: state.applicationReducer.cinemas,
    })
  );

  const [cinemaList, setCinemaList] = useState<IDropDown[]>([]);
  const [startDate, setStartDate] = useState(new Date().getDate() + 7);
  const [days, setDays] = useState<any>([0, 1, 2, 3, 4, 5, 6]);
  const [bookingLengthList, setBookingLengthList] = useState<any>([]);
  const [catSelectStatus, setCatSelectStatus] = useState("");
  // const [card, setCard] = useState<any>({});
  // const [errorMessage, setErrorMessage] = useState("");
  const [cardTypes, setCardTypes] = useState([]);
  const [selectedCinemaId, setSelectedCinemaId] = useState('')
  const [showError, setshowError] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [bannerData,setBannerData] = useState('');

  const getData = async () => {
    const {
      data: { data, statusCode },
    } = await gamingHiresApi(countryId);
    if (statusCode && data && data.gamingHireScreen) {
      const content = data.gamingHireScreen.find((v:any)=>v.title === 'DETAILS');
      if(content){
        setBannerData(content.description);
      }
    }
  };

  useEffect(() => {
    if (countryId) {
      getData();
    }
  }, [countryId]);


  useEffect(() => {
    if (cinemas) {
      let tempCinemas: any = [];
      Object.keys(cinemas).forEach(function (key) {
        const arrList = cinemas[key];
        arrList.forEach((item: any) => {
          tempCinemas.push({
            label: `${item.name}, ${item.stateCode} `,
            value: `${item.slug}`,
          });
        });
      });
      setCinemaList(sortBy(tempCinemas, row => row.label));
    }
  }, []);

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("bigScreeenGaming"),
      initialValues: {
        name: "",
        companyName: "",
        contactNumber: "",
        email: "",
        date: "",
        timeOfDay: "",
        cinema: "",
        bookingLength: "",
        experience: "",
        experienceHours: "",
        experienceAmount: "",
        comments: "",
        captcha: "",
        countryId: countryId
      },
      onSubmit: async (values) => {
        const { data: { statusCode, data } } = await gamingHireBookingSubmissionApi(values)
        if (statusCode == 200) {
          toast.success(data.message);
          setIsSubmitted(true)
        } else {
          setIsSubmitted(false)
          toast.error('Could not connect to services at the moment. Please try again. Thanks.');
        }
      },
    });

  const getGamehireType = async (cinemaId: string) => {
    const { data: { data } } = await gameHireTypeApi(cinemaId);
    if (data && data.gamingHireTypes && data.gamingHireTypes.isDataExist !== 0) {
      const gaming = data.gamingHireTypes;
      const selecteddays = gaming.days;
      const daysArr = selecteddays.split(',').map(Number);
      const totalDaysNo = [0, 1, 2, 3, 4, 5, 6];
      setDays(totalDaysNo.filter((el) => {
        return !daysArr.includes(el);
      }));
      setCardTypes(gaming.types);
      setshowError(false);
    } else {
      setDays([0, 1, 2, 3, 4, 5, 6]);
      setBookingLengthList([]);
      setCardTypes([]);
      setshowError(true);
    }
  }

  const onItemSelect = async (item: any) => {
    setFieldValue('bookingLength', null);
    setFieldValue('date', null);
    setFieldValue('experience', null);
    setCatSelectStatus("");

    if (item) {
      setSelectedCinemaId(item);
    }
    await getGamehireType(item);
    // await getMovieList(item.slug);
  }

  const categoryClick = async (cat: any) => {
    setFieldValue('experience', cat.type_name);
    setFieldValue('experienceHours', cat.hours);
    setFieldValue('experienceAmount', cat.amount)
    setCatSelectStatus(cat.type_name);
    const { data: { data: { gamingBookingLength } } } = await getGamingHireBookingApi({
      categoryId: cat.id,
      categoryCinemaId: selectedCinemaId
    })

    if (gamingBookingLength.length > 0) {
      setFieldValue('bookingLength', null);
      const extraGamingBookingLength: any = [];
      const gamingbookinglength = gamingBookingLength;
      gamingbookinglength.forEach((obj: any) => {
        extraGamingBookingLength.push({
          value: obj.booking_length,
          label: obj.booking_length
        });
      });
      setBookingLengthList([...extraGamingBookingLength]);
      setshowError(false);
    } else {
      setBookingLengthList([]);
      setshowError(true);
    }

  }

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  return isSubmitted ? <div className="gaming-hire-form-wrap">
    <div className="modal-header">
      <h5>Thank You</h5>
    </div>
    <div className="modal-body">
      <h6>Thank you for your booking. Your enquiry has been submitted successfully. One of our team will be in touch with you shortly to discuss further.</h6>
    </div>
  </div> :
    <div className="gaming-hire-form-wrap">
      <div className="modal-header">
        <div className="row">
          <div className="col-md-12">
            <h2 className="title mb-5">Big Screen Gaming</h2>
            <div className="col-md-12">
              <ul className="gaming-hire-wrap">
              <HtmlRender content={bannerData}/>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="form_page">
          <div className="row">
            <div className="col-12 col-md-6 ">
              <label className="form-label">Name*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.name}
                  field={"name"}
                  handleChange={handleChange}
                  touched={touched.name!}
                  error={errors.name}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 ">
              <label className="form-label">Company Name</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.companyName}
                  field={"companyName"}
                  handleChange={handleChange}
                  touched={touched.companyName!}
                  error={errors.companyName}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Contact Number</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.contactNumber}
                  field={"contactNumber"}
                  handleChange={handleChange}
                  touched={touched.contactNumber!}
                  error={errors.contactNumber}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Email*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.email}
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <label className="form-label">Cinema*</label>
              <div className="form-group pass_show">
                <SelectDropDown
                  field={"cinema"}
                  options={cinemaList}
                  setFieldValue={setFieldValue}
                  touched={touched.cinema!}
                  error={errors.cinema}
                  value={values.cinema}
                  onChange={onItemSelect}
                />
              </div>
              {showError ? (
                <div className="error_message">
                  Gaming hire experience not available for the selected cinema
                </div>
              ) : null}
            </div>

            {cardTypes ? <div className="category_wrap">
              <ul>
                {cardTypes.map((listItem: any) => (
                  <li onClick={() => categoryClick(listItem)}>
                    <div
                      className={`category_list ${catSelectStatus == listItem.type_name ? "active" : ""
                        }`}
                    >
                      <h6>{listItem.type_name}</h6>
                      <p>{listItem.description}</p>
                      <div className="value_wrap">
                        <div>
                          <span>Total</span>
                          <h3>${listItem.amount}</h3>
                        </div>
                        <p>{listItem.hours} hours</p>
                      </div>
                    </div>
                    <div className={`cat_footer ${catSelectStatus == listItem.type_name ? 'active' : ''}`}>
                      {values.experience == listItem.type_name ? 'Selected' : 'Choose the experience'}</div>
                  </li>
                ))}
              </ul>
            </div> : null}
            {(cardTypes.length > 0 && !catSelectStatus) ?
              <div className="error_message col-md-12 text-center">
                <span>Please Choose the experience</span>
              </div> : null
            }
            <div className="col-12 col-md-6">
              <label className="form-label">Booking Length*</label>
              <div className="form-group pass_show">
                <SelectDropdown
                  field={"bookingLength"}
                  options={bookingLengthList}
                  setFieldValue={setFieldValue}
                  touched={touched.bookingLength!}
                  error={errors.bookingLength}
                  value={values.bookingLength}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Date*</label>
              <div className="form-group form-group-date-field">
                <CalendarPicker
                  minDate={new Date(moment().add(7, "days").format())}
                  tileDisabled={({ date }: any) => {
                    return days.includes(date.getDay()) ? true : false
                  }

                  }
                  field={"date"}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  touched={touched.date!}
                  error={errors.date}
                  showWeekNumbers={false}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <label className="form-label">Time of Day</label>
              <div className="form-group">
                <SelectDropdown
                  field={"timeOfDay"}
                  options={TimeOfDayList}
                  value={values.timeOfDay}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  touched={touched.timeOfDay!}
                  error={errors.timeOfDay}
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <label className="form-label">Comments</label>
              <div className="form-group">
                <textarea
                  title="Enter your Comments"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  aria-label="Comments"
                />
              </div>
            </div>
            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code*</label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={onChangeCaptcha}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="button_wrap text-center div">
            <button
              type="button"
              className="btn black_btn mx-2"
              onClick={() => handleSubmit()}
            >
              REQUEST BOOKING
            </button>
          </div>
        </div>
      </div>
    </div>
    ;
};

export default GamingHiresForm;
