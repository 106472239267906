import React, { FC } from 'react';
import { IMAGE_URL_CONFIG } from '../../../../constants/url';
import './inActivAccount.scss'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { toast } from 'react-toastify';
import { COUNTRY } from '../../../../constants/app';


interface IAccountUpdateInfoProps {
    onCloseModal: any
}

const InActiveAccount: FC<IAccountUpdateInfoProps> = ({ onCloseModal }) => {

    const { contentModal, settings, countryId } = useSelector((state: RootState) => ({
        contentModal: state.applicationReducer.contentModal,
        settings: state.applicationReducer.settings,
        countryId: state.applicationReducer.countryId
    }))
    
    const copy = (e:any)=>{
        navigator.clipboard.writeText(settings?.rewardsEmail);
        toast.success("Copied")
    }

    return <div className="inactive-account-wrapper">
        <div className="col-12">
            <div className="content">
                <div className="body">
                    <div className="header">
                        <h3 className='title-1'>{countryId === COUNTRY.ANG?'ANGELIKA':countryId === COUNTRY.STA?'STATE': 'READING'}</h3>    
                        <h3 className='title-2'>REWARDS</h3>    
                    </div>
                    <div className='divider'></div>
                    <div className='contact-details'>
                        <p className='title'>If you are having issues with your account, please;</p>
                        <p>Contact {countryId === COUNTRY.ANG?'Angelika':countryId === COUNTRY.STA?'State Cinema': 'Reading'} Staff at a cinema.</p>
                        <div className='or'>
                            <div >
                                <span>OR</span>
                            </div>
                        </div>

                        <p>Send us an email to the below email address:</p>
                        <p className='rewards-email'>{settings?.rewardsEmail}</p>
                        <div>
                            <button className='btn black_btn btn-copy btn-sm' onClick={copy}>
                            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'copy_icon.svg'} />
                            <span>COPY EMAIL</span>
                            </button>
                        </div>
                    </div>
                    <div className={`movie_footer_btn_el col-md-12 ml-auto mt-3`}>
                        <div className="movie_button_wrap justify-content-end">
                            <button
                                type="button"
                                className="btn gray_btn"
                                onClick={onCloseModal}>
                                CLOSE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default InActiveAccount;