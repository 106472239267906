import axios from 'axios';
import { ENDPOINT } from '../endpoint';

export const getItemsApi = (payload: any) => {
    return axios.post(`${ENDPOINT.CONCESSION_ITEMS}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}


export const getLicenseApi = (payload: any) => {
    return axios.post(`${ENDPOINT.GET_LICIENSE}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const ordersApi = (payload: any) => {
    return axios.post(`${ENDPOINT.CONCESSION_ORDERS}`, payload);
}

export const sentOtp = (payload: any) => {
    return axios.post(`${ENDPOINT.GET_OTP}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const verifyOTP = (payload: any) => {
    return axios.post(`${ENDPOINT.VERIFY_OTP}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const getBookedTicketsApi = (payload: any) => {
    return axios.post(`${ENDPOINT.GET_BOOKED_TICKETS}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const orderHistoryApi = (payload: any) => {
    return axios.post(`${ENDPOINT.ORDER_HISTORY}`, payload);
}

export const foodCollectApi = (payload: any) => {
    return axios.post(`${ENDPOINT.FOOD_COLLECT}`, payload);
}

export const foodQrCodeApi = (payload: any) =>{
    return axios.post(`${ENDPOINT.CONCESSION_SEND_NOTIFICATION}`, payload)
}