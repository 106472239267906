import React, { FC } from "react";
import { ICartData } from "../../../store/giftshop/type";
import { GiftType } from "../../../models/giftcard";
import moment from "moment";

interface IRemoveGiftCardProps {
  item: ICartData;
  index: number;
  removeCard: any;
  cancelRemove: ()=>void
}

const RemoveGiftCard: FC<IRemoveGiftCardProps> = ({ item,index, removeCard, cancelRemove }) => {
  return (
    <div>
      <div className="cart-detail-left remove-cart-detail-left">
        <div className="cartImg">
          <img src={item.image} alt="" />
        </div>
        <div className="cartContent">
          <h4>{item.description}</h4>
          {item.giftcardType == GiftType.ecard ? (
            <p>
              Gift Type: <span>E-Gift Card</span>
            </p>
          ) : item.giftcardType == GiftType.physical ? (
            <p>
              Gift Type: <span>Physical Gift Card</span>
            </p>
          ) : item.giftcardType == GiftType.exp ? (
            <p>
              Gift Type: <span>Experience</span>
            </p>
          ) : null}
          <p>
            Gift To: <span>{item.recipientName}</span>
          </p>
          {item.giftcardType == GiftType.ecard ? (
            <p>
              Deliver To: <span>{item.recipientemail}</span>
            </p>
          ) : item.giftcardType == GiftType.ecard ? (
            <p>
              Delivery Date:{" "}
              <span>{moment(item.deliveryDate).format("DD MMM YYYY")}</span>
            </p>
          ) : item.giftcardType != GiftType.ecard ? (
            <p>
              Deliver To:
              <span>
                {item.address1}
                {item.address2},{item.city},{item.state},{item.postCode}
              </span>
            </p>
          ) : null}
          <p>
            From: <span>{item.senderName}</span>
          </p>
          <p>
            Message: <span>{item.giftMessage}</span>
          </p>
          <p>
            Quantity: <span>{item.quantity}</span>
          </p>
          <p>
            Item Total: <span>${item.itemTotal}</span>
          </p>
        </div>
      </div>
      <div className="row btn-section  remove-cart-btn-section">
        <div className="col-md-12 movie_footer_btn_el ml-auto">
          <div className="movie_button_wrap d-flex justify-content-end">
            <button
              type="button"
              className="btn light_gray_btn"
              onClick={cancelRemove}
            >
              cancel
            </button>
            <button type="button" className="btn black_btn" onClick={() => removeCard(index)}>
              remove gift
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveGiftCard;
