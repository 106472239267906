import React, { FC } from "react";
import "./confirm.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

interface IRefundConfirmationProps {
  onCloseModal: () => void;
}

const RefundConfirmation: FC<IRefundConfirmationProps> = ({ onCloseModal }) => {
  const { data } = useSelector((state: RootState) => ({
    data: state.ticketBookingReducer.modal.data,
  }));

  return (
    <div className="refund-confirmation-wrapper">
      <div className="col-12">
        <div className="content">
          <p className="header">
            A confirmation email has been sent to: {data}
          </p>
          <p className="body">
            Please allow up to 72 business hours for the funds to reflect in
            your account.
          </p>
        </div>
        <div className={`movie_footer_btn_el col-md-12 ml-auto px-5`}>
          <div className="movie_button_wrap justify-content-end">
            <button
              type="button"
              className="btn black_btn"
              onClick={onCloseModal}
            >
              RETURN TO HOME PAGE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundConfirmation;
