import { ENDPOINT } from "../services/endpoint";
import { COUNTRY } from "./app";
import { API_REQUEST_TYPE } from "./url";

export const MAKE_PAYMENT_REQUEST_TYPE = {
    [COUNTRY.AUS]: API_REQUEST_TYPE.FATZEBRA_MAKE_PAYMENT,
    [COUNTRY.STA]: API_REQUEST_TYPE.FATZEBRA_MAKE_PAYMENT,
    [COUNTRY.ANG]: API_REQUEST_TYPE.FATZEBRA_MAKE_PAYMENT,
    [COUNTRY.NZ]: API_REQUEST_TYPE.STRIPE_MAKE_PAYMENT,
    [COUNTRY.US]: API_REQUEST_TYPE.PAYEEZY_MAKE_PAYMENT,
    [COUNTRY.CON]: API_REQUEST_TYPE.PAYEEZY_MAKE_PAYMENT,
    [COUNTRY.AFC]: API_REQUEST_TYPE.PAYEEZY_MAKE_PAYMENT,
}

export const TOKEN_PAYMENT_REQUEST_TYPE = {
    [COUNTRY.AUS]: API_REQUEST_TYPE.FATZEBRA_TOKEN_PAYMENT,
    [COUNTRY.STA]: API_REQUEST_TYPE.FATZEBRA_TOKEN_PAYMENT,
    [COUNTRY.ANG]: API_REQUEST_TYPE.FATZEBRA_TOKEN_PAYMENT,
    [COUNTRY.NZ]: API_REQUEST_TYPE.STRIPE_TOKEN_PAYMENT,
    [COUNTRY.US]: API_REQUEST_TYPE.PAYEEZY_TOKEN_PAYMENT,
    [COUNTRY.CON]: API_REQUEST_TYPE.PAYEEZY_TOKEN_PAYMENT,
    [COUNTRY.AFC]: API_REQUEST_TYPE.PAYEEZY_TOKEN_PAYMENT,
}

export const GIFTSHOP_MAKE_PAYMENT_REQUEST_TYPE = {
    [COUNTRY.AUS]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_MAKE_PAYMENT,
    [COUNTRY.STA]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_MAKE_PAYMENT,
    [COUNTRY.ANG]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_MAKE_PAYMENT,
    [COUNTRY.NZ]: API_REQUEST_TYPE.STRIPE_GIFTCARD_MAKE_PAYMENT,
    [COUNTRY.US]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_MAKE_PAYMENT,
    [COUNTRY.CON]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_MAKE_PAYMENT,
    [COUNTRY.AFC]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_MAKE_PAYMENT,
}

export const GIFT_SHOP_REFUND_REQUEST_TYPE = {
    [COUNTRY.AUS]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_MAKE_REFUND,
    [COUNTRY.STA]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_MAKE_REFUND,
    [COUNTRY.ANG]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_MAKE_REFUND,
    [COUNTRY.NZ]: API_REQUEST_TYPE.STRIPE_GIFTCARD_MAKE_REFUND,
    [COUNTRY.US]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_MAKE_REFUND,
    [COUNTRY.CON]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_MAKE_REFUND,
    [COUNTRY.AFC]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_MAKE_REFUND,
}

export const GIFT_SHOP_TOKEN_PAYMENT_REQUEST_TYPE = {
    [COUNTRY.AUS]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_TOKEN_PAYMENT,
    [COUNTRY.STA]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_TOKEN_PAYMENT,
    [COUNTRY.ANG]: API_REQUEST_TYPE.FATZEBRA_GIFTCARD_TOKEN_PAYMENT,
    [COUNTRY.NZ]: API_REQUEST_TYPE.STRIPE_GIFTCARD_TOKEN_PAYMENT,
    [COUNTRY.US]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_TOKEN_PAYMENT,
    [COUNTRY.CON]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_TOKEN_PAYMENT,
    [COUNTRY.AFC]: API_REQUEST_TYPE.PAYEEZY_GIFTCARD_TOKEN_PAYMENT,
}

export const MAKE_PAYMENT_API = {
    [COUNTRY.AUS]: ENDPOINT.FATZEBRA_PAYMENT,
    [COUNTRY.STA]: ENDPOINT.FATZEBRA_PAYMENT,
    [COUNTRY.ANG]: ENDPOINT.FATZEBRA_PAYMENT,
    [COUNTRY.NZ]: ENDPOINT.STRIPE_PAYMENT,
    [COUNTRY.US]: ENDPOINT.PAYEEZY_PAYMENT,
    [COUNTRY.CON]: ENDPOINT.PAYEEZY_PAYMENT,
    [COUNTRY.AFC]: ENDPOINT.PAYEEZY_PAYMENT,
}


export const REFUND_API = {
    [COUNTRY.AUS]: ENDPOINT.FATZEBRA_REFUND,
    [COUNTRY.STA]: ENDPOINT.FATZEBRA_REFUND,
    [COUNTRY.ANG]: ENDPOINT.FATZEBRA_REFUND,
    [COUNTRY.NZ]: ENDPOINT.STRIPE_REFUND,
    [COUNTRY.US]: ENDPOINT.PAYEEZY_REFUND,
    [COUNTRY.CON]: ENDPOINT.PAYEEZY_REFUND,
    [COUNTRY.AFC]: ENDPOINT.PAYEEZY_REFUND,
}