import { HEADER_TYPE, ITICKET_FLOW, MODAL_TYPE, TICKET_FLOW_TYPES } from "../models/tickets";

export const TICKET_FLOW: ITICKET_FLOW = {
    [TICKET_FLOW_TYPES.NO_OF_SEAT]: {
        [MODAL_TYPE.NO_OF_SEAT]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.NO_OF_SEAT,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.AUTH_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.PAYMENT_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.SEAT_SELECTION]: {
        [MODAL_TYPE.SEAT_SELECTION]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.SEAT_SELECTION,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.AUTH_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.PAYMENT_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.MULTI_TICKETING]: {
        [MODAL_TYPE.SEAT_SELECTION]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.SEAT_SELECTION,
            nextModal: MODAL_TYPE.NO_OF_SEAT,
            data: null,
        },
        [MODAL_TYPE.NO_OF_SEAT]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal:  MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.NO_OF_SEAT,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.AUTH_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.PAYMENT_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.NO_OF_SEAT_FB]: {
        [MODAL_TYPE.NO_OF_SEAT]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.NO_OF_SEAT,
            nextModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            data: null,
        },
        [MODAL_TYPE.FOOD_AND_BEVERAGE]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.AUTH_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.PAYMENT_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.SEAT_SELECTION_FB]: {
        [MODAL_TYPE.SEAT_SELECTION]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.SEAT_SELECTION,
            nextModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            data: null,
        },
        [MODAL_TYPE.FOOD_AND_BEVERAGE]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.AUTH_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.PAYMENT_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.MULTI_TICKETING_FB]: {
        [MODAL_TYPE.SEAT_SELECTION]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.SEAT_SELECTION,
            nextModal: MODAL_TYPE.NO_OF_SEAT,
            data: null,
        },
        [MODAL_TYPE.NO_OF_SEAT]: {
            header: HEADER_TYPE.TICKET_BOOKING_HEADER,
            prevModal:MODAL_TYPE.SEAT_SELECTION,
            modal: MODAL_TYPE.NO_OF_SEAT,
            nextModal:MODAL_TYPE.FOOD_AND_BEVERAGE,
            data: null,
        },
        [MODAL_TYPE.FOOD_AND_BEVERAGE]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.AUTH_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.PAYMENT_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.DEFAULT]: {
        [MODAL_TYPE.DEFAULT]: {
            header: "",
            prevModal: "",
            modal: "",
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.INITIAL_FOOD_AND_BEVERAGE]: {
        [MODAL_TYPE.INITIAL_FOOD_AND_BEVERAGE]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.INITIAL_FOOD_AND_BEVERAGE,
            nextModal: MODAL_TYPE.FUTURE_FOOD_AND_BEVERAGE,
            data: null,
        },
        [MODAL_TYPE.SESSION]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.SESSION,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.LOGIN]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.INITIAL_FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.LOGIN,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.PURCHASE_LIST]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.INITIAL_FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.PURCHASE_LIST,
            nextModal: MODAL_TYPE.PRE_PURCHASE_ORDER,
            data: null,
        },
        [MODAL_TYPE.PRE_PURCHASE_ORDER]: {
            header: HEADER_TYPE.FUTURE_FANDB_HEADER,
            prevModal: MODAL_TYPE.PURCHASE_LIST,
            modal: MODAL_TYPE.PRE_PURCHASE_ORDER,
            nextModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            data: null,
        },
        [MODAL_TYPE.FOOD_AND_BEVERAGE]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.AUTH_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.PAYMENT_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE]: {
        [MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN,
            nextModal: MODAL_TYPE.DEFAULT,
            data: null,
        },
        [MODAL_TYPE.FOOD_AND_BEVERAGE]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN,
            modal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            nextModal: MODAL_TYPE.ORDER_SUMMARY,
            data: null,
        },
        [MODAL_TYPE.FOOD_AND_BEVERAGE_FUTURE]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN,
            modal: MODAL_TYPE.FOOD_AND_BEVERAGE_FUTURE,
            nextModal: MODAL_TYPE.MOVIE_LISTING,
            data: null,
        },
        [MODAL_TYPE.MOVIE_LISTING]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE_FUTURE,
            modal: MODAL_TYPE.MOVIE_LISTING,
            nextModal: MODAL_TYPE.PRE_PURCHASE_ORDER,
            data: null,
        },
        [MODAL_TYPE.PRE_PURCHASE_ORDER]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.MOVIE_LISTING,
            modal: MODAL_TYPE.PRE_PURCHASE_ORDER,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.EDIT_FUTURE_GL]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.PRE_PURCHASE_ORDER,
            modal: MODAL_TYPE.EDIT_FUTURE_GL,
            nextModal: MODAL_TYPE.PRE_PURCHASE_ORDER,
            data: null,
        },
        [MODAL_TYPE.ORDER_SUMMARY]: {
            header: HEADER_TYPE.ORDER_SUMMARY_HEADER,
            prevModal: MODAL_TYPE.FOOD_AND_BEVERAGE,
            modal: MODAL_TYPE.ORDER_SUMMARY,
            nextModal: MODAL_TYPE.AUTH,
            data: null,
        },
        [MODAL_TYPE.AUTH]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.ORDER_SUMMARY,
            modal: MODAL_TYPE.AUTH,
            nextModal: MODAL_TYPE.PAYMENT,
            data: null,
        },
        [MODAL_TYPE.PAYMENT]: {
            header: HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.PAYMENT,
            nextModal: MODAL_TYPE.TICKET_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.TICKET_CONFIRMATION]: {
            header: HEADER_TYPE.TICKET_CONFIRMATION_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.TICKET_ERROR]: {
            header: HEADER_TYPE.TICKET_ERROR_HEADER,
            prevModal: MODAL_TYPE.NO_OF_SEAT,
            modal: MODAL_TYPE.TICKET_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.SOCIAL_SHARE]: {
        [MODAL_TYPE.SOCIAL_SHARE]: {
            header: HEADER_TYPE.SOCIAL_SHARE,
            prevModal: "",
            modal: MODAL_TYPE.SOCIAL_SHARE,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.REFUND]: {
        [MODAL_TYPE.REFUND]: {
            header: HEADER_TYPE.REFUND,
            prevModal: "",
            modal: MODAL_TYPE.REFUND,
            nextModal: MODAL_TYPE.REFUND_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.REFUND_CONFIRMATION]: {
            header: HEADER_TYPE.REFUND_CONFIRMATION,
            prevModal: "",
            modal: MODAL_TYPE.REFUND_CONFIRMATION,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.GIFTSHOP]: {
        [MODAL_TYPE.GIFTSHOP_PAYMENT]: {
            header: HEADER_TYPE.GIFTSHOP_PAYMENT_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.GIFTSHOP_PAYMENT,
            nextModal: MODAL_TYPE.GIFTSHOP_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.GIFTSHOP_CONFIRMATION]: {
            header: HEADER_TYPE.GIFTSHOP_CONFIRMATION_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.GIFTSHOP_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.GIFTSHOP_ERROR]: {
            header: HEADER_TYPE.GIFTSHOP_ERROR_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.GIFTSHOP_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.MILITARY_MEMBER]: {
        [MODAL_TYPE.MILITARY_MEMBER_CONFIRMATION]: {
            header: HEADER_TYPE.MILITARY_MEMBER_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MILITARY_MEMBER_CONFIRMATION,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.KITCHEN_CLOSED]: {
        [MODAL_TYPE.KITCHEN_CLOSED]: {
            header: HEADER_TYPE.KITCHEN_CLOSED_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.KITCHEN_CLOSED,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE]: {
        [MODAL_TYPE.MEMBERSHIP_AUTH]: {
            header: HEADER_TYPE.MEMBERSHIP_AUTH_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MEMBERSHIP_AUTH,
            nextModal: MODAL_TYPE.MEMBERSHIP_PAYMENT,
            data: null,
        },
        [MODAL_TYPE.MEMBERSHIP_PAYMENT]: {
            header: HEADER_TYPE.MEMBERSHIP_PAYMENT_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MEMBERSHIP_PAYMENT,
            nextModal: MODAL_TYPE.MEMBERSHIP_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.MEMBERSHIP_CONFIRMATION]: {
            header: HEADER_TYPE.MEMBERSHIP_CONFIRMATION_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MEMBERSHIP_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.MEMBERSHIP_ERROR]: {
            header: HEADER_TYPE.MEMBERSHIP_ERROR_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MEMBERSHIP_ERROR,
            nextModal: "",
            data: null,
        },
    },
    [TICKET_FLOW_TYPES.MEMBERSHIP_FREE_PURCHASE]: {
        [MODAL_TYPE.MEMBERSHIP_AUTH]: {
            header: HEADER_TYPE.MEMBERSHIP_AUTH_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MEMBERSHIP_AUTH,
            nextModal: MODAL_TYPE.MEMBERSHIP_CONFIRMATION,
            data: null,
        },
        [MODAL_TYPE.MEMBERSHIP_CONFIRMATION]: {
            header: HEADER_TYPE.MEMBERSHIP_CONFIRMATION_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MEMBERSHIP_CONFIRMATION,
            nextModal: "",
            data: null,
        },
        [MODAL_TYPE.MEMBERSHIP_ERROR]: {
            header: HEADER_TYPE.MEMBERSHIP_ERROR_HEADER,
            prevModal: "",
            modal: MODAL_TYPE.MEMBERSHIP_ERROR,
            nextModal: "",
            data: null,
        },
    }
};
