import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Outlet } from 'react-router-dom';
import { Link } from "react-router-dom";
import Slider from "../../../components/UI/Slider";
import { BRANDS, COUNTRY } from "../../../constants/app";
import { ROUTES } from "../../../constants/path";
import { API_REQUEST_TYPE, URLS } from "../../../constants/url";
import { RootState } from "../../../store";
import {
  getSlidesWatcher,
  openSliderAction,
  setContentModal,
  setFooterType,
  toggleContentModal,
} from "../../../store/application/action";

import "./rewards.scss";
import { GIFT_CARD_ACTION } from "../../../models/giftcard";
import HelmetTitle from "../../../components/Helmet";
import { memberValidationWatcher, showLogin } from "../../../store/auth/action";
import { socialMediaUrl } from "../../../utils/helper";
import Footer from "../../../components/Membership/Footer";

interface IGiftShopProps { }

const Rewards: FC<IGiftShopProps> = () => {
  const {
    slides,
    countryId,
    currentTab,
    brandId,
    currentCinema,
    currentAction,
    userDetails,
    isLoggedIn,
  } = useSelector((state: RootState) => ({
    slides: state.applicationReducer.slides,
    countryId: state.applicationReducer.countryId,
    currentTab: state.giftCardReducer.currentTab,
    currentAction: state.giftCardReducer.currentAction,
    brandId: state.applicationReducer.brandId,
    currentCinema: state.applicationReducer.currentCinema,
    userDetails: state.authReducer.userDetails,
    isLoggedIn: state.authReducer.isLoggedIn,
  }));

  const navigate = useNavigate();

  const [LINK, setLink] = useState<any>({
    summary: `/${ROUTES.REWARDS}/${ROUTES.SUMMARY}`,
    rewards: `/${ROUTES.REWARDS}/${ROUTES.REWARDITEM}`,
    profile: `/${ROUTES.REWARDS}/${ROUTES.PROFILE}`,
    benefits: `/${ROUTES.REWARDS}/${ROUTES.BENEFITS}`,
    transaction: `/${ROUTES.REWARDS}/${ROUTES.TRANSACTION}`,
  });

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [facebookUrl, setFacebookUrl] = useState<string>("");
  const [instagramUrl, setInstagramUrl] = useState<string>("");
  const { renewal } = useParams();

  useEffect(() => {
    if (currentCinema && !isLoggedIn) {
      if (renewal && renewal === 'renewal') {
        dispatch(showLogin(true));
        dispatch(openSliderAction({ open: false, type: "membershipRenewal" }));
      }
      navigate(countryId === COUNTRY.STA ? `/${ROUTES.MEMBERSHIP_STATE}` : `/${ROUTES.MEMBERSHIPS}`);
    }else if(!isLoggedIn){
      navigate(countryId === COUNTRY.STA ? `/${ROUTES.MEMBERSHIP_STATE}` : `/${ROUTES.MEMBERSHIPS}`);
    }
  }, [currentCinema, isLoggedIn, renewal])

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.REWARDS,
          location: ""
        })
      );
      dispatch(setFooterType(10));
    }
  }, [countryId]);

  useEffect(() => {
    if (userDetails) {
      const payload = {
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userDetails?.result?.cardNumber,
        cardNumber: userDetails?.result?.cardNumber,
        countryId: countryId,
      };
      dispatch(memberValidationWatcher(payload));
    }
  }, [userDetails]);



  const openModal = (type: string) => {
    dispatch(setContentModal({ type }));
    dispatch(toggleContentModal(true));
  }

  useEffect(() => {
    if (countryId) {
      const { facebook, instagram } = socialMediaUrl(countryId);
      setFacebookUrl(facebook);
      setInstagramUrl(instagram);
    }
  }, [countryId]);

  return (
    <section>
      <HelmetTitle title="Rewards" description="Rewards" />
      <div className={"col"}>
        <Slider
          data={slides}
        />
      </div>
      <div>
        <div className="reward-tab">
          <div className="stickytabmenu">
            <div className="container">
              <ul className="nav" role="tablist">
                <li className="nav-item" role="tab">
                  <Link
                    to={LINK?.summary}
                    className={`nav-link ${pathname.includes(LINK?.summary)
                      ? "active"
                      : ""
                      }`}
                  >
                    SUMMARY
                  </Link>
                </li>
                <li className="nav-item" role="tab">
                  <Link
                    to={LINK?.rewards}
                    className={`nav-link ${pathname.includes(LINK?.rewards)
                      ? "active"
                      : ""
                      }`}
                  >
                    REWARDS
                  </Link>
                </li>
                <li className="nav-item" role="tab">
                  <Link
                    to={LINK?.profile}
                    className={`nav-link ${pathname.includes(LINK?.profile)
                      ? "active"
                      : ""
                      }`}
                  >
                    MY ACCOUNT
                  </Link>
                </li>
                <li className="nav-item" role="tab">
                  <Link
                    to={LINK?.benefits}
                    className={`nav-link ${pathname.includes(LINK?.benefits)
                      ? "active"
                      : ""
                      }`}
                  >
                    MY BENEFITS
                  </Link>
                </li>
                <li className="nav-item" role="tab">
                  <Link
                    to={LINK?.transaction}
                    className={`nav-link ${pathname.includes(LINK?.transaction)
                      ? "active"
                      : ""
                      }`}
                  >
                    TRANSACTIONS
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="child_container"><Outlet /></div>
      </div>
      <Footer/>
    </section>
  );
};

export default Rewards;
