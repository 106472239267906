import React, { FC } from "react";
// import "./militaryConfirmation.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

interface IMilitaryConfirmationProps {
  onCloseModal: () => void;
}

const MembershipError: FC<IMilitaryConfirmationProps> = ({
  onCloseModal,
}) => {
  const { data } = useSelector((state: RootState) => ({
    data: state.ticketBookingReducer.modal.data,
  }));

  return (
    <div className="row ticket-error-wrapper">
   
    <div className= "col-12">
      <div className="ticket_error">
        <div className="ticket-header">Transaction Failed!</div>
          <div className="ticket_detail">
            <p>
              There appears to have been a problem processing your
              transaction.
            </p>
            <p>
              Any transaction in error will be automatically refunded, which
              may take 1-3 days to appear on your statement. Apologies for the
              inconvenience.
            </p>
          </div>
      </div>

      <div className={`movie_footer_btn_el col-md-12 ml-auto`}>
        <div className="movie_button_wrap justify-content-center">
          <button type="button" className="btn black_btn" onClick={onCloseModal}>
            RETURN TO HOME SCREEN
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default MembershipError;
