import React, { FC, useEffect, useState } from "react";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG } from "../../../../constants/url";
import { upcomingTicketApi } from "../../../../services/auth";
import { ITicketHistory, IUpcomingTicket } from "../../../../models/auth";
import Slick from "react-slick";
import './ticketList.scss';
import TicketDetails from "../TicketDetails";

interface ITicketListProps {
  type: string
}

const settings = {
  className: "",
  centerPadding: "10px",
  dots: true,
  lazyload: true,
  infinite: false,
  speed: 300,
  autoplay: false,
  autoplaySpeed: 4000,
  cssEase: "linear",
  fade: false,
  slidesPerRow: 4,
  swipeToSlide: true,
  arrows: true,
  appendDots: (dots: any) => <div>  <div>Slide to see more</div>
    <div>
      <ul>{dots}</ul>
    </div></div>,
  customPaging: (i: any) => (
    <div className="ft-slick__dots--custom">
      <div className="loading" />
    </div>
  ),
};

const TicketList: FC<ITicketListProps> = ({ type }) => {
  const { countryId, userDetails, memberDetail, isLoggedIn, openSlider } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      isLoggedIn: state.authReducer.isLoggedIn,
      openSlider: state.applicationReducer.openSlider,
    })
  );

  const [bookingData, setBookingData] = useState<ITicketHistory[]>([]);
  const [data, setData] = useState<IUpcomingTicket | null>(null);
  const [showTickets, setShowTickets] = useState<boolean>(true);

  const getUpcomingTickets = async () => {
    const payload = {
      countryId: countryId,
      emailId: userDetails?.email!,
      requestType: API_REQUEST_TYPE.UPCOMING_TRANSACTION,
      flag: 'showAll',
      type: type
    };
    const { status, response } = (await upcomingTicketApi(payload)) as any;
    if (status) {
      const {
        data: { data },
      } = response;
      setBookingData(data);
    }
  };


  useEffect(() => {
    if (userDetails && isLoggedIn && openSlider && openSlider.open) {
      getUpcomingTickets();
      setShowTickets(true);
      setData(null);
    }
  }, [userDetails, isLoggedIn, openSlider, type]);

  const showDetails = (data: IUpcomingTicket) => {
    setData(data);
    setShowTickets(true);
  }

  return showTickets && data ? <TicketDetails type={type} data={data} setShowTickets={setShowTickets} />
    : <div className="ticket-list-wrapper">
      <Slick
        {...settings}
        className="carousel"

      >
        {bookingData.length ? bookingData.map((bh: ITicketHistory) =>

          bh.tickets.map((v: IUpcomingTicket, i: number) =>
            <div className="ticket-list">
              {i == 0 ? <h4 className="date">{bh.date}</h4> : null}
              <div className="movie-details">
                <div className="content">
                  <img className="movie-image" src={v.movieImage} />
                  <div className="desc">
                    <div className="name">{v.movieName}</div>
                    <div className="time">{v.sesionTime}</div>
                    <div className="cinema">
                      <span>{v.cinemaName}</span>
                      {v.numberOfSeats ? ` - ${v.numberOfSeats} x Seat${v.numberOfSeats > 1 ? 's' : ''}` : ''}
                    </div>
                  </div>
                </div>
                <div className="movie-arrow">
                  <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'arrow_right_grey.svg'} onClick={() => showDetails(v)} />
                </div>
              </div>
            </div>
          ))

          : <div className="no-tickets">
            <p>No Tickets Available</p>
          </div>}
      </Slick>
    </div>
};

export default TicketList;
