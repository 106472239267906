import React, { FC, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Slider from "../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../constants/url";
import { RootState } from "../../../store";
import { getSlidesWatcher, setDateObject } from "../../../store/application/action";
import "./movies.scss";
import { ROUTES } from "../../../constants/path";
import { COUNTRY } from "../../../constants/app";

const LINK = {
  nowShowing: `/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`,
  kidsNowShowing: `/${ROUTES.MOVIES}/${ROUTES.KIDS_NOW_SHOWING}`,
  comingSoon: `/${ROUTES.MOVIES}/${ROUTES.COMING_SOON}`,
  kidsComingSoon: `/${ROUTES.MOVIES}/${ROUTES.KIDS_COMING_SOON}`,
  kidsActivities: `/${ROUTES.MOVIES}/${ROUTES.KIDS_ACTIVITIES}`,
  specialEvents: `/${ROUTES.MOVIES}/${ROUTES.SPECIAL_EVENTS}`,
  foodAndBeverage: `/${ROUTES.FOOD_AND_BEVERAGE}`,
};
interface IMoviesProps {
  children: FC<any>;
  to: string;
}

const Movies: FC<IMoviesProps> = ({ to }) => {

  const { pathname } = useLocation();

  const { countryId, slides, cinemas, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      slides: state.applicationReducer.slides,
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );

  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState<string[]>([]);

  useEffect(() => {
    if (countryId) {
      const kids = [LINK.kidsNowShowing, LINK.kidsComingSoon, LINK.kidsActivities].includes(pathname);
      switch (countryId) {
        case COUNTRY.AUS:
          if (kids) {
            setShowMenu(["kidsNowShowing", "kidsComingSoon", "kidsActivities"])
          } else {
            setShowMenu(["nowShowing", "comingSoon", "foodAndBeverage"]);
          }
          break;
        case COUNTRY.NZ:
          if (kids) {
            setShowMenu(["kidsNowShowing", "kidsComingSoon", "kidsActivities"])
          } else {
            setShowMenu(["nowShowing", "comingSoon", "foodAndBeverage"]);
          }
          break;
        case COUNTRY.ANG:
          setShowMenu(["nowShowing", "comingSoon", "specialEvents"]);
          break;
        case COUNTRY.STA:
          setShowMenu(["nowShowing", "comingSoon", "specialEvents"]);
          break;
      }
    }
  }, [countryId, pathname]);

  useEffect(() => {
    if (countryId) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.MOVIES,
        })
      );
    }
  }, [countryId]);

  const clearDateObject = () => {
    dispatch(setDateObject(null));
  }

  return (
    <div>
      <Slider data={slides} />
      <div className="now-showing-tab">
        <div className="stickytabmenu">
          <div className="container">
            <ul className="nav">
              {showMenu.includes("nowShowing") && (
                <li className="nav-item">
                  <Link
                    onClick={clearDateObject}
                    to={LINK.nowShowing}
                    className={`nav-link ${pathname === LINK.nowShowing ? "active" : ""
                      }`}
                  >
                    NOW SHOWING
                  </Link>
                </li>
              )}
              {showMenu.includes("kidsNowShowing") && (
                <li className="nav-item">
                  <Link
                    to={LINK.kidsNowShowing}
                    className={`nav-link ${pathname === LINK.kidsNowShowing ? "active" : ""
                      }`}
                  >
                    NOW SHOWING
                  </Link>
                </li>
              )}
              {showMenu.includes("comingSoon") && (
                <li className="nav-item">
                  <Link
                    to={LINK.comingSoon}
                    className={`nav-link ${pathname === LINK.comingSoon ? "active" : ""
                      }`}
                  >
                    COMING SOON
                  </Link>
                </li>
              )}
              {showMenu.includes("kidsComingSoon") && (
                <li className="nav-item">
                  <Link
                    to={LINK.kidsComingSoon}
                    className={`nav-link ${pathname === LINK.kidsComingSoon ? "active" : ""
                      }`}
                  >
                    COMING SOON
                  </Link>
                </li>
              )}
              {showMenu.includes("specialEvents") && (
                <li className="nav-item">
                  <Link
                    to={LINK.specialEvents}
                    className={`nav-link ${pathname === LINK.specialEvents ? "active" : ""
                      }`}
                  >
                    SPECIAL EVENTS
                  </Link>
                </li>
              )}
              {showMenu.includes("foodAndBeverage") && (
                <li className="nav-item">
                  <Link
                    to={LINK.foodAndBeverage.replace(':cinemaId', currentCinema?.alias)}
                    className={`nav-link ${pathname === LINK.foodAndBeverage ? "active" : ""
                      }`}
                  >
                    FOOD & BEVERAGE
                  </Link>
                </li>
              )}
              {showMenu.includes("kidsActivities") && (
                <li className="nav-item">
                  <Link
                    to={LINK.kidsActivities}
                    className={`nav-link ${pathname === LINK.kidsActivities ? "active" : ""
                      }`}
                  >
                    ACTIVITIES
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Movies;
