import axios from 'axios'
import { getSettingsApi } from '../services/application'
import { Store } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { getSettingsSuccess } from '../store/application/action'

let isRefreshing = false
let failedQueue: any = []

const processQueue = (error: any) => {
  failedQueue.forEach((req: any) => {
    error ? req.reject(error) : req.resolve()
  })
  failedQueue = []
}

export const refreshTokenWithQueue = (originalRequest: any, authCallbacks: any, store: Store) => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject })
    })
      .then(() => {
        return axios(originalRequest)
      })
      .catch((err) => {
        return err
      })
  }
  originalRequest._retry = true
  isRefreshing = true

  return new Promise(async (resolve, reject) => {
    const state: RootState = store.getState();
    const countryId = state.applicationReducer.countryId;
    const { response: { data } } = await getSettingsApi({ countryId }) as any;
    if (data) {
      store.dispatch(getSettingsSuccess(data.data.settings));
      axios(originalRequest).then(resolve, reject);
      processQueue(null);
    }
    isRefreshing = false
  })
}
