import React, { FC, useState } from "react";
import { IDeal } from "../../../../models/sidemenu";
import HtmlRender from "../../../UI/HtmlRender";
import "./deal.scss";
import { URLS } from "../../../../constants/url";

interface IDealProps {
  data: IDeal;
}

const Deal: FC<IDealProps> = ({ data }) => {
  const errorHandler = (event: any) => {
    event.target.src = URLS.ERROR_IMAGE;
  };
  return (
    <div className="deals list-content">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="media">
              <div className="list_poster">
                <img
                  className="align-self-start"
                  src={data.deal_image}
                  alt={data.title}
                  title={data.title}
                  onError={errorHandler}
                />
              </div>
              <div className="media-body ">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="deal-head">{data.title}</h3>
                    <HtmlRender
                      {...{ className: "description" }}
                      content={data.description}
                    />
                    <HtmlRender
                      {...{ className: "description" }}
                      content={data.content}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deal;
