import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setPopupInfo } from "../../store/application/action";
import './info.scss';

interface IInfoPopup {}

const InfoPopup: FC<IInfoPopup> = () => {
  const { infoPopup } = useSelector((state: RootState) => ({
    infoPopup: state.applicationReducer.infoPopup,
  }));
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    setOpen(infoPopup.open);
  }, [infoPopup.open]);

  const onClose = () => {
    dispatch(setPopupInfo({ open: false, type: "" }));
  };

  const Content = useMemo(() => {
    switch (infoPopup.type) {
      case "COVID-19":
        setTitle("COVID-19 Social Distancing");
        return (
          <div className="covid_annoc">
            <p>Your health and safety is our highest priority.</p>
            <p>
              Due to the current government health recommendations we have
              decided to reserve this seat as a safety precaution and to
              encourage social distancing in our cinemas.
            </p>
          </div>
        );
      case "Special":
        setTitle("Information");
        return (
          <div className="covid_annoc">
            <p>
              You have selected a wheelchair space. Please note, there is no
              physical cinema seat in this location
            </p>
          </div>
        );
      case "privacyPolicy":
        setTitle("Privacy Policy");
        return null;
      case "ticketSaleTC":
        setTitle("Terms");
        return null;
      case "etixTerms":
        setTitle("e-Tix Terms and conditions");
        return null;
    }
  }, [infoPopup.type]);

  return (
    <Modal visible={open} className="announcement-popup" showCloseBtn={false}>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <button className="close" type="button" onClick={onClose}>
              <span className="close_icon"></span>
            </button>
            <div className="modal-header ng-star-inserted">
              <div className="modal-title">
                <div className="mobile_header">
                  {title ? <div className="title">{title}</div> : null}
                </div>
              </div>
            </div>
            <div className="modal-body">{Content}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InfoPopup;
