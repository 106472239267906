import React,{ FC } from "react";

const TextInput: FC<any> = ({
  field,
  touched,
  error,
  onValueChange,
  setFieldValue,
  value,
  handleChange,
  ...props
}) => {
  return (
    <>
      <input type="text" 
        className={`form-control ${touched && error?'is-invalid':''}`}
        onKeyUp={({ target: { value } }: any) => onValueChange && onValueChange(value)}
        value={value}
        onChange={handleChange && handleChange(field)}
        {...props}
      />
      {touched && error ? <div className="error_message"><span>{error}</span></div> : null}
    </>
  );
};

export default TextInput;