import React,{ FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IAnnouncementProps {}

const Announcement: FC<IAnnouncementProps> = () => {
  const { settings, currentCinema } = useSelector((state: RootState) => ({
    settings: state.applicationReducer.settings,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const [isBookable, setIsBookable] = useState<number>(1);

  useEffect(() => {
    if (currentCinema && Object.keys(currentCinema).length > 0) {
      setIsBookable(
        currentCinema.hasOwnProperty("etix") ? currentCinema["etix"] : 0
      );
    }
  }, [currentCinema]);

  return (
    <div>
      <div className="container">
        <div className="row">
          {settings.enableTicketBooking == 0 ? (
            <div className="col-12 bookingnotallowed">
              Online ticket booking not available
            </div>
          ) : null}
        </div>
      </div>
      {isBookable != 1 ? (
        <div className="bookingnotallowed_wrap bg_black">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="etix_status">
                  <h6>E-Tix is currently not available for this cinema location.</h6>
                  {/* <p>
                    We're currently closed in light of the ongoing COVID-19
                    pandemic.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Announcement;
