import moment from "moment";
import React,{ FC } from "react";
import HtmlRender from "../../../UI/HtmlRender";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IComingSoonSectionProps {
  data: any;
}

const ComingSoonSection: FC<IComingSoonSectionProps> = ({ data }) => {

  const { currentCinema } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const navigate = useNavigate();

  const onClickMovieDetails = (data: any) => {
      const { slug } = data;
      navigate(`/movies/details/${currentCinema?.slug}/${slug}`);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="media">
            <div
              className="list_poster"
              onClick={() => onClickMovieDetails(data)}
            >
              <img
                className="align-self-start"
                src={data.images[0].large}
                alt={data.movie}
                title={data.movie}
              />
            </div>
            <div className="media-body display-flex">
              <div
                className="pull-left"
                // [ngClass]="{ 'col-md-4 col-12': getTabactive == true }"
              >
                <h5
                  className="mt-0 title"
                  onClick={() => onClickMovieDetails(data)}
                >
                  {data.movie}
                </h5>
                <h5 className="disp-date">{data.release_date?moment(data.release_date).format('DD MMM'):''}</h5>
                {data.synopsis ? (
                  <div className="description">
                    <HtmlRender content={data.synopsis} sliceLength={300} />
                  </div>
                ) : (
                  <p className="description">No Description</p>
                )}

                <div className="rating_wrap">
                  <div className="icon">
                    <span className="certification_icon">
                      <img
                        className="align-self-start"
                        src={data.ratingIcon}
                        alt={data.ratingName}
                        data-tooltip-id="rating-explanation" data-tooltip-html={data.ratingExplanation}
                      />
                       <Tooltip id="rating-explanation" className="tool-tip" />
                    </span>
                  </div>
                  <HtmlRender {...{className:'rating_title'}} content={data.ratingName} />
                </div>
                <div>
                  <div className="accordion1" id="commingSoonAcc">
                    <div className="card1">
                      <div className="card-header1" id="details"></div>
                      <div
                        id="collapseOne{{ i }}"
                        className="collapse "
                        aria-labelledby="details"
                        data-parent="#commingSoonAcc"
                      >
                        <div className="acc-content">
                          <span className="h6">Movie Name - </span>
                          {data.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonSection;
