import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../../constants/path";

interface IGroupSaleProps {}

const GroupSale: FC<IGroupSaleProps> = () => {
  const { type } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setContentModal({ type: ROUTES.GROUP_SALE }));
    dispatch(toggleContentModal(true));
    navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
  }, [type]);

  return null;
};

export default GroupSale;
