
import { COUNTRY } from "./app"

export const MEMBERSHIP_TAB: any = {
    [COUNTRY.STA]: ['BASIC', 'BOOST'],
    [COUNTRY.ANG]: ['CLUB', 'GOLD'],
    [COUNTRY.AUS]: ['BASIC', 'BOOST'],
    [COUNTRY.NZ]: ['BASIC', 'BOOST']
}

export const MEMBERSHIP_CONTENT: any = {
    [COUNTRY.STA]: {
        BASIC: {
            membershipLabel: "BASIC",
            logo: "state_rewards_base.svg",
            membershipLogo: "state_rewards_base.svg",
            membershipHeader: "State Cinema Rewards perks",
            membershipLogoDesc: "Join today at no cost and start earning points immediately with every ticket and candy bar purchase online and at the cinema.",
            rewards: [
                { header: "Earn 10 points per dollar", text: "Earn points on every dollar you spend on tickets, snacks, and drinks, and redeem them for exciting rewards!" },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" }
            ],
            type: 'basic'
        },
        BOOST: {
            membershipLabel: "BOOST",
            logo: "state_rewards_boost.svg",
            membershipLogo: "state_rewards_boost.svg",
            membershipHeader: "State Cinema Rewards Boost perks",
            membershipLogoDesc: "Upgrade your Rewards Membership to the Boost tier and supercharge your benefits! Get discounts on tickets and at the Candy Bar and enjoy even faster points accumulation and extra perks that make every visit special.",
            rewards: [
                { header: "15% off tickets", text: "Get 15% off your ticket every time you visit!" },
                { header: "10% off Candy Bar purchases", text: "Enjoy 10% off your food and drink purchases online or at the cinema." },
                { header: "Earn 20 points per dollar", text: "Earn points on every dollar you spend on tickets, snacks, and drinks, and redeem them for exciting rewards!" },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" }
            ],
            type: 'boost'
        }
    },
    [COUNTRY.ANG]: {
        CLUB: {
            membershipLabel: "CLUB",
            logo: "angelika_rewards_club.svg",
            membershipLogo: "ang_club_logo_head.svg",
            membershipLogoDesc: "Join today at no cost and start earning points immediately with every ticket and candy bar purchase online and at the cinema.",
            membershipHeader: "",
            rewards: [
                { header: "Earn 10 points per dollar", text: "Bit on info about points and redemptions." },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" },
            ],
            type: 'club'
        },
        GOLD: {
            membershipLabel: "GOLD",
            logo: "angelika_rewards_gold.svg",
            membershipLogo: "ang_gold_logo_head.svg",
            membershipLogoDesc: "Upgrade your Rewards Membership to the Gold tier and supercharge your benefits! Get discounts on tickets and at the Candy Bar and enjoy even faster points accumulation and extra perks that make every visit special.",
            rewards: [
                { header: "20% off adult tickets", text: "Get 20% off your ticket every time you visit!" },
                { header: "10% off Candy Bar purchases", text: "Enjoy 10% off your food and drink purchases online or at the cinema." },
                { header: "Earn 20 points per dollar", text: "Bit on info about points and redemptions." },
                // { header: "Free popcorn refills", text: "Show your Boost membership at the candy bar and get free same-day popcorn refills!" },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" },
            ],
            type: 'gold'
        }
    },
    [COUNTRY.AUS]: {
        BASIC: {
            membershipLabel: "BASIC",
            logo: "reading_rewards_base_white.png",
            membershipLogo: "reading_rewards_base.png",
            membershipHeader: "Reading Rewards perks",
            membershipLogoDesc: "Join today at no cost and start earning points immediately with every ticket and candy bar purchase online and at the cinema.",
            rewards: [
                { header: "Earn 10 points per dollar", text: "Earn points on every dollar you spend on tickets, snacks, and drinks, and redeem them for exciting rewards!" },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" }
            ],
            type: 'basic'
        },
        BOOST: {
            membershipLabel: "BOOST",
            logo: "reading_rewards_boost_white.png",
            membershipLogo: "reading_rewards_boost.png",
            membershipHeader: "Reading Rewards Boost perks",
            membershipLogoDesc: "Upgrade your Rewards Membership to the Boost tier and supercharge your benefits! Get discounts on tickets and at the Candy Bar and enjoy even faster points accumulation and extra perks that make every visit special.",
            rewards: [
                { header: "15% off tickets", text: "Get 15% off your ticket every time you visit!" },
                { header: "10% off Candy Bar purchases", text: "Enjoy 10% off your food and drink purchases online or at the cinema." },
                { header: "Earn 20 points per dollar", text: "Earn points on every dollar you spend on tickets, snacks, and drinks, and redeem them for exciting rewards!" },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" }
            ],
            type: 'boost'
        }
    },
    [COUNTRY.NZ]: {
        BASIC: {
            membershipLabel: "BASIC",
            logo: "reading_rewards_base_white.png",
            membershipLogo: "reading_rewards_base.png",
            membershipHeader: "Reading Rewards perks",
            membershipLogoDesc: "Join today at no cost and start earning points immediately with every ticket and candy bar purchase online and at the cinema.",
            rewards: [
                { header: "Earn 10 points per dollar", text: "Earn points on every dollar you spend on tickets, snacks, and drinks, and redeem them for exciting rewards!" },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" }
            ],
            type: 'basic'
        },
        BOOST: {
            membershipLabel: "BOOST",
            logo: "reading_rewards_boost_white.png",
            membershipLogo: "reading_rewards_boost.png",
            membershipHeader: "Reading Rewards Boost perks",
            membershipLogoDesc: "Upgrade your Rewards Membership to the Boost tier and supercharge your benefits! Get discounts on tickets and at the Candy Bar and enjoy even faster points accumulation and extra perks that make every visit special.",
            rewards: [
                { header: "15% off tickets", text: "Get 15% off your ticket every time you visit!" },
                { header: "10% off Candy Bar purchases", text: "Enjoy 10% off your food and drink purchases online or at the cinema." },
                { header: "Earn 20 points per dollar", text: "Earn points on every dollar you spend on tickets, snacks, and drinks, and redeem them for exciting rewards!" },
                { header: "Exclusive member competitions", text: "Unlock the chance to win exciting prizes, movie tickets, and exclusive experiences only available to our members." },
                { header: "Special member only screenings", text: "Join us for exclusive member-only screenings of the latest blockbusters!" }
            ],
            type: 'boost'
        }
    }
}

export const ALREADY_MEMBER_CONTENT: any = {
    [COUNTRY.STA]: {
        header: "Already a State Rewards Member?",
        desc: "No worries! Your State Rewards membership has automatically been converted to Angelika Rewards. As a bonus, when you swap to Angelika Rewards, we’ll fast track your 8 visits and sort you a free ticket regardless."
    },
    [COUNTRY.ANG]: {
        header: "",
        desc: ""
    },
    [COUNTRY.AUS]: {
        header: "",
        desc: "",
    },
}

export const QUESTIONS: any = {
    [COUNTRY.STA]: [
        {
            sectionName:
                "Changeover",
            faqs: [
                { Title: "What Happened to the State Membership?", Description: "The State Membership is being retired to make way for a brand-new loyalty program at The State Cinema called Angelika Rewards!" },
                { Title: "I was only a couple of visits from a free ticket. What happens now?", Description: "To kick off our new membership program, we’re giving all our current paid State Members a free ticket when they swap to Angelika Rewards!" },
                { Title: "What’s different about Angelika Rewards?", Description: "The old State Members program was very basic, rewarding you with a complimentary ticket after a set number of visits. Angelika Rewards, on the other hand, lets you earn points for every dollar spent on tickets, food, and drinks at the State Cinema. You can redeem these points for discounted or free tickets, café items, snacks, and drinks, offering more flexibility and value." },
                { Title: "Something has gone wrong with the changeover. Who do I contact?", Description: `Send us an email at <a href="mailto:rewards@statecinema.com.au">rewards@statecinema.com.au</a> and we’ll be happy to take a look at what’s going on.` }
            ]
        },
        {
            sectionName:
                "Angelika Rewards",
            faqs: [
                { Title: "What is Angelika Rewards?", Description: "Angelika Rewards is a great new loyalty program at The State Cinema! You earn points for every dollar spent and then cash those points in for great rewards." },
                { Title: "What is Angelika Rewards Boost?", Description: "Angelika Rewards Boost is the VIP tier of Angelika Rewards.  Boost members get 15% off movie tickets (one discounted ticket per transaction), 10% off Candy Bar purchases and double Rewards points (20 per dollar) plus some other great benefits exclusive to our Angelika Rewards Boost members for just $20 a year! " },
                { Title: "How do I sign up?", Description: `You can join Angelika Rewards for free or upgrade to Angelika Rewards Boost online by clicking <a href="https://statecinema.com.au/memberships">here</a>.` },
                { Title: "How do I earn Points?", Description: "Points are earned on every dollar you spend online or in person at The State Cinema (excluding on gifts or booking fee). If you are a free Angelika Rewards member, you’ll earn 10 points per dollar, and for our Angelika Rewards Boost members, you’ll earn 20 points per dollar." },
                { Title: "What rewards can I get?", Description: `There are plenty of great rewards up for grabs, from free Candy Bar items to discounted and free movie tickets! The best thing about Angelika Rewards is you get to decide what to spend your points on! Currently, you can cash your points in for the following rewards:
                    <br/><br/>1000 points = Free Coffee
                    <br/>1500 points = $15 Ticket
                    <br/>2000 points = Free Choc top                        
                    <br/>2000 points = Wine Pour Upgrade
                    <br/>2500 points = House Wine or Beer                  
                    <br/>3000 points = $10 Ticket                                 
                    <br/>3500 points = Coffee and Slice
                    <br/>4000 points = Coffee and Cake
                    <br/>5000 points = Free Ticket 
                    <br/><br/>
                    View the Rewards page in your account to see what’s available or click <a href="https://statecinema.com.au/rewards/summary">here</a>.`
                },
                { Title: "Do my points expire?", Description: "Your Angelika Rewards points will not expire if the account is active and has had a transaction made in the last 12 months." },
                { Title: "Can I earn points when buying Gift Cards?", Description: "You cannot earn points when purchasing a gift card, however, points can be earned when redeeming the value of a gift card." },
                { Title: "I need to change my personal details", Description: "You can log in to your account on our website. In your membership profile, you will see a section that states ‘My Account’. Hit the Edit button and you will be able to update your details there." },
                { Title: "I forgot to log in or swipe my card at the cinema to earn points. Can I add them after the transaction?", Description: `If you forget to use your loyalty card, you can contact the cinema directly or email <a href="mailto:rewards@statecinema.com.au">rewards@statecinema.com.au</a> with proof of purchase to add on your points manually.` },
                { Title: "Who do I contact if I have issues with my Angelika Rewards membership?", Description: `If you have any issues or questions that are not listed above, please feel free to email <a href="mailto:rewards@statecinema.com.au">rewards@statecinema.com.au</a> and our friendly team will be able to assist you` },
                { Title: "How do I cancel my membership?", Description: `If you need to cancel your membership for any reason, please email <a href="mailto:rewards@statecinema.com.au">rewards@statecinema.com.au</a> and our friendly team will be able to assist you. Please note the Angelika Rewards Plus membership fee is non-refundable.` },
            ]
        }
    ],
    [COUNTRY.ANG]: [
        {
            sectionName:
                "",
            faqs: [
                { Title: "What is Angelika Rewards?", Description: "Angelika Rewards is the loyalty program offered at Angelika Cinemas! You earn points for every dollar spent and then cash those points in for great rewards." },
                { Title: "What is Angelika Rewards Gold?", Description: "Angelika Rewards Gold is the VIP tier of Angelika Rewards.  Gold members get 20% off Adult movie tickets (two discounted tickets per transaction), 10% off Candy Bar purchases and double Rewards points (20 per dollar) plus some other great benefits exclusive to our Angelika Rewards Gold members for just $20 a year! " },
                { Title: "How do I sign up?", Description: "You can join Angelika Rewards for free or upgrade to Angelika Rewards Gold online by clicking here." },
                { Title: "How do I earn Points?", Description: "Points are earned on every dollar you spend online or in person at Angelika Cinemas (excluding on gifts or booking fee). If you are a free Angelika Rewards member, you’ll earn 10 points per dollar, and for our Angelika Rewards Gold members, you’ll earn 20 points per dollar." },
                { Title: "What rewards can I get?", Description: `There are plenty of great rewards up for grabs, from free Candy Bar items to discounted and free movie tickets! The best thing about Angelika Rewards is you get to decide what to spend your points on! Currently, you can cash your points in for the following rewards:
                    <br/><br/>1000 points = Free Medium Drink
                    <br/>1000 points = Free Medium Popcorn
                    <br/>1500 points = Free Choc top                                         
                    <br/>1500 points = Free Coffee
                    <br/>2000 points = Free Large Popcorn   
                    <br/>2000 points = $10 Ticket                                
                    <br/>2500 points = Medium Combo                  
                    <br/>3000 points = Free House Wine or Beer                                 
                    <br/>3000 points = Free Large Combo
                    <br/>3000 points = SoHo Lounge Upgrade
                    <br/>3500 points = Free Premium Ticket        
                    <br/>4000 points = Free Couples Combo 
                    <br/>5000 points = Free Ticket
                    <br/><br/>
                    View the Rewards page in your account to see what’s available or click here.`
                },
                { Title: "Do my points expire?", Description: "Your Angelika Rewards points will not expire if the account is active and has had a transaction made in the last 12 months." },
                { Title: "Can I earn points when buying Gift Cards?", Description: "You cannot earn points when purchasing a gift card, however, points can be earned when redeeming the value of a gift card." },
                { Title: "I need to change my personal details", Description: "You can log in to your account on our website. In your membership profile, you will see a section that states ‘My Account’. Hit the Edit button and you will be able to update your details there." },
                { Title: "I forgot to log in or swipe my card at the cinema to earn points. Can I add them after the transaction?", Description: "If you forget to use your loyalty card, you can contact the cinema directly or email rewards@angelikacinemas.com.au with proof of purchase to add on your points manually." },
                { Title: "Who do I contact if I have issues with my Angelika Rewards membership?", Description: "If you have any issues or questions that are not listed above, please feel free to email rewards@angelikacinemas.com.au and our friendly team will be able to assist you. " },
                { Title: "How do I cancel my membership?", Description: "If you need to cancel your membership for any reason, please email rewards@angelikacinemas.com.au and our friendly team will be able to assist you. Please note the Angelika Rewards Plus membership fee is non-refundable." },
            ]
        },
    ],
    [COUNTRY.AUS]: [
        {
            sectionName:
                "Changeover",
            faqs: [
                { Title: "What happened to the Reel Club?", Description: "The Reel Club has been retired to make way for Reading Rewards, a brand-new loyalty program at Reading Cinemas!" },
                { Title: "I had rewards in my Reel Club account. What happens to them?", Description: "Your rewards have been converted into their equivalent point value and deposited into your Reading Rewards account. For example, if you had 2 Free Choc Tops in your Reel Club account, you’ll now have 3,000 points (1,500 points per Choc Top) in Reading Rewards. You can redeem these points for 2 Choc Tops or use them for other rewards, like a Medium Popcorn (1,000 points)." },
                { Title: "What’s different about Reading Rewards?", Description: "Reel Club rewarded you with set rewards at fixed intervals, but Reading Rewards allows you to earn points for every dollar spent on tickets, food, and drinks. These points can be redeemed for a variety of rewards, offering more flexibility and value." },
                { Title: "Something went wrong during the changeover. Who do I contact?", Description: `Email us at <a href="mailto:rewards@readingcinemas.com.au">rewards@readingcinemas.com.au</a> and we’ll help resolve the issue.` }
            ]
        },
        {
            sectionName:
                "Reading Rewards",
            faqs: [
                { Title: "What is Reading Rewards?", Description: "Reading Rewards is our new loyalty program where you earn points for every dollar spent. Points can be redeemed for rewards like discounted or free tickets, snacks, and drinks." },
                { Title: "What is Reading Rewards Boost?", Description: `Boost is the VIP tier of Reading Rewards. For $20/year, Boost members enjoy: <ul>
                    <li>15% off one movie ticket per transaction</li>
                    <li>10% off Candy Bar purchases</li>
                    <li>Double points (20 points per dollar)</li>
                    <li>Free Popcorn Refills</li>
                    <li>Plus other exclusive benefits!</li>
                    </ul>` },
                { Title: "How do I sign up?", Description: `Join Reading Rewards for free or upgrade to Boost online <a href="https://readingcinemas.com.au/memberships">here</a>.` },
                { Title: "How do I earn points?", Description: "Points are earned on every dollar spent at Reading Cinemas, online or in person (excluding gift cards and booking fees). Free members earn 10 points per dollar, and Boost members earn 20 points per dollar." },
                { Title: "What rewards can I redeem?", Description: `You can redeem points for a wide range of rewards, including:
                    <br/><br/>500 points = Combo Upgrade
                    <br/>1000 points = Regular Popcorn
                    <br/>1000 points = Regular Drink                        
                    <br/>1500 points = Regular Coffee
                    <br/>1500 points = Choc Top                  
                    <br/>2000 points = Wine Pour Upgrade                                
                    <br/>2000 points = $10 Ticket
                    <br/>2000 points = Large Popcorn
                    <br/>2500 points = Regular Combo
                    <br/>3000 points = Gold Lounge Upgrade (Available at select sites) 
                    <br/>3000 points = House Wine or Beer (Available at select sites) 
                    <br/>3000 points = Large Combo
                    <br/>3500 points = Free Standard Ticket
                    <br/>4000 points = Couples Combo
                    <br/>4500 points = Free Titan Luxe Ticket (Available at select sites)
                    <br/>5000 points =  Gold Lounge Ticket (Available at select sites)
                    <br/><br/>
                    These rewards are subject to change at any time.`
                },
                { Title: "Do my points expire?", Description: "Points do not expire as long as your account remains active with a transaction in the past 12 months." },
                { Title: "Can I earn points on gift card purchases?", Description: "Points are not earned when purchasing a gift card but can be earned when using the gift card." },
                { Title: "How do I update my personal details?", Description: `Log in to your account on our website, go to "My Account," and select "Edit" to update your information.` },
                { Title: "I forgot to use my card at the cinema. Can I add points afterward?", Description: `Yes, just contact the cinema or email us at <a href="mailto:rewards@readingcinemas.com.au">rewards@readingcinemas.com.au</a> with proof of purchase to manually add your points.` },
                { Title: "Who do I contact for issues with my membership?", Description: `For any questions or concerns, email <a href="mailto:rewards@readingcinemas.com.au">rewards@readingcinemas.com.au</a>, and our team will assist you.` },
                { Title: "How do I cancel my membership?", Description: `If you wish to cancel your membership, email <a href="mailto:rewards@readingcinemas.com.au">rewards@readingcinemas.com.au</a>. Please note that the $20 Boost membership fee is non-refundable.` },
            ]
        }
    ],
    [COUNTRY.NZ]: [
        {
            sectionName:
                "Changeover",
            faqs: [
                { Title: "What happened to the Reel Club?", Description: "The Reel Club has been retired to make way for Reading Rewards, a brand-new loyalty program at Reading Cinemas!" },
                { Title: "I had rewards in my Reel Club account. What happens to them?", Description: "Your rewards have been converted into their equivalent point value and deposited into your Reading Rewards account. For example, if you had 2 Free Choc Tops in your Reel Club account, you’ll now have 3,000 points (1,500 points per Choc Top) in Reading Rewards. You can redeem these points for 2 Choc Tops or use them for other rewards, like a Medium Popcorn (1,000 points)." },
                { Title: "What’s different about Reading Rewards?", Description: "Reel Club rewarded you with set rewards at fixed intervals, but Reading Rewards allows you to earn points for every dollar spent on tickets, food, and drinks. These points can be redeemed for a variety of rewards, offering more flexibility and value." },
                { Title: "Something went wrong during the changeover. Who do I contact?", Description: `Email us at <a href="mailto:rewards@readingcinemas.co.nz">rewards@readingcinemas.co.nz</a> and we’ll help resolve the issue.` }
            ]
        },
        {
            sectionName:
                "Reading Rewards",
            faqs: [
                { Title: "What is Reading Rewards?", Description: "Reading Rewards is our new loyalty program where you earn points for every dollar spent. Points can be redeemed for rewards like discounted or free tickets, snacks, and drinks." },
                { Title: "What is Reading Rewards Boost?", Description: `Boost is the VIP tier of Reading Rewards. For $20/year, Boost members enjoy: <ul>
                    <li>15% off one movie ticket per transaction</li>
                    <li>10% off Candy Bar purchases</li>
                    <li>Double points (20 points per dollar)</li>
                    <li>Free Popcorn Refills</li>
                    <li>Plus other exclusive benefits!</li>
                    </ul>` },
                { Title: "How do I sign up?", Description: `Join Reading Rewards for free or upgrade to Boost online <a href="https://readingcinemas.co.nz/memberships">here</a>.` },
                { Title: "How do I earn points?", Description: "Points are earned on every dollar spent at Reading Cinemas, online or in person (excluding gift cards and booking fees). Free members earn 10 points per dollar, and Boost members earn 20 points per dollar." },
                { Title: "What rewards can I redeem?", Description: `You can redeem points for a wide range of rewards, including:
                    <br/><br/>500 points = Combo Upgrade
                    <br/>1000 points = Regular Popcorn
                    <br/>1000 points = Regular Drink                        
                    <br/>1500 points = Regular Coffee
                    <br/>1500 points = Choc Top                  
                    <br/>2000 points = Wine Pour Upgrade                                
                    <br/>2000 points = $10 Ticket
                    <br/>2000 points = Large Popcorn
                    <br/>2500 points = Regular Combo
                    <br/>3000 points = Gold Lounge Upgrade (Available at select sites) 
                    <br/>3000 points = House Wine or Beer (Available at select sites) 
                    <br/>3000 points = Large Combo
                    <br/>3500 points = Free Standard Ticket
                    <br/>4000 points = Couples Combo
                    <br/>4500 points = Free Titan Luxe Ticket (Available at select sites)
                    <br/>5000 points =  Gold Lounge Ticket (Available at select sites)
                    <br/><br/>
                    These rewards are subject to change at any time.`
                },
                { Title: "Do my points expire?", Description: "Points do not expire as long as your account remains active with a transaction in the past 12 months." },
                { Title: "Can I earn points on gift card purchases?", Description: "Points are not earned when purchasing a gift card but can be earned when using the gift card." },
                { Title: "How do I update my personal details?", Description: `Log in to your account on our website, go to "My Account," and select "Edit" to update your information.` },
                { Title: "I forgot to use my card at the cinema. Can I add points afterward?", Description: `Yes, just contact the cinema or email us at <a href="mailto:rewards@readingcinemas.co.nz">rewards@readingcinemas.co.nz</a> with proof of purchase to manually add your points.` },
                { Title: "Who do I contact for issues with my membership?", Description: `For any questions or concerns, email <a href="mailto:rewards@readingcinemas.co.nz">rewards@readingcinemas.co.nz</a>, and our team will assist you.` },
                { Title: "How do I cancel my membership?", Description: `If you wish to cancel your membership, email <a href="mailto:rewards@readingcinemas.co.nz">rewards@readingcinemas.co.nz</a>. Please note that the $20 Boost membership fee is non-refundable.` },
            ]
        }
    ]
}