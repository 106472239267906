import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";
import { useParams } from "react-router";
import { resetConfirmationApi } from "../../../../services/auth";
import { RootState } from "../../../../store";

interface IResetPasswordProps {}

const ResetPassword: FC<IResetPasswordProps> = () => {
  const { countryId, data } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    data: state.ticketBookingReducer.modal.data,
  }));
  const dispatch = useDispatch();
  const { code } = useParams();

  const resetConfirmation = async () => {
    const {
      data: { data },
    } = await resetConfirmationApi({ code: code });
    if (data && data.status == "true") {
      dispatch(setContentModal({ type: ROUTES.RESET_PASSWORD, code }));
      dispatch(toggleContentModal(true));
    }
  };

  useEffect(() => {
    if (code && countryId) {
      resetConfirmation();
    }
  }, [code, countryId]);

  return null;
};

export default ResetPassword;
