import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMovieData } from "../../../../models/cinema";
import { RootState } from "../../../../store";
import SessionTimes from "../../../SessionTimes/AU";
import HtmlRender from "../../../UI/HtmlRender";
import Announcement from "../Announcement";
import "./nowShowing.scss";
import { URLS } from "../../../../constants/url";
import { Tooltip } from 'react-tooltip';
interface ISessionProps {
  sessionData: any[];
}

const NowShowingSession: React.FC<ISessionProps> = ({ sessionData }) => {
  const { currentCinema, countryId, ticketType } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      ticketType: state.ticketBookingReducer.ticketType,
    })
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isBookable, setIsBookable] = useState<number>(0);

  useEffect(() => {
    if (currentCinema && Object.keys(currentCinema).length > 0) {
      setIsBookable(
        currentCinema.hasOwnProperty("etix") ? currentCinema["etix"] : 0
      );
    }
  }, [currentCinema]);

  const onClickMovieDetails = (data: IMovieData) => {
    const { slug, theater } = data;
    navigate(`/movies/details/${theater}/${slug}`);
  };

  const errorHandle = (event:any)=>{
    event.target.src = URLS.ERROR_IMAGE
  }

  return (
    <div>
      <Announcement />
      {sessionData.length > 0 ? (
        <div>
          {sessionData.map((contentObj: any, index: number) => (
            <div key={`session_${index}`} className="list-content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <label
                      className="title mobile_title"
                      onClick={() => onClickMovieDetails(contentObj.movieData)}
                    >
                      {contentObj.movieData.movie}
                    </label>
                  </div>
                  <div className="col-md-12 col-12 mobile_view">
                    <div className="media">
                      <div
                        className="list_poster"
                        onClick={() =>
                          onClickMovieDetails(contentObj.movieData)
                        }
                      >
                        <img
                          className="align-self-start"
                          src={contentObj.movieData.images[0].large}
                          alt={contentObj.movieData.movie}
                          title={contentObj.movieData.movie}
                          onError={errorHandle}
                        />
                      </div>
                      <div className="media-body ">
                        <div className="row">
                          <div className="col-md-4 mobile_hide">
                            <div className="">
                              <h5
                                className="mt-0 title"
                                onClick={() =>
                                  onClickMovieDetails(contentObj.movieData)
                                }
                              >
                                {contentObj.movieData.movie}
                              </h5>
                              {contentObj.movieData.synopsis ? (
                                <div className="description">
                                  <HtmlRender
                                    content={contentObj.movieData.synopsis}
                                    sliceLength={300}
                                  />
                                </div>
                              ) : (
                                <p className="description">No Description</p>
                              )}
                              <div className="rating_wrap">
                                <div className="icon">
                                  <span className="certification_icon">
                                    <img
                                      className="align-self-start"
                                      src={contentObj.movieData.ratingIcon}
                                      alt={contentObj.movieData.ratingName}
                                      onError={errorHandle}
                                      data-tooltip-id="rating-explanation" data-tooltip-html={contentObj.movieData.ratingExplanation}
                                    />
                                    <Tooltip id="rating-explanation" className="tool-tip" />
                                  </span>
                                </div>
                                <div className="rating_title">
                                  {contentObj.movieData.ratingName}
                                </div>
                              </div>
                            </div>
                          </div>
                          {contentObj.shows ? (
                            <div className="col-md-12 col-lg-8">
                              <div className="session-times">Session Times</div>
                              <SessionTimes
                                key={`sessionTimes_${index}`}
                                data={contentObj.shows}
                                isBookable={isBookable}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No data found</div>
      )}
    </div>
  );
};
export default NowShowingSession;
