import { AnyAction } from '@reduxjs/toolkit';
import { ITicketBookingState } from './type';
import { ACTIONS } from './type';
import { MODAL_TYPE, TICKET_FLOW_TYPES } from '../../models/tickets';
import { formatTicketConfig } from '../../utils/formatter';

const initialState: ITicketBookingState = {
    modal: {
        header: "",
        prevModal: MODAL_TYPE.DEFAULT,
        modal: MODAL_TYPE.DEFAULT,
        nextModal: MODAL_TYPE.DEFAULT,
        type: TICKET_FLOW_TYPES.DEFAULT,
        data: "",
    },
    openModal: false,
    films: null,
    ticketTypeCount: 0,
    ticketType: [],
    seatLayout: [],
    sharedUserSession: [],
    ticketDetails: null,
    timer: {
        minutes: 0,
        seconds: 0,
    },
    hasGL: 0,
    ticketVoucher: [],
    isFutureFb: false,
    isMailSent: null,
    showCovid: false,
    error: null,
    inProgress: false,
    seatSwapDetails: null,
    canBookFood: 0
}

const ticketBookingReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ACTIONS.GET_TICKET_BOOKING_DETAILS:
            return {
                ...state,
                inProgress: true,
                error: ""
            }
        case ACTIONS.GET_TICKET_BOOKING_DETAILS_SUCCESS:
            return {
                ...state,
                inProgress: false,
                error: null,
                ...formatTicketConfig(action.payload)
            }
        case ACTIONS.GET_TICKET_BOOKING_DETAILS_ERROR:
            return {
                ...state,
                inProgress: false,
                error: action.payload,
                films: null,
                ticketTypeCount: 0,
                ticketType: [],
                seatLayout: [],
                hasGL: 0,
                isFutureFb: false
            }
        case ACTIONS.TOGGLE_MODAL:
            return {
                ...state,
                openModal: action.payload,
                showCovid: false,
                isAlreadyLoggedIn: false,
                modal: !action.payload ? { ...initialState.modal } : state.modal,
                seatSwapDetails: null,
                hasGL: 0,
                ticketDetails: !action.payload
                    ? {...state.ticketDetails,
                        ...{
                            selectedSeats: [],
                            ticketTypes: [],
                            sessionId: "",
                            saleId: "",
                            total: 0,
                            bookingFees: 0,
                            price: 0,
                            bookingId: "",
                        },
                    }
                    : state.ticketDetails,
            };

        case ACTIONS.SET_MODAL:
            return {
                ...state,
                modal: action.payload
            }
        case ACTIONS.SET_TICKET_VOUCHER:
            return {
                ...state,
                ticketVoucher: action.payload
            }
        case ACTIONS.CANCEL_TICKET_SUCCESS:
            return {
                ...state,
                ticketDetails: null
            }
        case ACTIONS.ADD_TICKET_SUCCESS:
            return {
                ...state,
                ticketDetails: action.payload
            }
        case ACTIONS.UPDATE_SESSION_DETAILS:
            return {
                ...state,
                ticketDetails: {
                    ...state.ticketDetails,
                    userSessionId: action.payload,
                    saleId: action.payload,
                },
            }
        case ACTIONS.UPDATE_BOOKING_ID:
            const tktDetails: any = { ...state.ticketDetails };
            if (tktDetails) {
                tktDetails.bookingId = action.payload;
            }
            return {
                ...state,
                ticketDetails: tktDetails,
            };
        case ACTIONS.SEAT_SWAP_DETAILS:
            return {
                ...state,
                seatSwapDetails: action.payload
            }
        case ACTIONS.FUTURE_FB:
            return {
                ...state,
                isFutureFb: action.payload,
            };
        case ACTIONS.PREORDER_CONCESSION:
            return {
                ...state,
                preOrderFb: action.payload,
            };
        case ACTIONS.CAN_BOOK_FOOD:
            return{
                ...state,
                canBookFood: action.payload
            }    
        case ACTIONS.RESET_GL:
            return {
                ...state,
                hasGL: 0
            }      
        default:
            return state
    }


}

export default ticketBookingReducer