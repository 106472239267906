import React,{
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";

interface ISessionTimer {
  startTime: number;
  onEnd: () => void;
  paused: boolean;
  class: string;
}

export interface ISessionRef {
  start: () => void;
  stop: () => void;
  reset: () => void;
  addSeconds: (seconds: number) => void;
}

const SessionTimer = forwardRef(
  (props: ISessionTimer, ref: React.Ref<ISessionRef>) => {
    const timerInterval = useRef<any>(null);
    const [timer, setTimer] = useState(props.startTime);

    useImperativeHandle(ref, () => ({
      start: () => start(),
      stop: () => stop(),
      reset: () => reset(),
      addSeconds: (seconds: number) => addSeconds(seconds),
    }));

    const start = () => {
      timerInterval.current = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    };

    const reset = () => {
      stop();
      setTimer(props.startTime);
      start();
    };

    const stop = () => {
      if (timerInterval && timerInterval.current) {
        clearInterval(timerInterval.current);
      }
    };

    const addSeconds = (seconds: number) => {
      setTimer((timer) => timer + seconds);
    };

    const duration = useMemo(() => {
      const getSeconds = `0${timer % 60}`.slice(-2);
      const minutes = Math.floor(timer / 60);
      const getMinutes = `0${minutes % 60}`.slice(-2);
      if (timer <= 0) {
        toast.error('Session Time Out')
        stop();
        props.onEnd();
        timerInterval.current = null;
      }

      return { minutes: getMinutes, seconds: getSeconds };
    }, [timer]);

    return (
      <div className={`desktop_time ${props.class ? props.class : ""}`}>
        <span className="timecount-label"> Time left to book:</span>
        <span className="timecounter">
          <span className="min-width">{duration.minutes} </span>
          <span>:</span>
          <span className="min-width">{duration.seconds}</span>
        </span>
      </div>
    );
  }
);

export default SessionTimer;
