import React, { FC } from "react";
import "./militaryConfirmation.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IMilitaryConfirmationProps {
  onCloseModal: () => void;
}

const MilitaryConfirmation: FC<IMilitaryConfirmationProps> = ({
  onCloseModal,
}) => {
  const { data } = useSelector((state: RootState) => ({
    data: state.ticketBookingReducer.modal.data,
  }));

  return (
    <div className="military-confirmation-wrapper">
      <div className="col-12">
        <div className="content">
          <p className="header">
           {data.title}
          </p>
          <p className="body">
            {data.description}
          </p>
        </div>
        <div className={`movie_footer_btn_el col-md-12 ml-auto px-5`}>
          <div className="movie_button_wrap justify-content-end">
            <button
              type="button"
              className="btn black_btn"
              onClick={onCloseModal}
            >
              RETURN TO HOME PAGE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilitaryConfirmation;
