import React, { FC, useEffect } from "react";

interface IRefundConfirmationHeaderProps {}

const RefundConfirmationHeader: FC<IRefundConfirmationHeaderProps> = () => {
  return (
    <div className="refund-wrapper">
      <div className="col-12 px-2">
        <div className="refund-header">
          Your refund has been successfully processed.
        </div>
      </div>
    </div>
  );
};

export default RefundConfirmationHeader;
