import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import SessionTimes from "../../../SessionTimes/AU";
import HtmlRender from "../../../UI/HtmlRender";
import Announcement from "../Announcement";
import AdvanceTicketSection from "./AdvanceTicketSection";
import ComingSoonSection from "./ComingSoonSection";
import "./comingSoon.scss";

interface ISessionProps {
  sessionData: any[];
}

const ComingSoonSession: React.FC<ISessionProps> = ({ sessionData }) => {
  const { settings, currentCinema } = useSelector((state: RootState) => ({
    settings: state.applicationReducer.settings,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const [isBookable, setIsBookable] = useState<number>(0);

  useEffect(() => {
    if (currentCinema && Object.keys(currentCinema).length > 0) {
      setIsBookable(
        currentCinema.hasOwnProperty("etix") ? currentCinema["etix"] : 0
      );
    }
  }, [currentCinema]);
  const onClickMovieDetails = (contentObj: any) => {};

  return (
    <div>
      <Announcement />
      <div className="comming_soon_list">
        {sessionData.map((contentObj: any) => (
          <div className="list-content comming-list">
            {!contentObj.movieData ? (
              <ComingSoonSection data={contentObj} />
            ) : (
              <AdvanceTicketSection data={contentObj} isBookable={isBookable}/>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ComingSoonSession;
