import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GiftCardForm from "../../../../components/GiftCard/GiftCardForm";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { GIFT_CARD_ACTION, GIFT_CARD_CATEGORY_TYPE, GIFT_CARD_TYPE } from "../../../../models/giftcard";
import { RootState } from "../../../../store";
import {
  getGiftCardWatcher,
  setGiftShopAction,
} from "../../../../store/giftshop/action";
import "./cards.scss";
import { COUNTRY, GIFT_CARD_CINEMA_ID } from "../../../../constants/app";
import { findGiftPromo } from "../../../../utils/giftshop";
import { ICardCategory } from "../../../../store/giftshop/type";

interface IEcardsProps { }

const Cards: FC<IEcardsProps> = () => {
  const { countryId, currentTab, cards, currentAction, giftFormVisible, giftPromo,
    cardCategory,
    brandId,
    currentTabId,
    currentCinema } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId as COUNTRY,
      currentTab: state.giftCardReducer.currentTab,
      currentTabId: state.giftCardReducer.currentTabId,
      currentAction: state.giftCardReducer.currentAction,
      cards: state.giftCardReducer.cards,
      giftFormVisible: state.giftCardReducer.giftFormVisible,
      giftPromo: state.giftCardReducer.giftPromo,
      cardCategory: state.giftCardReducer.cardCategory,
      brandId: state.applicationReducer.brandId,
      currentCinema: state.applicationReducer.currentCinema,
    }));
  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedCard, setSelectedCard] = useState<any>();

  const getGiftCards = async () => {
    const payload = {
      cinemaId: GIFT_CARD_CINEMA_ID[countryId],
      countryId: countryId,
      requestType: API_REQUEST_TYPE.GIFT_CARDS,
      giftType: currentTab ? currentTab : GIFT_CARD_TYPE.E_GIFT_CARD,
      categoryId: Number(currentTabId),
      type: 'new'
    };
    dispatch(getGiftCardWatcher(payload));
  };

  const getOffer = useCallback(
    (amount: number) => {
      return findGiftPromo(giftPromo, amount, currentTab);
    },
    [giftPromo, currentTab]
  );


  useEffect(() => {
    setCardDetails(cards);
  }, [cards]);

  useEffect(() => {
    if (countryId && currentTabId) {
      getGiftCards();
    }
  }, [countryId, currentTabId]);

  let onClickBuy = (item: any) => {
    dispatch(setGiftShopAction(GIFT_CARD_ACTION.CARD));
    setSelectedCard(item);
  };

  return (
    <>
      {currentAction === GIFT_CARD_ACTION.CARD ? (
        <GiftCardForm card={selectedCard} />
      ) : (
        <div className="row cards-gift-card">
          <div className="col-md-12">
            <div className="giftcard_list_wrap">
              <ul className="e_giftcard_list">
                {cardDetails &&
                  cardDetails.length > 0 &&
                  cardDetails.map((item: any, index: number) => {
                    const promo = getOffer(
                      Number(item.price)
                    );
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          onClickBuy(item);
                        }}
                      >
                        <div className="e_giftcard_item_thumb">
                          {promo ? (
                            <div className="bonus">
                              <p>{promo?.name}</p>
                              <div className="triangle"></div>
                            </div>
                          ) : null}
                          <img
                            src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${item.image}`}
                            alt=""
                            onError={(e: any) =>
                              (e.target.src = URLS.GIFT_CARD_IMAGE)
                            }
                          />
                        </div>
                        <div className="e_giftcard_item_details_wrap">
                          <p className="e_giftcard_item_name">
                            {item.name ? item.name : item.description}
                          </p>
                          <div className="e_giftcard_item_price_sec">
                            <p className="e_giftcard_item_price">
                              $
                              {item.price}
                            </p>
                            <a href="javascript:void(0)" className="white_btn">
                              Buy now
                            </a>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              {cardDetails && cardDetails.length === 0 ? (
                <div className="no-data">
                  <h4>No cards available</h4>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cards;
