import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface iHelmetTiytle {
    title?: string;
    description?: string;
  }
const HelmetTitle: React.FC<iHelmetTiytle> = ({ title, description }) => {
  document.querySelector('meta[name="description"]')?.setAttribute('content', description?description:"sdfsdf");
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetTitle;