import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { GiftType, PROMO_TYPE } from "../../../../models/giftcard";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import "./cart.scss";
import moment from "moment";
import {
  addGiftCart,
  giftCardOrderSuccess,
  setGiftModalVisible,
} from "../../../../store/giftshop/action";
import { ICartData } from "../../../../store/giftshop/type";
import Modal from "../../../../components/UI/Modal";
import GiftCardForm from "../../../../components/GiftCard/GiftCardForm";
import {
  calTotal,
  findGiftPromo,
  formatPromo,
} from "../../../../utils/giftshop";
import {
  BRANDS,
  COUNTRY,
  GIFT_CARD_CINEMA_ID,
  GIFT_CARD_DELIVERY_FEE,
} from "../../../../constants/app";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/path";
import RemoveGiftCard from "../../../../components/GiftCard/RemoveCard";
import { generateUsersessionId } from "../../../../utils/tickets";
import { giftCardOrderApi } from "../../../../services/payment";
import { toast } from "react-toastify";
import {
  setModal,
  toggleTicketModal,
} from "../../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import GiftShopFooter from "../Footer";
import HelmetTitle from "../../../../components/Helmet";


interface ICardProps { }

const Cart: React.FC<ICardProps> = () => {
  const {
    brandId,
    currentTab,
    countryId,
    cart,
    giftModalVisible,
    currentCinema,
    giftPromo,
    settings,
    promoPopupData,
  } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    currentTab: state.giftCardReducer.currentTab,
    currentCinema: state.applicationReducer.currentCinema,
    countryId: state.applicationReducer.countryId as COUNTRY,
    cart: state.giftCardReducer.cart,
    giftModalVisible: state.giftCardReducer.giftModalVisible,
    giftPromo: state.giftCardReducer.giftPromo,
    settings: state.applicationReducer.settings,
    promoPopupData: state.giftCardReducer.promoPopupData,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editCardData, setEditCardData] = useState<any>();
  const [index, setIndex] = useState<number>(0);
  const [action, setAction] = useState("");
  const [cartData, setCartData] = useState<{
    deliveryFee: number;
    deliveryQty: number;
    total: number;
  }>({ deliveryFee: 0, deliveryQty: 0, total: 0 });

  useEffect(() => {
    if (countryId && cart) {
      setCartData(calTotal(cart, countryId));
    }
  }, [cart, countryId]);

  const removeCard = async (index: number) => {
    let cards = cart;
    cards = cards.filter((item: any, ind: number) => ind != index);
    const promoIndex = cards.findIndex(
      (v: any) => v.parentIndex === index && v.promoType === PROMO_TYPE.SEPARATE
    );
    cards = cards.map((item: any, ind: number) => {
      const cd = { ...item };
      if (ind > index && cd.parentIndex > 0) {
        cd.parentIndex = cd.parentIndex - 1;
      }
      return cd;
    })
    if (promoIndex > -1) {
      cards.splice(promoIndex, 1);
      cards = cards.map((item: any, ind: number) => {
        const cd = { ...item };
        if (ind > promoIndex && cd.parentIndex > 0) {
          cd.parentIndex = cd.parentIndex - 1;
        }
        return cd;
      })
    }
    await dispatch(addGiftCart(cards));
    dispatch(setGiftModalVisible(false));
  };
  const updateAmount = (cardData: ICartData, index: number) => {
    if (Number(cardData.giftamount) > 0 && cardData.isVariablePriceItem) {
      cardData.itemTotal =
        Number(cardData.quantity) * Number(cardData.giftamount);
    } else {
      cardData.itemTotal = Number(cardData.quantity) * Number(cardData.price);
    }
    const cartData = [...cart];
    cartData[index] = cardData;
    dispatch(addGiftCart(cartData));
    dispatch(setGiftModalVisible(false));
    setAction("");
  };

  useEffect(() => {
    if (cart && cart.length === 0) {
      navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`);
    }
  }, [cart]);

  const formatPromoData = (data: any, index: number) => {
    const promoData = { ...data[index] };
    const promo = findGiftPromo(giftPromo, data[index].itemTotal, currentTab);
    const promoIndex = data.findIndex(
      (v: any) => v.parentIndex === index && v.promoType === PROMO_TYPE.SEPARATE
    );

    if (promoIndex > -1) {
      data.splice(promoIndex, 1);
    }

    if (promo && promo?.promoTo === PROMO_TYPE.SEPARATE) {
      data[index] = { ...promoData, promoType: "" };
      data.splice(index+1,0,formatPromo(promoData, index, promo, data[index]));
    } else if (promo && promo?.promoTo === PROMO_TYPE.EXISTING) {
      data[index] = {
        ...promoData,
        promoType: PROMO_TYPE.EXISTING,
        promo: { ...promo },
      };
    } else {
      data[index] = { ...promoData, promoType: "" };
    }
    return data;
  };

  const onClickminus = (card: any, index: number) => {
    const qty = card.quantity;
    if (qty > 1) {
      const cardData = { ...card };
      cardData.quantity = qty - 1;
      if (Number(cardData.giftamount) > 0 && cardData.isVariablePriceItem) {
        cardData.itemTotal =
          Number(cardData.quantity) * Number(cardData.giftamount);
      } else {
        cardData.itemTotal = Number(cardData.quantity) * Number(cardData.price);
      }
      //setCard(cardData);
      let cartData = [...cart];
      cartData[index] = cardData;
      cartData = formatPromoData(cartData, index);
      dispatch(addGiftCart(cartData));
    }
  };
  const openModal = (cartData: any, index: number) => {
    setEditCardData(cartData);
    setIndex(index);
    dispatch(setGiftModalVisible(true));
  };

  useEffect(() => {
    if (promoPopupData && promoPopupData.card) {
      setEditCardData(promoPopupData.card);
      setIndex(promoPopupData.index);
      dispatch(setGiftModalVisible(true));
    }
  }, [promoPopupData]);

  const onClickplusCart = async (card: any, index: number) => {
    const qty = card.quantity;
    if (qty < 10) {
      const cardData = { ...card };
      cardData.quantity = qty + 1;
      updateAmount(cardData, index);
      if (Number(cardData.giftamount) > 0 && cardData.isVariablePriceItem) {
        cardData.itemTotal =
          Number(cardData.quantity) * Number(cardData.giftamount);
      } else {
        cardData.itemTotal = Number(cardData.quantity) * Number(cardData.price);
      }
      //setCard(cardData);
      let cartData = [...cart];
      cartData[index] = cardData;
      cartData = formatPromoData(cartData, index);
      dispatch(addGiftCart(cartData));
    }
  };

  const backToGiftShop = () => {
    navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`);
  };

  const openRemoveConfirmation = () => {
    setAction("remove");
  };

  const cancelRemove = () => {
    setAction("edit");
  };

  const promoAmount = useMemo(() => {
    return cart.reduce((total: number, v: any) => {
      return (total =
        total +
        (v.promoType === PROMO_TYPE.SEPARATE ||
          v.promoType === PROMO_TYPE.EXISTING
          ? v.promo.amount
          : 0));
    }, 0);
  }, [cart]);

  const openPaymentModal = async () => {
    generateUsersessionId("giftshop");
    const orders = {
      giftcards: cart.map((v: ICartData) => {
        const egiftCardImage = v.image.split("/");
        return {
          quantity: v.quantity,
          giftamount:
            (!v.isVariablePriceItem ? v.price : v.giftamount) +
            (v.promoType ? v.promo?.amount : 0),
          recipientname: v.recipientName,
          recipientemail: v.recipientemail,
          confirmemail: v.confirmRecipientEmail,
          yourname: v.senderName,
          phone: v.phone,
          giftMessage: v.giftMessage,
          id: v.promoType && v.promo ? settings.giftcard_bonus_id : settings.giftcard_promo_id,
          image: v.image,
          description: v.description,
          price: (!v.isVariablePriceItem ? v.price : v.giftamount) + (v.promoType ? v.promo?.amount : 0),
          tax: v.tax,
          deliverydate: v.deliveryDate,
          giftcard_type: v.giftcardType,
          itemTotal: v.itemTotal,
          IsVariablePriceItem: true,
          MinimumVariablePriceInCents: v.minimumVariablePriceInCents,
          MaximumVariablePriceInCents: v.maximumVariablePriceInCents,
          name: v.carddesign,
          carddesign: v.carddesign,
          eGiftcardDesign: egiftCardImage[egiftCardImage.length - 1],
          type: v.type,
          delivery_add_1: v.address1,
          delivery_add_2: v.address2,
          city: v.city,
          state: v.state,
          postcode: v.postCode,
          promo: v.promo ? v.promo : undefined,
          promoType: v.promoType ? v.promoType : "",
          purchaserEmail: v.purchaserEmail
        };
      }),
      subTotal: cartData.total.toFixed(2),
      totalTaxPrice: "0.00",
      totalPrice: cartData.total.toFixed(2),
      deliveryFee: cartData.deliveryFee.toFixed(2),
      totalQuantity: cart.length,
    };

    const payload = {
      userSessionId: generateUsersessionId("giftshop"),
      method: "giftcards",
      cinemaId: GIFT_CARD_CINEMA_ID[countryId],
      step: 1,
      requestMethod: "GiftcardshopOrder",
      countryId: countryId,
      order: orders,
    };
    const { status, data, error }: any = await giftCardOrderApi(payload);
    if (status) {
      dispatch(giftCardOrderSuccess(data));
      const modal =
        TICKET_FLOW[TICKET_FLOW_TYPES.GIFTSHOP][MODAL_TYPE.GIFTSHOP_PAYMENT];
      dispatch(
        setModal({
          ...modal,
          type: TICKET_FLOW_TYPES.GIFTSHOP,
        })
      );
      dispatch(toggleTicketModal(true));
    } else {
      toast.error(error ? error : "Error occured while making payment");
    }
  };

  return (
    <div className="gift-cart-wrapper">
      <HelmetTitle title="My Gift Cart" description="My Gift Cart" />
      <div className="gift-addcart-main">
        <h3>
          <img src={URLS.GIFT_CART} alt="" />
          My Gift Cart
          {/* <span>
            {carts.length} <label>ITEMS</label>
          </span> */}
        </h3>
        <div className="gift-addcart-inner">
          <div className="gift-addcart-inner-cont">
            <ul>
              {cart &&
                cart.map((item: any, index: any) => (
                  <li id="cart" key={index}>
                    <div className="cart-detail-left">
                      <div className="cartImg">
                        {item.promoType === PROMO_TYPE.SEPARATE ? (
                          <div className="promo-design">
                            <div>
                              <p className="amount">${item?.promo?.amount}</p>
                              <p className="desc">Gift Card</p>
                            </div>
                            <img
                              src={
                                IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                "gift_bonus_white.png"
                              }
                            />
                          </div>
                        ) : (
                          <img
                            src={item.image}
                            alt=""
                            onError={(e: any) =>
                              (e.target.src = URLS.GIFT_CARD_IMAGE)
                            }
                          />
                        )}
                      </div>
                      <div className="cartContent">
                        <h2>
                          <strong>
                            {item.name ? item.name : item.description}
                            {item?.promoType === PROMO_TYPE.EXISTING
                              ? ` + ${item?.promo?.name}`
                              : ""}
                          </strong>
                        </h2>
                        {item.giftcardType == GiftType.ecard ? (
                          <p>
                            Gift Type: <span>E-Gift Card</span>
                          </p>
                        ) : null}
                        {item.giftcardType == GiftType.physical ? (
                          <p>
                            Gift Type: <span>Physical Gift Card</span>
                          </p>
                        ) : null}
                        {item.giftcardType == GiftType.exp ? (
                          <p>
                            Gift Type: <span>Experience</span>
                          </p>
                        ) : null}
                        <p>
                          Gift To: <span>{item.recipientName}</span>
                        </p>
                        {item.giftcardType == GiftType.ecard ? (
                          <p>
                            Deliver To: <span>{item.recipientemail}</span>
                          </p>
                        ) : null}
                        {item.giftcardType == GiftType.ecard ? (
                          <p>
                            Delivery Date:{" "}
                            <span>{`${moment(item.deliveryDate).format(
                              "DD MMM YYYY"
                            )}`}</span>
                          </p>
                        ) : null}
                        {item.giftcardType !== GiftType.ecard ? (
                          <p>
                            Deliver To:
                            <span>
                              {item.delivery_add_1}
                              {item.delivery_add_2}
                              {item.city},{item.state},{item.postcode}
                            </span>
                          </p>
                        ) : null}
                        <p>
                          From: <span>{item.senderName}</span>
                        </p>
                        <p>
                          Message: <span>{item.giftMessage}</span>
                        </p>
                        <p className="mob-field">
                          Item Total: <span>${item.itemTotal}</span>
                        </p>
                        {item.giftcardType == GiftType.physical && item.promoType === PROMO_TYPE.SEPARATE ? <p>
                          Bonus will be emailed to the purchaser by 6th of December
                        </p> : null}
                      </div>
                      <div className="cartEdit cartDeladd">
                        <div className="mob-div">
                          <img
                            src={URLS.GIFT_CARD_DEL}
                            alt="del"
                            aria-label="Close"
                            className="imgDel"
                            onClick={() => {
                              removeCard(index);
                            }}
                          />
                        </div>
                        <div>
                          <img
                            src={URLS.GIFT_CARD_EDIT}
                            alt=""
                            aria-label="Close"
                            onClick={() => {
                              setAction("edit");
                              openModal(item, index);
                            }}
                          />
                        </div>
                        <div className="nor-div">
                          <img
                            src={URLS.GIFT_CARD_DEL}
                            alt="del"
                            aria-label="Close"
                            className="imgDel"
                            onClick={() => {
                              removeCard(index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="cart-detail-right cart-count-right">
                      {item?.promoType !== PROMO_TYPE.SEPARATE ? (
                        <div className="cart-detail-count">
                          <div className="form-group quantity_wrap">
                            <div className="mob-qty">Quantity</div>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <button
                                  className="btn_minus"
                                  onClick={() => {
                                    onClickminus(item, index);
                                  }}
                                >
                                  &nbsp;
                                </button>
                              </div>
                              <input
                                type="number"
                                className="form-control"
                                aria-label="qantity"
                                disabled
                                value={item.quantity}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn_plus"
                                  onClick={() => {
                                    onClickplusCart(item, index);
                                  }}
                                >
                                  &nbsp;
                                </button>
                              </div>
                              {/* <div className="error_message">
        <span>Minimum one quantity is required</span>
    </div> */}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="cart-detail-price">
                        {item?.promoType === PROMO_TYPE.SEPARATE ? (
                          <p>Free</p>
                        ) : (
                          <p>${item.itemTotal}</p>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            {cartData.deliveryFee ? (
              <div className="cart-detail-delivery">
                <h2>${cartData.deliveryFee.toFixed(2)} Delivery Fee</h2>
                <ul>
                  {cart &&
                    cart.map((item: any, index: any) => {
                      return (
                        <>
                          {item.giftcardType != GiftType.ecard ? (
                            <li id="cart" key={index}>
                              <p>
                                Deliver To:
                                <span>
                                  {" "}
                                  {item.address1}
                                  {item.address2}
                                  {item.city}, {item.state}, {item.postCode}
                                </span>
                              </p>
                            </li>
                          ) : null}
                        </>
                      );
                    })}
                </ul>
              </div>
            ) : null}

            <div className="cart-total">
              <div className="cart-total-left">
                <label>TOTAL ORDER</label>
                <h2>${cartData.total.toFixed(2)}</h2>
              </div>
              <div className="cart-total-right">
                <div className="btn-section">
                  <button
                    type="button"
                    className="btn green_btn"
                    onClick={openPaymentModal}
                  >
                    Checkout ({cart.length})
                  </button>
                  <button
                    type="button"
                    className="btn black_btn"
                    onClick={backToGiftShop}
                  >
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        { }
        <GiftShopFooter page="giftshop" />
      </div>
      <Modal visible={giftModalVisible} className="edit-cart-popup">
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-lg ">
            <div className="modal-content">
              {action === "remove" ? (
                <div className="modal-header">
                  <h3 className="modal-title" style={{ fontSize: 22 }}>
                    Remove gift?
                  </h3>
                </div>
              ) : null}
              <button
                className="close btn-close "
                type="button"
                onClick={() => dispatch(setGiftModalVisible(false))}
              >
                <span className="close_icon"></span>
              </button>
              <div className="modal-header ng-star-inserted"></div>
              <div className={`modal-body ${action === "remove" ? "" : "p-0"}`}>
                {action === "remove" ? (
                  <RemoveGiftCard
                    cancelRemove={cancelRemove}
                    item={editCardData}
                    index={index}
                    removeCard={removeCard}
                  />
                ) : (
                  <GiftCardForm
                    isEdit={true}
                    item={editCardData}
                    index={index}
                    removeGiftCard={openRemoveConfirmation}
                  ></GiftCardForm>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Cart;
