import moment from "moment";
import { IMemberUpdateDetail, IUserDetails } from "../models/auth";
import { MEMBERSHIP_TYPE } from "../constants/app";
import { API_REQUEST_TYPE } from "../constants/url";
import { RootState, store } from "../store";

export const formatLoyaltyPayload = (userDetails: IUserDetails, countryId: string, memberDetail: IMemberUpdateDetail, type: string) => {
    return {
        id: userDetails?.result?.memberRefId!,
        emailId: userDetails?.email!,
        type: API_REQUEST_TYPE.LOYALTY,
        wallet: type,
        countryId: countryId,
        memberType: memberDetail?.LoyaltyMember?.MemberLevelName
            ? memberDetail?.LoyaltyMember?.MemberLevelName?.replace(
                "Gold",
                "Rewards Gold"
            )?.replace("Club", "Rewards Club")
            : "",
    }
}

export const formatRewardPoints = (memberDetail: IMemberUpdateDetail) => {
    if (
        memberDetail &&
        memberDetail?.LoyaltyMember &&
        memberDetail.LoyaltyMember.BalanceList &&
        memberDetail.LoyaltyMember.BalanceList.length > 0
    ) {
        return memberDetail?.LoyaltyMember?.BalanceList.reduce(
            (total: number, v: any) => {
                total = total + Number(v.PointsRemaining);
                return total;
            },
            0
        )
    } else {
        return 0;
    }
}

export const formatMembershipType = (memberDetail: IMemberUpdateDetail) => {
    const state: RootState = store.getState();
    const settings = state.applicationReducer.settings;
    return memberDetail &&
        memberDetail?.LoyaltyMember &&
        Number(memberDetail?.LoyaltyMember?.MemberLevelId) === Number(settings?.membership?.membershipLevelId) ? MEMBERSHIP_TYPE.GOLD : MEMBERSHIP_TYPE.CLUB
}

export const formatMembershipExpireDate = (memberDetail: IMemberUpdateDetail) => {
    return memberDetail?.LoyaltyMember
        ? moment(memberDetail?.LoyaltyMember?.ExpiryDate).format("DD MMM YYYY")
        : "";
}

export const formatMembershipExpireDays = (memberDetail: IMemberUpdateDetail) => {
    if (memberDetail?.LoyaltyMember?.ExpiryDate) {
        const a = moment();
        const b = moment(
            moment(memberDetail?.LoyaltyMember?.ExpiryDate).format("YYYY-MM-DD")
        );
        return b.diff(a, "days");
    } else {
        return 0;
    }
}


export const calculateCummulativePoints = (data: any, memberDetail: IMemberUpdateDetail)=>{
    let tpoints =  0;
    let points = formatRewardPoints(memberDetail!);
    return data.result.MemberTransactions.map((item: any, index: number) => {
        tpoints +=  item.PointsEarned
        let cumulativeSum = index ? (points - tpoints) : points;
        return { ...item, cumulativeSum: cumulativeSum }
      });
}