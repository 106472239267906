import { useEffect, useMemo, useState } from "react";
import { IMAGE_URL_CONFIG } from "../../../../constants/url";
import "./transaction.scss"
import { purchaseHistoryApi } from "../../../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ITransactionHistory } from "../../../../models/auth";
import moment from "moment";
import { COUNTRY } from "../../../../constants/app";
import { calculateCummulativePoints, formatRewardPoints } from "../../../../utils/rewards";
import { setContentModal, toggleContentModal } from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";

const COUNT = 5;
const Transaction = () => {

  const { countryId, memberDetail } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    memberDetail: state.authReducer.memberDetail,
  }));

  const dispatch = useDispatch();
  const [count, setCount] = useState(5);
  const [actualPurchaseHistory, setActualPurchaseHistory] = useState<ITransactionHistory[]>(
    []
  );
  const [purchaseHistory, setPurchaseHistory] = useState<ITransactionHistory[]>(
    []
  );

  const getPurchaseHistory = async () => {

    const payload = {
      countryId: countryId,
      MemberRefId: memberDetail?.LoyaltyMember?.MemberId,
      cardNumber: memberDetail?.LoyaltyMember?.CardNumber,
      UserSessionId: memberDetail?.LoyaltyMember?.CardNumber,
    };
    const {
      status,
      data: {
        data,
        statusCode
      }
    } = await purchaseHistoryApi(payload) as any;
    if (status === 200 && statusCode === 200 && data) {
      if (
        data.result &&
        data.result.MemberTransactions &&
        data.result.MemberTransactions.length > 0
      ) {
        const history = calculateCummulativePoints(data, memberDetail!);
        setActualPurchaseHistory(history);
        setPurchaseHistory(history.slice(0, COUNT));
      }
    }
  };
  useEffect(() => {
    if (memberDetail) {
      getPurchaseHistory();
    }
  }, [memberDetail]);

  const onLoadMore = () => {
    if (purchaseHistory.length < actualPurchaseHistory.length) {
      setPurchaseHistory(actualPurchaseHistory.slice(0, count + COUNT));
      setCount(count + COUNT);
    }
  }

  const openContactUs = () => {
    dispatch(setContentModal({ type: ROUTES.CONTACT_US }));
    dispatch(toggleContentModal(true));
  }

  return (
    <div className="transaction_history">
      <div className="tran_haeading">
        <h1>Transaction History</h1>
        <p>Transactions can take up to 24 hours to appear.</p>
      </div>
      <div className="tran_table">
        <table>
          <tr>
            <th className="text-center w_100">DATE</th>
            <th className="w_250">PURCHASED ITEMS</th>
            <th className="text-right w_100 mobile-hide">PURCHASED TOTAL</th>
            <th className="text-right w_80 mobile-hide">POINTS USED</th>
            <th className="text-right w_100 mobile-hide">POINTS EARNED</th>
            <th className="text-right w_100">POINT BALANCE</th>
          </tr>

          {
            purchaseHistory.length > 0 ? purchaseHistory.map((item: any, index: number) => {
              return (
                <tr className={index % 2 == 0 ? "odd" : ""}>
                  <td scope="row">
                    <span className="subitem">{moment(
                      new Date(parseFloat(item.TransactionDate.substr(6)))
                    ).format("DD MMM YYYY")}</span>
                  </td>
                  <td>
                    <span>{item.MovieName ? `${item.MovieName} -` : ""}</span> {item.CinemaName}
                    <div className="subitem">1 x {item.ItemName}</div>
                  </td>
                  <td className="text-right mobile-hide subitem">${item.BoxOfficeValue ? (item.BoxOfficeValue).toFixed(2) : (item.ConcessionsValue).toFixed(2)}</td>
                  <td className={`text-right mobile-hide ${!item.PointsRedeemed ? 'subitem' : ''}`}>{item.PointsRedeemed !== 0 ? `- ${item.PointsRedeemed}` : item.PointsRedeemed}</td>
                  <td className={`text-right mobile-hide ${!item.PointsRedeemed ? 'subitem' : ''}`}>{item.PointsEarned !== 0 ? `+ ${item.PointsEarned}` : item.PointsEarned}</td>
                  <td className="text-right">{item.cumulativeSum}</td>
                </tr>
              )
            }) : <tr className="text-center">
              <td colSpan={6}>No records found</td>
            </tr>}
        </table>
      </div>
      {actualPurchaseHistory.length > 5 && purchaseHistory.length < actualPurchaseHistory.length ? <div>
        <button className="btn btn_black" onClick={onLoadMore}><span style={{ paddingRight: 10 }}>LOAD MORE</span>
          <img src={
            IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
            "arrow-bottom-white.svg"
          } alt="" /></button>
      </div> : null}
      <div className="missing_tran">
        <h6>Missing transactions?</h6>
        <button className="btn btn_white" onClick={openContactUs}>CONTACT US HERE</button>
      </div>
    </div>
  );
}

export default Transaction;