import React, { FC, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FOOD_COMPONENT_TYPE } from "../../../../../../constants/foodAndBeverage";
import { MODAL_TYPE } from "../../../../../../models/tickets";
import { RootState } from "../../../../../../store";
import {
  addFoodItemsCart,
  updateFoodItemsCart,
} from "../../../../../../store/foodAndBeverage/action";
import { FoodBeverageItems } from "../../../../../../store/foodAndBeverage/type";
import CustomizeItems from "./CustomizeItem";
import Items from "./ItemsList";
let _ = require("lodash");

interface IFoodAndBeverageProps {
  onCancel?: () => void;
}

export const FoodAndBeverage: FC<IFoodAndBeverageProps> = ({ onCancel }) => {
  const { orderCartData, modalType, modalData } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      orderCartData: state.foodBeverageReducer.orderCartData,
      modalType: state.ticketBookingReducer.modal.type,
      modalData: state.ticketBookingReducer.modal.data,
    })
  );

  const dispatch = useDispatch();

  const [currentComponent, setCurrentComponent] = useState<FOOD_COMPONENT_TYPE>(
    FOOD_COMPONENT_TYPE.FOOD_ITEMS
  );
  const [selectedFoodItemIndex, setSelecetedFoodItemIndex] = useState();
  const [selectedFoodItem, setSelecetedFoodItem] = useState<any>([]);

  useEffect(() => {
    if (modalData && modalData.page == MODAL_TYPE.FOOD_AND_BEVERAGE) {
      const item = JSON.parse(JSON.stringify(modalData));
      setSelecetedFoodItem({
        ...item,
        state: "edit",
        index: item.index,
      });
      setCurrentComponent(FOOD_COMPONENT_TYPE.CUSTOMISE);
    } 
    else if (modalData && modalData.tab) {
      setCurrentComponent(FOOD_COMPONENT_TYPE.FOOD_ITEMS);
    }
  }, [modalData]);

  const handleAddToOrder = (items: FoodBeverageItems) => {
    const item = JSON.parse(JSON.stringify(items));
    if (item.state) {
      const newArray = JSON.parse(JSON.stringify(orderCartData));
      newArray[item.index] = item;
      dispatch(updateFoodItemsCart([...newArray]));
    } else {
      dispatch(
        addFoodItemsCart({
          ...item,
        })
      );
    }
    setCurrentComponent(FOOD_COMPONENT_TYPE.FOOD_ITEMS);
  };

  const removeOrder = (index: any) => {
    const order = JSON.parse(JSON.stringify(orderCartData));
    order.splice(index, 1);
    dispatch(updateFoodItemsCart(order));
    setCurrentComponent(FOOD_COMPONENT_TYPE.FOOD_ITEMS);
  };

  const Content = useMemo(() => {
    switch (currentComponent) {
      case FOOD_COMPONENT_TYPE.FOOD_ITEMS:
        return (
          <Items
            setCurrentComponent={setCurrentComponent}
            setSelecetedFoodItem={setSelecetedFoodItem}
            onCancel={onCancel}
          />
        );
      case FOOD_COMPONENT_TYPE.CUSTOMISE:
        return (
          <CustomizeItems
            item={selectedFoodItem}
            index={selectedFoodItemIndex}
            onSave={handleAddToOrder}
            onRemove={removeOrder}
            onBack={() => setCurrentComponent(FOOD_COMPONENT_TYPE.FOOD_ITEMS)}
          />
        );
      default:
        return null;
    }
  }, [currentComponent]);

  return <div>{Content}</div>;
};

export default FoodAndBeverage;
