import React,{ FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COUNTRY, MOVIE_TYPES } from "../../../../constants/app";
import { RootState } from "../../../../store";
import { getNowShowingWatcher } from "../../../../store/films/action";
import { downloadFile } from "../../../../utils/helper";
import _, { sortBy, orderBy } from "lodash";
import "../NowShowing/nowShowing.scss";
import moment from "moment";
import DateSlider from "../../../../components/UI/DateSlider";
import { IFormatMovie } from "../../../../models/cinema";
import { formatMovieData } from "../../../../utils/formatter";
import { IMOVIE_TYPES } from "../../../../models/common";
import Session from "../../../../components/Session/AU/NowShowing";
import {
  setFooterType,
  setReinitializeScroll,
} from "../../../../store/application/action";

interface ISpecialEventsProps {}

const SpecialEvents: FC<ISpecialEventsProps> = () => {
  const { countryId, cinemaId, nowShowing, sessionPdf, currentCinema } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      cinemaId: state.applicationReducer.currentCinema?.cinemaId,
      nowShowing: state.filmsReducer.nowShowing,
      sessionPdf: state.applicationReducer.currentCinema?.sessionPdf,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const dispatch = useDispatch();
  const [showPrintSession, setShowPrintSession] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [dateArray, setDateArray] = useState<
    Array<{ disp_date: string; value: string }>
  >([]);
  const [sort, setSort] = useState<string>("showtimes");
  const [nowShowingData, setNowShowingData] = useState<IFormatMovie[]>([]);
  const [movieTypes, setMovieTypes] = useState<IMOVIE_TYPES>(MOVIE_TYPES);
  const [selectedDate, setSelectedDate] = useState<string>("");

  useEffect(() => {
    if (countryId) {
      switch (countryId) {
        case COUNTRY.STA:
          setShowPrintSession(false);
          setShowPdf(true);
          break;
        default:
          setShowPrintSession(true);
          setShowPdf(false);
          break;
      }
    }
  }, [countryId]);

  useEffect(() => {
    if (cinemaId) {
      const payload = {
        countryId: countryId,
        cinemaId: cinemaId,
        status: "nowShowing",
        flag: "SE",
      };
      dispatch(getNowShowingWatcher(payload));
    }
  }, [cinemaId]);

  useEffect(() => {
    if (Object.keys(nowShowing).length > 0) {
      let resDate = _.keys(nowShowing);
      resDate = _.sortBy(resDate, function (date: string) {
        return new Date(date);
      });
      const dates = resDate.map((v) => {
        return {
          disp_date:
            v == moment().format("YYYY-MM-DD")
              ? "TODAY"
              : moment(v).format("DD/MM ddd"),
          value: v,
        };
      });
      setDateArray(dates);
      setSelectedDate(dates[0].value);
      const sortedData = sortData(
        formatMovieData(
          Object.values(!!dates[0] ? nowShowing[dates[0].value] : [])
        ),
        sort
      );
      if (sortedData) {
        setNowShowingData(sortedData);
        setFlags(sortedData);
        dispatch(setFooterType(sortedData.length+5));
      }
    }else{
      setNowShowingData([])
      dispatch(setFooterType(0));
    }
  }, [nowShowing]);

  const setFlags = (data: IFormatMovie[]) => {
    const tempMoviesTypes = movieTypes;
    data?.forEach((movies: any) => {
      movies?.shows?.forEach((session: any) => {
        const sessionAttributes = session?.session_attributes;
        if (
          sessionAttributes &&
          Object.keys(tempMoviesTypes).includes(sessionAttributes.toLowerCase())
        ) {
          tempMoviesTypes[sessionAttributes.toLowerCase()].active = true;
        }
      });
    });
    setMovieTypes(tempMoviesTypes);
  };

  const downloadPdf = () => {
    downloadFile(sessionPdf);
  };

  const sortData = (data: any, type: string) => {
    switch (type) {
      case "showtimes":
        return [...orderBy(data, (row) => row["shows"].length, ["desc"])];
      case "movieName":
        return [...sortBy(data, (row) => row["movieData"]["name"])];
    }
  };

  const onSort = (type: string) => {
    setSort(type);
    const sortedData = sortData(nowShowingData, type);
    if (sortedData) {
      setNowShowingData(sortedData);
    }
  };

  const onChangeDate = (dateObject: { disp_date: string; value: string }) => {
    setSelectedDate(dateObject.value);
    dispatch(setReinitializeScroll(true));
    const sortedData = sortData(
      formatMovieData(Object.values(nowShowing[dateObject.value])),
      sort
    );
    if (sortedData) {
      setNowShowingData(sortedData);
      setFlags(sortedData);
      dispatch(setFooterType(sortedData.length+5));
    }
  };

  return (
    <div>
      {nowShowingData.length > 0 ? (
        <>
          <div className="bg stickysecmenu">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-7">
                  {dateArray.length > 0 ? (
                    <DateSlider
                      data={dateArray}
                      onChange={onChangeDate}
                      selected={selectedDate}
                    />
                  ) : null}
                </div>
                <div className="col-12 col-lg-5 pl-lg-0 pr-lg-0">
                  <div className="print_session_wrap">
                    <div className="d-flex">
                      <div className="selected_location">
                        {currentCinema?.name}
                      </div>
                      <div className="print_session">
                        {showPrintSession ? (
                          <a
                            href="/printsession"
                            target="_blank"
                            className="text-nowrap"
                          >
                            Print Session Times
                          </a>
                        ) : null}
                        {showPdf && sessionPdf ? (
                          <a
                            href="javascript:void(0)"
                            onClick={downloadPdf}
                            className="text-nowrap"
                          >
                            Print Programme
                          </a>
                        ) : null}
                      </div>
                    </div>
                    <div className="legend_text">
                      <ul>
                        {Object.values(movieTypes).map((v) => {
                          return v.active ? (
                            <li className={`${v.class} text-nowrap`}>
                              {v.title}
                            </li>
                          ) : null;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="sort_wrap">
                    <div className="sort_title">Sort by:</div>
                    <ul className="sort_type">
                      <li
                        onClick={() => onSort("showtimes")}
                        className={`${sort === "showtimes" ? "active" : ""}`}
                      >
                        Popularity
                      </li>
                      <li
                        onClick={() => onSort("movieName")}
                        className={`${sort === "movieName" ? "active" : ""}`}
                      >
                        Movie Name
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Session sessionData={nowShowingData} />
        </>
      ) : (
        <div className="err-noMovie">No sessions available</div>
      )}
    </div>
  );
};

export default SpecialEvents;
