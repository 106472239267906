import React,{ FC, useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import {
  clearFoodItemsAction,
  getFoodItemsWatcher,
  getLicense,
  setAlcoholDob,
  updateFoodItemsCart,
} from "../../../../../../../store/foodAndBeverage/action";
import "./items.scss";
import { FoodBeverageItems } from "../../../../../../../store/foodAndBeverage/type";
import {
  FOOD_COMPONENT_TYPE,
  FOOD_TAB_CONFIG,
} from "../../../../../../../constants/foodAndBeverage";
import { addFoodItemsCart } from "../../../../../../../store/foodAndBeverage/action";
import { concessionModifierPrice, generateUsersessionId } from "../../../../../../../utils/tickets";
import {
  cancelOnlyTicketAction,
  setModal,
  updateSession,
} from "../../../../../../../store/ticketBooking/action";
import { setReinitializeScroll } from "../../../../../../../store/application/action";
import moment from "moment";
import HtmlRender from "../../../../../../../components/UI/HtmlRender";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../../../models/tickets";
import { TICKET_FLOW } from "../../../../../../../constants/ticket";
import { onImgError } from "../../../../../../../utils/helper";
import { toast } from "react-toastify";
import { memberValidationApi } from "../../../../../../../services/auth";
import { COUNTRY } from "../../../../../../../constants/app";
import HelmetTitle from "../../../../../../../components/Helmet";
import { memberValidationSuccess } from "../../../../../../../store/auth/action";

interface IItemProps {
  setSelecetedFoodItem: React.Dispatch<React.SetStateAction<FoodBeverageItems>>;
  setCurrentComponent: React.Dispatch<
    React.SetStateAction<FOOD_COMPONENT_TYPE>
  >;
  onCancel?: () => void;
}

const Items: FC<IItemProps> = ({
  setSelecetedFoodItem,
  setCurrentComponent,
  onCancel,
}) => {
  const {
    countryId,
    modalType,
    prevModal,
    nextModal,
    ticketDetails,
    foodItemsData,
    orderCartData,
    hasGL,
    ticketLessFb,
    isFutureFb,
    preOrderFb,
    currentCinema,
    films,
    alcoholDob,
    license,
    isLoggedIn,
    userDetails,
    modalData,
    isConcessionDiscount
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    modalType: state.ticketBookingReducer.modal.type,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    foodItemsData: state.foodBeverageReducer.foodItemsData,
    orderCartData: state.foodBeverageReducer.orderCartData,
    currentCinema: state.applicationReducer.currentCinema,
    hasGL: state.ticketBookingReducer.hasGL,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    isFutureFb: state.ticketBookingReducer.isFutureFb,
    preOrderFb: state.ticketBookingReducer.preOrderFb,
    films: state.ticketBookingReducer.films,
    alcoholDob: state.foodBeverageReducer.alcoholDob,
    license: state.foodBeverageReducer.license,
    modalData: state.ticketBookingReducer.modal.data,
    isLoggedIn: state.authReducer.isLoggedIn,
    userDetails: state.authReducer.userDetails,
    isConcessionDiscount: state.foodBeverageReducer.isConcessionDiscount,
  }));

  const dispatch = useDispatch();
  const dateRef: React.RefObject<HTMLInputElement> = useRef(null);
  const monthRef: React.RefObject<HTMLInputElement> = useRef(null);
  const yearRef: React.RefObject<HTMLInputElement> = useRef(null);

  const [items, setItems] = useState<Array<any>>([]);
  const [activeTab, setActiveTab] = useState([]);
  const [activeMenu, setActiveMenu] = useState(0);
  const [concessionType, setConcessionType] = useState("");
  const [isAgreed, setIsAgreed] = useState(alcoholDob ? true : false);
  const [dobError, setDobError] = useState("");

  useEffect(() => {
    if (countryId && currentCinema) {
      const userSessionId = generateUsersessionId(currentCinema?.slug!);
      if (ticketLessFb) {
        dispatch(updateSession(userSessionId));
        // validate member api call for direct fb
        if (isLoggedIn && countryId == COUNTRY.ANG) {
          memberValidate(userSessionId).then(() => {
            getConcessionItems(userSessionId);
          });
        }
        else {
          getConcessionItems(userSessionId);
        }
      }
      else {
        getConcessionItems(userSessionId);
      }
    }
  }, [countryId, currentCinema]);

  useEffect(() => {
    if (foodItemsData && foodItemsData.length > 0) {
      setItems(foodItemsData);
      if(modalData && modalData.tab){
        const index = foodItemsData?.findIndex((menuitems: any) => (menuitems.name).toLowerCase() === (modalData.tab).toLowerCase());
        if(index > -1 &&  activeMenu !== index ){
          setActiveTab(foodItemsData[index]["concessions"] ? foodItemsData[index]["concessions"] : []);
          setConcessionType(foodItemsData[index]["name"] ? (foodItemsData[index]["name"]).toLowerCase() : "");
          setActiveMenu(index);
        }
      }else{
      setActiveTab(foodItemsData[activeMenu]["concessions"]);
      setConcessionType(foodItemsData[activeMenu]["name"].toLowerCase());
      }
      dispatch(setReinitializeScroll(true));
    }
  }, [foodItemsData, activeMenu]);


  const foodMenuClick = (menu: any, i: any) => {
    setActiveTab(menu["concessions"] ? menu["concessions"] : []);
    setConcessionType(menu["name"] ? menu["name"].toLowerCase() : "");
    setActiveMenu(i);
    dispatch(setReinitializeScroll(true));
  };

  const getConcessionItems = (userSessionId: any) => {
    let params = {
      cinemaId:
        ticketLessFb && isFutureFb
          ? preOrderFb?.theatreId
          : currentCinema?.slug,
      countryId: countryId,
      userSessionId: ticketLessFb
        ? userSessionId
        : ticketDetails?.userSessionId,
      hasGL: ticketLessFb && !isFutureFb ? 0 : hasGL,
    };
    dispatch(getFoodItemsWatcher(params));
      dispatch(
        getLicense({
          countryId,
          cinemaId:
            ticketLessFb && isFutureFb
              ? preOrderFb?.theatreId
              : currentCinema?.slug,
        })
      );
  };

  const loadOrderSummary = () => {
    // onClick();
  };

  const editFoodItems = (items: FoodBeverageItems, index: number) => {
    const item = JSON.parse(JSON.stringify(items));
    setSelecetedFoodItem({
      ...item,
      state: "edit",
      index: index,
    });
    setCurrentComponent(FOOD_COMPONENT_TYPE.CUSTOMISE);
  };

  const onChange = (items: FoodBeverageItems) => {
    const item = JSON.parse(JSON.stringify(items));
    item.description = item.name;
    if (item.options.length > 0) {
      if (item.options.length == 2) {
        const modInd = item.options.findIndex(
          (value: any) => value._comment === "ModifierGroups"
        );
        const smartInd = item.options.findIndex(
          (value: any) => value._comment === "smartModifierGroups"
        );
        item.options[smartInd].selected = [];
        item.options[smartInd].unselected = [];
        item.options[modInd].selected = [];
      } else {
        item.options[0].selected = [];
        item.options[0].unselected = [];
      }
      setSelecetedFoodItem({
        ...item,
        totalPrice: item.price,
        quantity: 1,
      });
      setCurrentComponent(FOOD_COMPONENT_TYPE.CUSTOMISE);
    } else if(hasGL){
      setSelecetedFoodItem({
        ...item,
        totalPrice: item.price,
        quantity: 1,
      });
      setCurrentComponent(FOOD_COMPONENT_TYPE.CUSTOMISE);
    }else {
      dispatch(
        addFoodItemsCart({
          ...item,
          totalPrice: item.price,
          quantity: 1,
        })
      );
      setCurrentComponent(FOOD_COMPONENT_TYPE.FOOD_ITEMS);
      toast(<div className="toast-message">Item added to order</div>,{
        className: 'toast-concession-info',
        position: 'top-center',
        type: 'info',
        closeButton: <div className="toast-close-button">x</div>
      })
    }
  };

  const showAlcoholPopup = useMemo(() => {
    return !isAgreed && concessionType === "alcohol";
  }, [isAgreed, concessionType]);

  const handleChange = (evt: any, field: string) => {
    if (evt.keyCode == 13) {
      submitDob();
    }

    if (evt.target.value.length > 1) {
      switch (field) {
        case "date":
          monthRef.current?.focus();
          break;
        case "month":
          yearRef.current?.focus();
          break;
      }
    }
  };

  const submitDob = () => {
    const day = dateRef.current?.value;
    const month = monthRef.current?.value;
    const year = yearRef.current?.value;
    const date = `${year}-${Number(month) < 10 ? `0${Number(month)}` : month}-${
      Number(day) < 10 ? `0${Number(day)}` : day
    }`;

    if (!day || !month || !year) {
      setDobError("Please enter a valid date of birth");
      return;
    } else if (moment().diff(date, "years", true) < 18) {
      setDobError("You must be over 18 years old to view alcohol products");
      return;
    } else if (!moment(date).isValid() || Number(year) < 1900) {
      setDobError("Enter a valid date of birth");
      return;
    }
    dispatch(setAlcoholDob(date));
    setIsAgreed(true);
  };

  const memberValidate = async (userSessionId: any) => {
    let memberResponse = await memberValidationApi({
      MemberRefId: userDetails?.vista_memberid,
      UserSessionId: userSessionId,
      cardNumber: userDetails?.cardNumber,
      countryId: countryId,
    });
  }

  const AlcoholPopup = () => {
    return (
      <div className="alcohol-wrapper">
        <div className="popup col-md-8 col-12">
          <div className="row justify-content-center">
            <div className="col-md-10 col-12 py-3 px-3">
              <h6>
                Please enter your date of birth to
                <br /> view alcoholic beverages.
              </h6>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center">
              <input
                ref={dateRef}
                type="number"
                className="form-control date"
                id="date"
                placeholder="DD"
                onKeyUp={($e) => handleChange($e, "date")}
                maxLength={2}
              />
              <input
                ref={monthRef}
                type="number"
                className="form-control month mx-2"
                id="month"
                placeholder="MM"
                onKeyUp={($e) => handleChange($e, "month")}
                maxLength={2}
              />
              <input
                ref={yearRef}
                type="number"
                className="form-control year"
                id="year"
                placeholder="YYYY"
                onKeyUp={($e) => handleChange($e, "year")}
                max={9999}
              />
            </div>
          </div>
          {dobError ? (
            <div className="error_message mt-1">{dobError}</div>
          ) : null}

          <div className="col-12 my-4">
            <button
              type="button"
              className="btn black_btn black_btn_with_border mr-2"
              onClick={submitDob}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  };

  const updateMemberdetails = async (userSessionId: string) => {
    if (
      isLoggedIn &&
      !ticketLessFb &&
      userDetails?.cardNumber
    ) {
      const memberResponse: any = await memberValidationApi({
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userSessionId,
        cardNumber: userDetails?.cardNumber,
        countryId: countryId,
      });

      if (memberResponse.status && memberResponse.response.data && memberResponse.response.data.data) {
        dispatch(memberValidationSuccess(memberResponse.response.data.data));
      }
    }
  }

  const onBack = () => {
    if (ticketLessFb) {
      dispatch(clearFoodItemsAction());
      const modal =
        TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
          MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN
        ];
      dispatch(
        setModal({
          ...modal,
          type: modalType,
        })
      );
    } else {
      const ticketType: TICKET_FLOW_TYPES = modalType;
      const prev: MODAL_TYPE = prevModal;
      const modal = TICKET_FLOW[ticketType][prev];
      if (ticketDetails && ticketDetails.userSessionId) {
        dispatch(
          cancelOnlyTicketAction({
            countryId: countryId,
            requestType: "cancelOrder",
            userSessionId: ticketDetails.userSessionId,
          })
        );
        const userSessionId = generateUsersessionId(currentCinema?.slug!);
        dispatch(updateSession(userSessionId));
        updateMemberdetails(userSessionId);
      }
      dispatch(
        setModal({
          ...modal,
          type: modalType,
          data: null,
        })
      );
    }
  };

  const onNext = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
        data: null,
      })
    );
  };

  const totalCartValue = useMemo(() => {
    let total = 0;
    orderCartData.forEach((item: FoodBeverageItems) => {
      let itemPrice = concessionModifierPrice(item);
      total += Number(item.quantity) * Number(itemPrice);
    }, 0);
    return (ticketLessFb ? 0 : ticketDetails?.total) + total;
  }, [orderCartData]);

  const onClickPlus = (items: FoodBeverageItems, index: number) => {
    const item = JSON.parse(JSON.stringify(items));
    if (item.quantity <= 10) {
      const order = JSON.parse(JSON.stringify(orderCartData));
      const concession = JSON.parse(JSON.stringify(item));
      concession.quantity = concession.quantity + 1;
      let itemPrice = concessionModifierPrice(concession);
      concession.totalPrice = (
        Number(itemPrice) * Number(concession.quantity)
      ).toFixed(2);
      order[index] = concession;
      dispatch(updateFoodItemsCart(order));
    }
  };

  const onClickMinus = (items: FoodBeverageItems, index: number) => {
    const item = JSON.parse(JSON.stringify(items));
    const order = JSON.parse(JSON.stringify(orderCartData));
    const concession = JSON.parse(JSON.stringify(item));
    concession.quantity = concession.quantity - 1;
    let itemPrice = concessionModifierPrice(concession);
    concession.totalPrice = (
      Number(itemPrice) * Number(concession.quantity)
    ).toFixed(2);
    order[index] = concession;
    if (concession.quantity <= 0) {
      order.splice(index, 1);
    }
    dispatch(updateFoodItemsCart(order));
  };

  return (
    <>
      <div className="row p-relative food-item-wrapper">
      <HelmetTitle title="Food & Drink" description="Food & Drink"/>
        <div className="col-md-12 padding-wrapper">
          <div className="list_content_wrap">
            <div
              malihu-scrollbar
              className="custom_scroll_content list_item_name_wrap mCustomScrollbar"
            >
              <ul>
                {items?.map((menuitems: any, i) =>
                  FOOD_TAB_CONFIG[countryId].filter(
                    (v: { key: string; item: string }) =>
                      v.item ===
                        menuitems.name.replace(" ", "_").toLowerCase() &&
                      films?.[v.key]
                  ).length === 0 ? (
                    <li
                      className={`${activeMenu == i ? "active" : ""}`}
                      onClick={() => foodMenuClick(menuitems, i)}
                    >
                      {menuitems.name}
                    </li>
                  ) : null
                )}
              </ul>
            </div>
            <div
              className={`food_item_list_wrap mobile_modal_pad ${
                !isAgreed && concessionType === "alcohol" ? "disable-bg" : ""
              }`}
            >
              {activeTab && activeTab.length > 0 ? (
                <div
                  className={`food_item_list custom_scroll_content ${
                    activeTab && activeTab.length > 2 && activeTab.length <= 4
                      ? "three"
                      : activeTab &&
                        activeTab.length > 4 &&
                        activeTab.length <= 6
                      ? "five"
                      : activeTab &&
                        activeTab.length > 6 &&
                        activeTab.length <= 8
                      ? "seven"
                      : "nine_plus"
                  }`}
                >
                  <ul id="listScrollbar">
                    {activeTab?.map((foodItems: any, index: number) => (
                      <li
                        key={`tab_${index}`}
                        onClick={() => onChange(foodItems)}
                      >
                        <div className="food_item_thumb">
                          <img
                            src={foodItems.image}
                            alt="image"
                            onError={onImgError}
                          />
                        </div>
                        <div className="food_item_details_wrap">
                          <p className="food_item_name">{foodItems.name}</p>
                          <p className="food_item_price">
                            <span>From </span>${Number(foodItems.price)}
                            {
                              !isConcessionDiscount ? 
                              <span style={{color:"#838383"}}><br/>{countryId == COUNTRY.ANG?'Gold':'Boost'} Rewards ${Number(foodItems.discountPrice)}</span> : 
                              <span style={{color:"#838383"}}> <del>${Number(foodItems.actualPrice)}</del></span>
                            }
                            
                          </p>
                        </div>
                        <div className="navigate_btn mobile_hide">
                          <div className="nav_text">
                            {foodItems.options && foodItems.options.length > 0 || hasGL
                              ? "Customise"
                              : "Add to Order"}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              {activeTab && activeTab.length === 0 ? (
                <div className="my-5">
                  <div className="text-center">
                    <span className="titlecase">
                      {concessionType ? concessionType : "Food"}
                    </span>{" "}
                    not available
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {concessionType === "alcohol" && license ? (
            <div
              className={`license-content mt-5 ${
                !isAgreed && concessionType === "alcohol" ? "disable-bg" : ""
              }`}
            >
              <h6 className="mb-1">License Details</h6>
              <p className="content">License No. {license.licenseNo}</p>
              <p className="content">Licensee: {license.name}</p>
              <p className="content d-flex">
                <span className="add-width">Licensee Address:</span>
                <HtmlRender content={license.address} />
              </p>
              <div className="mt-2">
                <strong>
                  Click{" "}
                  <a href={currentCinema?.LicensePdf} rel="noopener" target="_blank">
                    here
                  </a>{" "}
                  for alcohol online ordering terms and conditions.
                </strong>
              </div>
              <div className="mt-2">
                <HtmlRender
                  {...{ classNameP: "content-bold" }}
                  content={license.disclaimer}
                />
              </div>
            </div>
          ) : null}
          <div className="col-md-12 black_bg">
            <div className="quantity_wrap">
              <p className="order_title">My Order</p>
              {orderCartData?.length > 0 ? (
                <ul malihu-scrollbar className="custom_scroll_content">
                  {orderCartData?.map((foodItems: any, i: any) => (
                    <li>
                      <div className="media_wrap">
                        <div className="list_details">
                          <p className="title">{foodItems.description}</p>
                          {foodItems.options?.map((optionItems: any) => (
                            <>
                              {optionItems._comment == "AlternateItems" ? (
                                <p className="item_list">
                                  {optionItems.selected.description}
                                </p>
                              ) : null}
                              {optionItems._comment == "ModifierGroups"
                                ? optionItems.selected
                                    .slice(0, 1)
                                    .map((ops: any) => (
                                      <p className="item_list">
                                        {ops.description}
                                        {optionItems.selected.length > 1 ? (
                                          <span>...</span>
                                        ) : null}
                                      </p>
                                    ))
                                : null}
                              {optionItems._comment == "smartModifierGroups"
                                ? optionItems.selected
                                    .slice(0, 1)
                                    .map((ops: any) => (
                                      <p className="item_list">
                                        {ops.description}
                                        {optionItems.selected.length > 1 ? (
                                          <span>...</span>
                                        ) : null}
                                      </p>
                                    ))
                                : null}
                            </>
                          ))}
                        </div>
                        {foodItems.options && foodItems.options.length > 0 || hasGL?<div className="view_edit">
                          <button
                            id="view_edit_button"
                            className="view_edit_btn"
                            onClick={() => editFoodItems(foodItems, i)}
                          >
                            View/Edit
                          </button>
                        </div>:null}
                      </div>
                      <div className="action_wrap">
                        <div className="action_btns">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="btn_minus"
                                onClick={() => onClickMinus(foodItems, i)}
                              >
                                &nbsp;
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              aria-label="qantity"
                              value={foodItems.quantity}
                              disabled
                            />
                            <div className="input-group-append">
                              <span
                                className="btn_plus"
                                onClick={() => onClickPlus(foodItems, i)}
                              >
                                &nbsp;
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>

            <div
              className={`black_bg_footer ${
                ticketLessFb ? "black_bg_footer_fooddet" : ""
              }`}
            >
              <div className="row">
                <div className="col-12 user_coloum col-md-6 food_foote">
                  <div>
                    <div className="user-details">
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <div className="total">TOTAL ORDER</div>
                          <div className="total-amount">
                            ${totalCartValue.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="movie_footer_btn_el col-md-6 ml-auto">
                  <div className="movie_button_wrap">
                    <button
                      type="button"
                      className="btn black_btn"
                      onClick={onBack}
                    >
                      BACK
                    </button>
                    {!ticketLessFb ||
                    (ticketLessFb && orderCartData?.length > 0) ? (
                      <button
                        type="button"
                        className="btn black_btn"
                        onClick={onNext}
                      >
                        {orderCartData?.length === 0 ? "SKIP" : "ORDER SUMMARY"}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showAlcoholPopup ? <AlcoholPopup /> : null}
        </div>
      </div>
    </>
  );
};

export default Items;
