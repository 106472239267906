import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../.././../../store";
import { useEffect, useState } from "react";
import React from "react";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import ReCAPTCHA from "react-google-recaptcha";
import {
  BRANDS,
  CAPTCHA_SITE_KEY,
  CONTACT_US_ETIX_ERROR,
  CONTACT_US_SEND_MAIL,
  COUNTRY,
} from "../../../../constants/app";
import { contactUsApi } from "../../../../services/sideMenus";
import "./contactUs.scss";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { getSlidesApi } from "../../../../services/application";
import { toast } from "react-toastify";
import HelmetTitle from "../../../../components/Helmet";
import { sortBy } from "lodash";
import { IDropDown } from "../../../../models/common";

interface IContactUsProps {
  onCloseModal: () => void;
}

const ContactUs: React.FC<IContactUsProps> = ({ onCloseModal }) => {
  const { countryId, cinemas, brandId, contentModal, currentCinema } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      cinemas: state.applicationReducer.cinemas,
      brandId: state.applicationReducer.brandId,
      contentModal: state.applicationReducer.contentModal,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const [cinemaList, setCinemaList] = useState<IDropDown[]>([]);
  const [headerModileImage, setHeaderModileImage] = useState("");
  const [headerDesktopImage, setHeaderDesktopImage] = useState("");

  const getSlides = async () => {
    const {
      status,
      response: { data },
    } = (await getSlidesApi({
      countryId,
      pageName: API_REQUEST_TYPE.CONTACT_US,
      location: "",
    })) as any;
    if (status && data && data.length > 0) {
      setHeaderModileImage(data[0]["mobileImageUrl"]);
      setHeaderDesktopImage(data[0]["imageUrl"]);
    }
  };

  useEffect(() => {
    if (countryId) {
      getSlides();
    }
  }, [countryId]);

  useEffect(() => {
    if (cinemas) {
      let tempCinemas: any = [];
      Object.keys(cinemas).forEach(function (key) {
        const arrList = cinemas[key];
        arrList.forEach((item: any) => {
          tempCinemas.push({
            label: `${item.name}, ${item.stateCode}`,
            value: `${item.slug}`,
          });
        });
      });
      setCinemaList(sortBy(tempCinemas, (row) => row.label));
    }
  }, [cinemas]);

  const onChangeCaptcha = (event: any) => {
    if (event) {
      setFieldValue("captcha", true);
    } else {
      setFieldValue("captcha", false);
    }
  };

  const handleDeviceChange = (event: any) => {
    setFieldValue("device", event.target.value);
  };

  const handleEtixChange = (event: any) => {
    setFieldValue("etixreelclub", event.target.value);
  };

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("contactUs"),
      initialValues: {
        name: "",
        email: "",
        contactNumber: "",
        sendemailto: "",
        cinema: "",
        comments: "",
        etixerror: "",
        etixreelclub: "",
        device: "",
        captcha: false,
        countryId: countryId,
        reelclubcard: "",
      },
      onSubmit: (values: any) => {
        const payload: any = values;
        const filter = CONTACT_US_SEND_MAIL[countryId].find(
          (v: any) => v.value === values.sendemailto
        );
        if (filter) {
          payload.filter = filter.onSelect;
        }

        contactUsApi(values)
          .then((e) => {
            toast.success("Contact details submitted successfully.");
            onCloseModal();
          })
          .catch((err) => {
            // submit error
            toast.error(
              "Could not connect to services at the moment. Please try again. Thanks."
            );
          });
      },
    });

  useEffect(() => {
    if (contentModal && contentModal.category && countryId) {
      let value = "";
      switch (contentModal.category) {
        case "refunds":
          value = "refund";
          break;
        case "lost-and-found":
          value = "lostAndFound";
          break;
        case "advertising":
          value = "advertising";
          break;
      }
      setFieldValue("sendemailto", value);
    }
  }, [contentModal, countryId]);

  return (
    <div className="contact-us-wrapper">
      <HelmetTitle title="Contact Us" description="Contact Us"/>
      <div className="modal-header">
        <div>
          {headerDesktopImage || headerModileImage ? (
            <div className="full_banner_wrap">
              <img
                className="d-none d-md-block"
                src={headerDesktopImage}
                alt="Contact Us"
                title="Contact Us"
              />
              <img
                className="d-block d-md-none"
                src={headerModileImage}
                alt="Contact Us"
                title="Contact Us"
              />
            </div>
          ) : null}
          <div className="row mx-3">
            <div className="col-md-12 mt-4">
              <h2 className="title">Contact Us</h2>
              {countryId === COUNTRY.STA ? (
                <p>
                  The State Cinema welcomes your feedback. We want every trip to
                  the cinema to be a great one. Please let us know if there is
                  anything you think we could do better.
                </p>
              ) : countryId === COUNTRY.ANG ? (
                <p>
                  Angelika Cinemas welcomes your feedback. We want every
                  Angelika experience to be a great one! Please let us know if
                  there is anything we can do better.
                </p>
              ) : (
                <p>
                  Reading Cinemas welcomes your feedback. We want every Reading
                  Cinemas experience to be a great one! Please let us know if
                  there is anything we can do better?
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="contact-us-form form_page">
          <div className="row">
            <div className="col-12 col-md-6 ">
              <label className="form-label">Name*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.name}
                  field={"name"}
                  handleChange={handleChange}
                  touched={touched.name!}
                  error={errors.name}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Email*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.email}
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Contact Number</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.contactNumber}
                  field={"contactNumber"}
                  handleChange={handleChange}
                  touched={touched.contactNumber!}
                  error={errors.contactNumber}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label empty">Send Message To*</label>
              <SelectDropdown
                // placeholder="Cinema"
                field={"sendemailto"}
                options={
                  countryId && CONTACT_US_SEND_MAIL[countryId]
                    ? CONTACT_US_SEND_MAIL[countryId]
                    : []
                }
                setFieldValue={setFieldValue}
                touched={touched.sendemailto!}
                error={errors.sendemailto}
                value={values.sendemailto}
              />
            </div>
            {values.sendemailto != "" && (
              <div className="col-12 col-md-6 mb-3 ">
                <label className="form-label empty">Cinema*</label>
                <SelectDropdown
                  // placeholder="Cinema"
                  field={"cinema"}
                  options={cinemaList}
                  setFieldValue={setFieldValue}
                  touched={touched.cinema!}
                  error={errors.cinema}
                  value={values.cinema}
                  // onChange={(value: any) => {
                  //   setFieldValue("cinema", value);
                  // }}
                />
              </div>
            )}
            {values.sendemailto === "readingReelClub" && (
              <div className="col-12 col-md-6 ">
                <label className="form-label">Reading Rewards Membership No*</label>
                <div className="form-group pass_show">
                  <TextInput
                    field={"reelclubcard"}
                    handleChange={handleChange}
                    touched={touched.reelclubcard!}
                    error={errors.reelclubcard}
                    maxLength={10}
                  />
                </div>
              </div>
            )}
            {values.sendemailto === "eTixSupport" && (
              <>
                <div className="col-12 col-md-6 ">
                  <label className="form-label empty">E-Tix Step Error*</label>
                  <SelectDropdown
                    field={"etixerror"}
                    options={CONTACT_US_ETIX_ERROR}
                    setFieldValue={setFieldValue}
                    touched={touched.etixerror!}
                    error={errors.etixerror}
                    value={values.etixerror}
                    // onChange={(value: any) => {
                    //   setFieldValue("etixerror", value);
                    // }}
                  />
                </div>
                <div className="col-12 col-md-12 ">
                  <label className="form-label">Device & Browser*</label>
                  <div className="custom-control">
                    <label className="form-label black">
                      Is this the same Device and Browser which error occurred
                      on?
                    </label>
                    <input
                      id="yes"
                      type="radio"
                      value="Yes"
                      name="device"
                      onClick={handleDeviceChange}
                      defaultChecked={values.device === "Yes"}
                    />
                    <label className="custom-control-label" htmlFor="yes">
                      Yes
                    </label>
                    <input
                      id="no"
                      type="radio"
                      value="No"
                      name="device"
                      onClick={handleDeviceChange}
                      checked={values.device === "No"}
                    />
                    <label className="custom-control-label" htmlFor="no">
                      No
                    </label>
                  </div>
                  {touched.device && errors?.device ? (
                    <div className="error_message">{`${errors?.device}`}</div>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 ">
                  <label className="form-label">Reading Rewards*</label>
                  <div className="custom-control">
                    <label className="form-label black">
                      Did you log in to the Reading Rewards?
                    </label>
                    <input
                      id="yes_reel_club"
                      type="radio"
                      value="Yes"
                      name="real-club"
                      onClick={handleEtixChange}
                      checked={values.etixreelclub === "Yes"}
                    />
                    <label className="custom-control-label" htmlFor="yes_reel_club">
                      Yes
                    </label>
                    <input
                      id="no_reel_club"
                      type="radio"
                      value="No"
                      name="real-club"
                      onClick={handleEtixChange}
                      defaultChecked={values.etixreelclub === "No"}
                    />
                    <label className="custom-control-label" htmlFor="no_reel_club">
                      No
                    </label>
                  </div>
                  {touched.etixreelclub && errors?.etixreelclub ? (
                    <div className="error_message">{`${errors?.etixreelclub}`}</div>
                  ) : null}
                </div>
              </>
            )}
            <div className="col-12 col-md-12">
              <label className="form-label">Comments: </label>
              <div className="form-group">
                <textarea
                  title="Enter your Comments"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  aria-label="Comments"
                />
              </div>
            </div>
            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code* </label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={onChangeCaptcha}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
            {countryId === COUNTRY.ANG ? (
              <p className="note">
                * Please Note: 'Head Office’, ‘Angelika Rewards’ and ‘Gift Shop’
                enquiries are reviewed Monday to Friday only, excluding evenings
                and public holidays. If the matter is urgent please contact
                Angelika Cinemas directly on 07 3709 8080.
              </p>
            ):countryId === COUNTRY.STA ? (
              <p className="note">
                * Please Note: 'Head Office' and 'Angelika Rewards' feedback
                messages are reviewed Monday to Friday only, excluding evenings
                and public holidays. If the matter is urgent please contact your
                local cinema.
              </p>
            ) : (
              <p className="note">
                * Please Note: 'Head Office' and 'Reading Rewards' feedback
                messages are reviewed Monday to Friday only, excluding evenings
                and public holidays. If the matter is urgent please contact your
                local cinema.
              </p>
            )}
            <div className="col-md-12 mt-3">
              <div className="button_wrap text-center div">
                <button
                  type="button"
                  className="btn gray_btn back-btn mx-2"
                  onClick={onCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn black_btn mx-1"
                  onClick={() => handleSubmit()}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
