import React, { FC } from "react";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { toggleTicketModal } from "../../../../../store/ticketBooking/action";
import "./ticketError.scss";

interface ITicketErrorProps {
  onCloseModal: () => void;
}

const TicketError: FC<ITicketErrorProps> = ({ onCloseModal }) => {
  const { ticketLessFb, modal } = useSelector((state: RootState) => ({
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    modal: state.ticketBookingReducer.modal,
  }));

  const dispatch = useDispatch();

  return (
    <div className="row ticket-error-wrapper">
      {!ticketLessFb ? <MovieDescription /> : null}

      <div className={`${ticketLessFb ? "col-12" : "col-md-8 px-2 py-5"}`}>
        <div className="ticket_error">
          <div className="ticket-header">Transaction Failed!</div>
          {modal && modal.errorMsg && modal.errorMsg != "" ? (
            <div className="ticket_detail">
              <p>{modal.errorMsg}</p>
            </div>
          ) : (
            <div className="ticket_detail">
              <p>
                There appears to have been a problem processing your
                transaction.
              </p>
              <p>
                Please check your email in 5 minutes, and if you have not received your E-TIX please repurchase your tickets.
              </p>
              <p>
                Any transaction in error will be automatically refunded, which
                may take 1-3 days to appear on your statement. Apologies for the
                inconvenience.
              </p>
            </div>
          )}
        </div>

        <div className={`movie_footer_btn_el col-md-12 ml-auto`}>
          <div className="movie_button_wrap justify-content-center">
            <button type="button" className="btn black_btn" onClick={onCloseModal}>
              RETURN TO HOME SCREEN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketError;
