import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../../constants/path';
import { IMAGE_URL_CONFIG } from '../../../../../constants/url';
import './accountUpdateInfo.scss'


interface IAccountUpdateInfoProps {
    onCloseModal: any
 }

const AccountUpdateInfo: FC<IAccountUpdateInfoProps> = ({onCloseModal}) => {

    const navigate = useNavigate();

    const home = () => {
        onCloseModal();
        navigate(`/${ROUTES.MOVIES}/$${ROUTES.NOW_SHOWING}`)
    }

    return <div className="account-update-info-wrapper">
        <div className="col-12">
            <div className="content">
                <div className="body">
                   
                    <div className="activation_link_banner">
                        <div className="party_logo">
                            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} />
                            <p>Your account details have been updated.</p>
                        </div>
                        <div className="activation_content">
                            <p>Start earning points by purchasing movie tickets, food and drinks!</p>
                        </div>
                    </div>

                    <div className={`movie_footer_btn_el col-md-12 ml-auto mt-3`}>
                        <div className="movie_button_wrap justify-content-end">
                            <button
                                type="button"
                                className="btn black_btn"
                                onClick={home}>
                                GO TO MOVIES HOMEPAGE</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default AccountUpdateInfo;