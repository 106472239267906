import React, { FC } from "react";
import { RootState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/path";
import { clearOrdersAction } from "../../../../../store/giftshop/action";
import { toggleTicketModal } from "../../../../../store/ticketBooking/action";

interface IGiftshopErrorProps {
  onCloseModal: () => void;
}

const GiftshopError: FC<IGiftshopErrorProps> = ({ onCloseModal }) => {
  return (
    <div>
      <div className="error_detail">
        <p>There appears to have been a problem processing your transaction.</p>
        <p>
          Please check your email in 5 minutes, and if you have not received
          your Gift Card please repurchase your Gift Cards.
        </p>
        <p>
          Any transaction in error will be automatically refunded, which may
          take 1-3 days to appear on your statement. Apologies for the
          inconvenience.
        </p>
      </div>

      <div className="row btn-section thanks_modal_btnsec">
        <div className="col-md-12 movie_footer_btn_el ml-auto">
          <div className="movie_button_wrap d-flex justify-content-end">
            <button
              type="button"
              className="btn black_btn px-3"
              onClick={onCloseModal}
            >
              RETURN TO GIFT SHOP HOME
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftshopError;
