import { ACTIONS } from './type';

export function openSliderAction(payload: any) {
    return { type: ACTIONS.OPEN_SLIDER, payload }
}

export function setLoader(payload: boolean) {
    return { type: ACTIONS.SET_LOADER, payload }
}

export function getSettingsWatcher(payload: any) {
    return { type: ACTIONS.GET_SETTINGS_WATCHER, payload }
}

export function getSettingsSuccess(payload: any) {
    return { type: ACTIONS.GET_SETTINGS_SUCCESS, payload }
}

export function getSettingsError(payload: any) {
    return { type: ACTIONS.GET_SETTINGS_FAILURE, payload }
}

export function getCinemaWatcher(payload: any) {
    return { type: ACTIONS.GET_CINEMAS_WATCHER, payload }
}

export function getCinemasSuccess(payload: any) {
    return { type: ACTIONS.GET_CINEMAS_SUCCESS, payload }
}

export function getCinemasError(payload: any) {
    return { type: ACTIONS.GET_CINEMAS_ERROR, payload }
}

export function setCinemaAction(payload: any) {
    return { type: ACTIONS.SET_SELECTED_CINEMA, payload };
}

export function getSlidesWatcher(payload: {
    countryId: string;
    pageName: string;
    location?: string;
}) {
    return { type: ACTIONS.GET_SLIDES_WATCHER, payload };
}

export function getSlidesSuccess(payload: any) {
    return { type: ACTIONS.GET_SLIDES_SUCCESS, payload: payload };
}

export function getSlidesError(payload: any) {
    return { type: ACTIONS.GET_SLIDES_FAILURE, payload: payload };
}

export function getPromoAdWatcher(payload: any) {
    return { type: ACTIONS.GET_PROMO_AD_WATCHER, payload };
}
export function clearPromoAd() {
    return { type: ACTIONS.CLEAR_PROMO_AD };
}

export function getPromoAdSuccess(payload: any) {
    return { type: ACTIONS.GET_PROMO_AD_SUCCESS, payload };
}

export function getPromoAdError(payload: any) {
    return { type: ACTIONS.GET_PROMO_AD_FAILURE, payload: payload };
}

export function setFooterType(payload: any) {
    return { type: ACTIONS.SET_FOOTER_TYPE, payload: payload };
}

export function setMovieDetailBackUrl(payload: any) {
    return { type: ACTIONS.SET_MOVIE_DETAIL_BACK_URL, payload: payload };
}

export function setReinitializeScroll(payload: boolean) {
    return { type: ACTIONS.SET_REINITIALIZE_SCROLL, payload: payload };
}

export function setPopupInfo(payload: any) {
    return { type: ACTIONS.SET_POPUP_INFO, payload: payload };
}

export function setBrandId(payload: any) {
    return { type: ACTIONS.SET_BRAND_ID, payload: payload };
}

export function setReinitializeSlider(payload: boolean) {
    return { type: ACTIONS.SET_REINITIALIZE_FILTER, payload: payload }
}

export function toggleContentModal(payload: boolean) {
    return { type: ACTIONS.TOGGLE_CONTENT_MODAL, payload: payload }
}

export function setContentModal(payload: any) {
    return { type: ACTIONS.SET_CONTENT_MODAL, payload: payload }
}

export function getMenuWatcher(payload: any) {
    return { type: ACTIONS.MENU_WATCHER, payload }
}

export function getMenuSuccess(payload: any) {
    return { type: ACTIONS.MENU_SUCCESS, payload }
}

export function getMenuFailure(payload: any) {
    return { type: ACTIONS.MENU_ERROR, payload }
}

export function setDateObject(payload: any) {
    return { type: ACTIONS.SET_DATE_OBJECT, payload }
}